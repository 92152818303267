import React, { ChangeEvent, useState } from 'react';
import style from './style.module.css';
import ApiResponse from '../../../../utils/types/api-response';
import { StudentListWrapper } from '../../../../models/student/student-list-wrapper';
import StudentProfileIcon from '../../../../assets/icons/user.png';
import { StudentData } from '../../../../models/student/student-data';
import { FeePaymentData } from '../../../../models/fee/fee-payment-data';
import { ClassData } from '../../../../models/class/class-data';
import {
    formatDateToInputDate,
    getFormattedDate,
    number2text,
} from '../../../../utils/helpers/helpers';
import { FeePaymentFormDataType } from '../../../view-models/fee-payment-viewmodel';
import { FeeDate } from '../../../../models/fee/fee-date';
import { key } from 'localforage';
// import arrowDown from '../../../assets/icons/down-arrow.png';
// import arrowUp from '../../../assets/icons/up-arrow.png';
import arrowUp from '../../../../assets/icons/up-arrow.png';
import arrowDown from '../../../../assets/icons/down-arrow.png';

type Props = {
    isStudentDataFetchLoading?: boolean;
    studentSuggestionListData?: ApiResponse<StudentListWrapper> | undefined;
    handlesearchInputChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    onStudentSearchListItemClick?: (item: StudentData) => void;
    searchTerm?: string;
    isSearchInputOnFocus?: boolean;
    setIsSearchInputOnFocus?: (value: boolean) => void;
    selectedStudentData?: StudentData;
    isStudentChangable: boolean;
    handleClassInputChange: (event: ChangeEvent<HTMLSelectElement>) => void;
    handleStudentFeeSearch: () => void;
    studentsClassFeeData: FeePaymentData[] | undefined;
    feePaymentFormData: FeePaymentFormDataType;
    allClasses: ClassData[] | undefined;
    handleFeeFormInputChange: (field: string, value: string | number) => void;
    handleFeeCollectSubmit: (e: React.FormEvent) => void;
    confirmedStudentClassId: number | undefined;
    handleStudentFeeReset: () => void;
    selectedStudentClassId: number | undefined;
    inactiveFeeData: FeePaymentData[] | undefined;
    handleExpandInactive: (id: number) => void;
    expandInactive: number[];
};
function FeecollectionForm({
    isStudentDataFetchLoading,
    searchTerm,
    studentSuggestionListData,
    handlesearchInputChange,
    onStudentSearchListItemClick,
    isSearchInputOnFocus,
    setIsSearchInputOnFocus,
    selectedStudentData,
    handleClassInputChange,
    handleStudentFeeSearch,
    studentsClassFeeData,
    feePaymentFormData,
    handleFeeFormInputChange,
    allClasses,
    handleFeeCollectSubmit,
    confirmedStudentClassId,
    handleStudentFeeReset,
    selectedStudentClassId,
    inactiveFeeData,
    handleExpandInactive,
    expandInactive,
    isStudentChangable,
}: Props) {
    const [showFields, setshowFields] = useState(false);

    return (
        <div className={style.container}>
            <form onSubmit={handleFeeCollectSubmit}>
                <div className={style.containermarginx}>
                    {/* <h1 className={style.containerHeader}>Collect Fees</h1> */}
                    <div className={style.containerWrapper}>
                        <div className={`${style.containerLeftSide}`}>
                            {
                                // studentSuggestionListData &&
                                // handlesearchInputChange &&
                                onStudentSearchListItemClick &&
                                setIsSearchInputOnFocus &&
                                isStudentChangable ? (
                                    <div className={`${style.containerLeftSideTogglediv}  `}>
                                        <h1 className={style.containerLeftSidedivText}>
                                            Student Name
                                        </h1>

                                        <input
                                            placeholder="Student Name"
                                            className={style.formInput}
                                            required
                                            value={searchTerm}
                                            onFocus={() => setIsSearchInputOnFocus(true)}
                                            onBlur={() =>
                                                setTimeout(
                                                    () => setIsSearchInputOnFocus(false),
                                                    200
                                                )
                                            }
                                            onChange={handlesearchInputChange}
                                            disabled={
                                                selectedStudentData !== undefined &&
                                                confirmedStudentClassId !== undefined
                                            }
                                        />

                                        {isSearchInputOnFocus && (
                                            <div className={`${style.suggestionBox}`}>
                                                <ul className={style.suggestionBoxListWrapper}>
                                                    {isStudentDataFetchLoading ? (
                                                        <li
                                                            className={
                                                                style.menuSearchLoaderContainer
                                                            }
                                                        >
                                                            <div
                                                                className={style.menuSearchLoader}
                                                            ></div>
                                                            Loading...
                                                        </li>
                                                    ) : studentSuggestionListData?.data?.data
                                                          ?.length === 0 ? (
                                                        <li className={style.suggestionBoxListItem}>
                                                            <div
                                                                className={
                                                                    style.suggestionBoxListItemText
                                                                }
                                                            >
                                                                No student found
                                                            </div>
                                                        </li>
                                                    ) : (
                                                        (
                                                            studentSuggestionListData?.data?.data ??
                                                            []
                                                        ).map((item, index) => (
                                                            <li
                                                                className={
                                                                    style.suggestionBoxListItem
                                                                }
                                                                onClick={() => {
                                                                    onStudentSearchListItemClick(
                                                                        item
                                                                    );
                                                                    setIsSearchInputOnFocus(false);
                                                                }}
                                                                key={index}
                                                            >
                                                                {item && item?.attributes && (
                                                                    <>
                                                                        <span>
                                                                            <img
                                                                                className={
                                                                                    style.studentProfileWrapper
                                                                                }
                                                                                src={
                                                                                    item?.attributes
                                                                                        ?.photo
                                                                                        ?.data
                                                                                        ?.attributes
                                                                                        ?.url ??
                                                                                    StudentProfileIcon
                                                                                }
                                                                                alt={`Profile image`}
                                                                            />
                                                                        </span>
                                                                        <span>
                                                                            {item?.attributes
                                                                                ?.firstName +
                                                                                ' ' +
                                                                                (item?.attributes
                                                                                    ?.lastName
                                                                                    ? item
                                                                                          ?.attributes
                                                                                          ?.lastName
                                                                                    : '')}
                                                                        </span>
                                                                    </>
                                                                )}
                                                            </li>
                                                        ))
                                                    )}
                                                </ul>
                                            </div>
                                        )}
                                    </div>
                                ) : (
                                    <div className={style.containerLeftSidediv}>
                                        <h1 className={style.containerLeftSidedivText}>
                                            Student Name
                                        </h1>
                                        <h1>{selectedStudentData?.attributes?.firstName}</h1>
                                    </div>
                                )
                            }

                            <div className={style.containerLeftSideTogglediv}>
                                <h1 className={style.containerLeftSidedivText}>Class</h1>
                                <div className={style.classSearchInputBtnwrapper}>
                                    <select
                                        className={style.classSearchInput}
                                        disabled={
                                            !selectedStudentData ||
                                            (selectedStudentData !== undefined &&
                                                confirmedStudentClassId !== undefined)
                                        }
                                        onChange={e => handleClassInputChange(e)}
                                        required
                                        value={selectedStudentClassId ?? ''}
                                    >
                                        <option value="">Choose a class</option>
                                        {selectedStudentData?.attributes?.classes?.data
                                            ?.filter(item=>item.attributes.status !== 'INACTIVE')
                                            .filter(item =>
                                                allClasses?.map(item => item.id)?.includes(item.id)
                                            )
                                            ?.map(item => (
                                                <option
                                                    key={item.id}
                                                    value={item.id}
                                                >
                                                    {item?.attributes?.class_name}
                                                </option>
                                            ))}
                                    </select>
                                </div>
                                <div className={style.SearchResetBtnwrapper}>
                                    <div
                                        className={style.classSearchBtn}
                                        onClick={handleStudentFeeSearch}
                                    >
                                        Search
                                    </div>
                                    <div
                                        className={style.classResetBtn}
                                        onClick={handleStudentFeeReset}
                                    >
                                        Reset
                                    </div>
                                </div>
                            </div>

                            {studentsClassFeeData &&
                            studentsClassFeeData?.length > 0 &&
                            confirmedStudentClassId === feePaymentFormData.class ? (
                                <div className={style.studentClassFeeInfoContainer}>
                                    <div className={style.containerLeftSidediv}>
                                        <h1 className={style.containerLeftSidedivText}>
                                            Last Received Amount in Rs
                                        </h1>
                                        <h1>
                                            {
                                                studentsClassFeeData[0]?.attributes?.fee_dates[
                                                    studentsClassFeeData[0]?.attributes?.fee_dates
                                                        .length - 1
                                                ].amount
                                            }
                                        </h1>
                                    </div>
                                    <div className={style.containerLeftSidediv}>
                                        <h1 className={style.containerLeftSidedivText}>
                                            Balance Amount from previous payment in Rs
                                        </h1>
                                        <h1>{studentsClassFeeData[0]?.attributes?.balance}</h1>
                                    </div>
                                    <div className={style.containerLeftSidediv}>
                                        <h1 className={style.containerLeftSidedivText}>
                                            Last Fees Payment Schedule
                                        </h1>
                                        <h1>
                                            {studentsClassFeeData[0]?.attributes.payment_schedule}
                                        </h1>
                                    </div>
                                    <div className={style.containerLeftSidediv}>
                                        <h1 className={style.containerLeftSidedivText}>
                                            Number of Sessions in a Week
                                        </h1>
                                        <h1>{studentsClassFeeData[0]?.attributes.sessions}</h1>
                                    </div>
                                    <div className={style.containerLeftSidediv}>
                                        <h1 className={style.containerLeftSidedivText}>
                                            Current Class Information
                                        </h1>
                                        <h1>
                                            {
                                                studentsClassFeeData[0]?.attributes?.class?.data
                                                    ?.attributes?.class_name
                                            }
                                        </h1>
                                    </div>
                                    <div className={style.containerLeftSidediv}>
                                        <h1 className={style.containerLeftSidedivText}>
                                            Fees start date
                                        </h1>
                                        <h1>
                                            {getFormattedDate(
                                                studentsClassFeeData[0]?.attributes?.fee_dates[0]
                                                    ?.startDate ?? ''
                                            )}
                                        </h1>
                                    </div>
                                    <div className={style.containerLeftSidediv}>
                                        <h1 className={style.containerLeftSidedivText}>
                                            Fees end date
                                        </h1>
                                        <h1>
                                            {getFormattedDate(
                                                new Date(
                                                    studentsClassFeeData[0]?.attributes
                                                        ?.fee_dates[0]?.dueDate ?? ''
                                                )
                                            )}
                                        </h1>
                                    </div>
                                    <div className={style.containerLeftSidediv}>
                                        <h1 className={style.containerLeftSidedivText}>
                                            Fees payment date
                                        </h1>
                                        <h1>
                                            {getFormattedDate(
                                                new Date(
                                                    studentsClassFeeData[0]?.attributes
                                                        ?.fee_dates[0]?.fee_date ?? ''
                                                )
                                            )}
                                        </h1>
                                    </div>
                                    <div className={style.containerLeftSidediv}>
                                        <h1 className={style.containerLeftSidedivText}>
                                            Fees to be paid for upcoming sessions in Rs
                                        </h1>
                                        <h1>{studentsClassFeeData[0]?.attributes?.amount}</h1>
                                    </div>
                                    {studentsClassFeeData[0]?.attributes?.fee_dates[0]?.message && (
                                        <div className={style.containerLeftSidediv}>
                                            <div className="w-full bg-slate-100 rounded-lg py-1 px-4">
                                                <div>
                                                    {
                                                        studentsClassFeeData[0]?.attributes
                                                            ?.fee_dates[0]?.message
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    <div className={style.containerLeftSidediv}>
                                        <h1 className={style.containerLeftSidedivText}></h1>
                                        <h1
                                            className={style.containerLeftSidedivToggle}
                                            onClick={() => setshowFields(!showFields)}
                                        >
                                            Edit Schedule, Number of sessions or Fees amount
                                        </h1>
                                    </div>

                                    {showFields && (
                                        <>
                                            <div className={style.containerLeftSideTogglediv}>
                                                <h1 className={style.containerLeftSidedivText}>
                                                    New Payment Schedule
                                                </h1>

                                                <select
                                                    value={feePaymentFormData.payment_schedule}
                                                    onChange={e =>
                                                        handleFeeFormInputChange(
                                                            'payment_schedule',
                                                            e.target.value
                                                        )
                                                    }
                                                >
                                                    <option value="">
                                                        Choose a payment schedule
                                                    </option>
                                                    <option value="Monthly">Monthly</option>
                                                    <option value="Quarterly">Quarterly</option>
                                                    <option value="Semi-Annually">
                                                        Semi-Annually
                                                    </option>
                                                    <option value="Annually">Annually</option>
                                                </select>
                                            </div>

                                            <div className={style.containerLeftSideTogglediv}>
                                                <h1 className={style.containerLeftSidedivText}>
                                                    Number of Sessions in a Week
                                                </h1>
                                                <select
                                                    value={feePaymentFormData?.sessions ?? ''}
                                                    onChange={e =>
                                                        handleFeeFormInputChange(
                                                            'sessions',
                                                            e.target.value
                                                        )
                                                    }
                                                >
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                    <option value="6">6</option>
                                                    <option value="7">7</option>
                                                </select>
                                            </div>

                                            {/* <div
                                                className={
                                                    style.containerLeftSideTogglediv
                                                }>
                                                <h1
                                                    className={
                                                        style.containerLeftSidedivText
                                                    }>
                                                    New Class
                                                </h1>

                                                <select
                                                    value={confirmedStudentClassId ?? ""}
                                                    onChange={(e) => handleFeeFormInputChange("class",e.target.value)}
                                                >
                                                    {allClasses?.filter(item => item?.attributes?.discipline === studentsClassFeeData[0]?.attributes?.class?.data?.attributes?.discipline).map(item => 
                                                        <option key={item.id} value={item.id}>{item?.attributes?.class_name}</option>    
                                                    )}
                                                </select>
                                            </div> */}

                                            <div className={style.containerLeftSideTogglediv}>
                                                <h1 className={style.containerLeftSidedivText}>
                                                    Fees to be paid for upcoming sessions in Rs{' '}
                                                    <span className="text-red-600">*</span>
                                                </h1>
                                                <input
                                                    placeholder="Fees in Rs to be collected due to change in schedule, session or class"
                                                    type="number"
                                                    className={style.formInput}
                                                    onChange={e =>
                                                        handleFeeFormInputChange(
                                                            'amount',
                                                            e.target.value
                                                        )
                                                    }
                                                    value={feePaymentFormData.amount ?? 0}
                                                    disabled={
                                                        studentsClassFeeData[0]?.attributes
                                                            .payment_schedule ===
                                                            feePaymentFormData.payment_schedule &&
                                                        studentsClassFeeData[0]?.attributes
                                                            .sessions ==
                                                            feePaymentFormData.sessions &&
                                                        studentsClassFeeData[0]?.attributes.class
                                                            .data.id == feePaymentFormData.class
                                                    }
                                                />
                                            </div>
                                        </>
                                    )}
                                </div>
                            ) : (
                                selectedStudentData !== undefined &&
                                confirmedStudentClassId !== undefined && (
                                    <>
                                        <div className={style.studentClassFeeInfoContainer}>
                                            <div className={style.noFeeInfoPrompt}>
                                                No fee data found for the selected class
                                            </div>
                                        </div>
                                        {inactiveFeeData && inactiveFeeData?.length > 0 && (
                                            <div className="sm:h-[130px] h-[200px] overflow-y-auto thin-scrollbar mt-2 border-2 mb-2">
                                                {inactiveFeeData.map((data, index) => (
                                                    <div
                                                        key={index}
                                                        className={`${style.inactiveClassContainer} `}
                                                    >
                                                        <div className="flex justify-between">
                                                            <div className="flex text-black font-normal">
                                                                Class name:{' '}
                                                                {
                                                                    data?.attributes.class.data
                                                                        ?.attributes.class_name
                                                                }
                                                                <div className="ml-2 h-[20px] bg-red-300 px-1 rounded text-black text-xs flex  items-center">
                                                                    Inactive
                                                                </div>
                                                                {data?.attributes.archived && (
                                                                    <div className="ml-2 h-[20px] bg-slate-300 rounded px-1 text-black text-xs flex items-center">
                                                                        Archived
                                                                    </div>
                                                                )}
                                                            </div>

                                                            <div>
                                                                {!expandInactive.includes(
                                                                    index
                                                                ) && (
                                                                    <button
                                                                        className="text-blue-700"
                                                                        onClick={() =>
                                                                            handleExpandInactive(
                                                                                index
                                                                            )
                                                                        }
                                                                    >
                                                                        <img
                                                                            src={arrowDown}
                                                                            className="w-6 h-6 cursor-pointer"
                                                                            alt=""
                                                                        />
                                                                    </button>
                                                                )}
                                                                {expandInactive.includes(index) && (
                                                                    <button
                                                                        className="text-blue-700"
                                                                        onClick={() =>
                                                                            handleExpandInactive(
                                                                                index
                                                                            )
                                                                        }
                                                                    >
                                                                        <img
                                                                            src={arrowUp}
                                                                            className="w-6 h-6 cursor-pointer"
                                                                            alt=""
                                                                        />
                                                                    </button>
                                                                )}
                                                            </div>
                                                        </div>
                                                        {expandInactive.includes(index) && (
                                                            <>
                                                                <div className="sm:flex justify-between mt-2">
                                                                    <div className="flex gap-1">
                                                                        Paid on:
                                                                        <div className="text-gray-500">
                                                                            {getFormattedDate(
                                                                                data?.attributes
                                                                                    .fee_dates[0]
                                                                                    .fee_date
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                    <div className="flex gap-1">
                                                                        Start Date:{' '}
                                                                        <div className="text-gray-500">
                                                                            {getFormattedDate(
                                                                                data?.attributes
                                                                                    .fee_dates[0]
                                                                                    .startDate
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                    <div className="flex gap-1">
                                                                        End Date:{' '}
                                                                        <div className="text-gray-500">
                                                                            {getFormattedDate(
                                                                                data?.attributes
                                                                                    .fee_dates[0]
                                                                                    .dueDate
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="flex gap-10">
                                                                    <div className="flex gap-1">
                                                                        <div>Amount:</div>
                                                                        <div className="text-gray-500">
                                                                            {''}
                                                                            {
                                                                                inactiveFeeData[0]
                                                                                    ?.attributes
                                                                                    .fee_dates[0]
                                                                                    .amount
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    {data?.attributes.fee_dates[0]
                                                                        .message && (
                                                                        <>
                                                                            Message:{' '}
                                                                            {
                                                                                data?.attributes
                                                                                    .fee_dates[0]
                                                                                    .message
                                                                            }
                                                                        </>
                                                                    )}
                                                                </div>
                                                            </>

                                                            // {data.attributes
                                                            //     .fee_dates[0]
                                                            //     .message && (
                                                            //     <div className="w-full bg-slate-400 mt-1">
                                                            //         Message:{' '}
                                                            //         {
                                                            //             data
                                                            //                 .attributes
                                                            //                 .fee_dates[0]
                                                            //                 .message
                                                            //         }
                                                            //     </div>
                                                            // )}
                                                        )}

                                                        {/* <div className="w-full bg-slate-200 mt-1">
                                                            Message:{' '}
                                                            {
                                                                data.attributes
                                                                    .fee_dates[0]
                                                                    .message
                                                            }
                                                        </div> */}

                                                        {/* {expandInactive && (
                                                                <div>
                                                                    <div className=" mt-2 flex justify-between">
                                                                        <div>
                                                                            Last
                                                                            received
                                                                            amount
                                                                            in
                                                                            Rs
                                                                        </div>
                                                                        <div className="text-gray-500">
                                                                            {
                                                                                data.attributes.fee_dates[0].amount 
                                    
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className=" mt-2 flex justify-between">
                                                                        <div>
                                                                            Balance
                                                                            amount
                                                                            in
                                                                            Rs
                                                                        </div>
                                                                        <div className="text-gray-500">
                                                                            {
                                                                               data.attributes.balance
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className=" mt-2 flex justify-between">
                                                                        <div>
                                                                            Last
                                                                            fee
                                                                            payment
                                                                            schedule
                                                                        </div>
                                                                        <div className="text-gray-500">
                                                                            {
                                                                                data.attributes.payment_schedule
                                                                                
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className=" mt-2 flex justify-between">
                                                                        <div>
                                                                            Number
                                                                            of
                                                                            sessions
                                                                            in a
                                                                            week
                                                                        </div>
                                                                        <div className="text-gray-500">
                                                                            {
                                                                                data.attributes.sessions
                                                                                
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className=" mt-2 flex justify-between">
                                                                        <div>
                                                                            Fee
                                                                            start
                                                                            date
                                                                        </div>
                                                                        <div className="text-gray-500">
                                                                            {getFormattedDate(
                                                                                data.attributes.fee_dates[0].startDate
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                    <div className=" mt-2 flex justify-between">
                                                                        <div>
                                                                            Fee
                                                                            end
                                                                            date
                                                                        </div>
                                                                        <div className="text-gray-500">
                                                                            {getFormattedDate(
                                                                                data.attributes.fee_dates[0].dueDate
                                                                                    
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                    <div className=" mt-2 flex justify-between">
                                                                        <div>
                                                                            Fee
                                                                            payment
                                                                            date
                                                                        </div>
                                                                        <div className="text-gray-500">
                                                                            {getFormattedDate(
                                                                                data.attributes.fee_dates[0].fee_date
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )} */}
                                                    </div>
                                                ))}
                                            </div>
                                        )}

                                        <div className={style.containerLeftSideTogglediv}>
                                            <h1 className={style.containerLeftSidedivText}>
                                                Payment Schedule
                                                <span className="text-red-600">*</span>
                                            </h1>
                                            <select
                                                value={feePaymentFormData.payment_schedule}
                                                onChange={e =>
                                                    handleFeeFormInputChange(
                                                        'payment_schedule',
                                                        e.target.value
                                                    )
                                                }
                                                required
                                            >
                                                <option value="">Choose a payment schedule</option>
                                                <option value="Monthly">Monthly</option>
                                                <option value="Quarterly">Quarterly</option>
                                                <option value="Semi-Annually">Semi-Annually</option>
                                                <option value="Annually">Annually</option>
                                            </select>
                                        </div>

                                        <div className={style.containerLeftSideTogglediv}>
                                            <h1 className={style.containerLeftSidedivText}>
                                                Number of Sessions in a Week
                                                <span className="text-red-600">*</span>
                                            </h1>
                                            <select
                                                value={feePaymentFormData?.sessions ?? ''}
                                                onChange={e =>
                                                    handleFeeFormInputChange(
                                                        'sessions',
                                                        e.target.value
                                                    )
                                                }
                                                required
                                            >
                                                <option value="">
                                                    Choose number of sessions per week
                                                </option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                            </select>
                                        </div>

                                        <div className={style.containerLeftSideTogglediv}>
                                            <h1 className={style.containerLeftSidedivText}>
                                                Total fees to be paid
                                                <span className="text-red-600">*</span>
                                            </h1>
                                            <input
                                                type="number"
                                                className={style.formInput}
                                                required
                                                onChange={e =>
                                                    handleFeeFormInputChange(
                                                        'amount',
                                                        e.target.value
                                                    )
                                                }
                                                value={feePaymentFormData.amount ?? 0}
                                            />
                                            {feePaymentFormData.amount && (
                                                <span>
                                                    {number2text(feePaymentFormData.amount)}
                                                </span>
                                            )}
                                        </div>
                                    </>
                                )
                            )}
                        </div>
                        <div className={style.containerRightSide}>
                            <div className={style.containerRightSidediv}>
                                <h1 className={style.containerRightSidedivText}>
                                    Fees Received in Rs
                                    <span className="text-red-600">*</span>
                                </h1>
                                <input
                                    required
                                    className={style.formInput}
                                    disabled={
                                        selectedStudentData === undefined ||
                                        confirmedStudentClassId === undefined
                                    }
                                    placeholder="Fee amount received in Rs"
                                    type="number"
                                    value={feePaymentFormData?.feePaymentAmount ?? 0}
                                    onChange={e =>
                                        handleFeeFormInputChange('feePaymentAmount', e.target.value)
                                    }
                                />
                                {feePaymentFormData.feePaymentAmount && (
                                    <span>
                                        {number2text(Number(feePaymentFormData.feePaymentAmount))}
                                    </span>
                                )}
                            </div>
                            <div className={style.containerRightSidediv}>
                                <h1 className={style.containerRightSidedivText}>
                                    Balance fees in Rs
                                </h1>

                                <input
                                    placeholder="Balance fees amount in Rs"
                                    type="number"
                                    className={style.formInput}
                                    disabled={
                                        selectedStudentData === undefined ||
                                        confirmedStudentClassId === undefined
                                    }
                                    required
                                    value={feePaymentFormData?.balance ?? 0}
                                    onChange={e =>
                                        handleFeeFormInputChange('balance', e.target.value)
                                    }
                                />
                                {feePaymentFormData.balance !== 0 && (
                                    <span>{number2text(Number(feePaymentFormData.balance))}</span>
                                )}
                            </div>

                            <div className={style.containerRightSidediv}>
                                <h1 className={style.containerRightSidedivText}>
                                    <span className="text-red-600">*</span>
                                    Fees Start Date
                                </h1>
                                <input
                                    value={feePaymentFormData?.feeStartDate}
                                    onChange={e =>
                                        handleFeeFormInputChange('feeStartDate', e.target.value)
                                    }
                                    className={style.formInput}
                                    disabled={
                                        selectedStudentData === undefined ||
                                        confirmedStudentClassId === undefined
                                    }
                                    type="date"
                                    required
                                    name="feesStartDate"
                                />
                            </div>

                            <div className={style.containerRightSidediv}>
                                <h1 className={style.containerRightSidedivText}>
                                    <span className="text-red-600">*</span>
                                    Fees End Date
                                </h1>
                                <input
                                    value={feePaymentFormData?.feeEndDate}
                                    onChange={e =>
                                        handleFeeFormInputChange('feeEndDate', e.target.value)
                                    }
                                    type="date"
                                    className={style.formInput}
                                    disabled={
                                        selectedStudentData === undefined ||
                                        confirmedStudentClassId === undefined
                                    }
                                    required
                                    name="feesEndDate"
                                />
                            </div>

                            <div className={style.containerRightSidediv}>
                                <h1 className={style.containerRightSidedivText}>
                                    <span className="text-red-600">*</span>
                                    Fees Payment Date
                                </h1>
                                <input
                                    value={feePaymentFormData?.feePaymentDate}
                                    onChange={e =>
                                        handleFeeFormInputChange('feePaymentDate', e.target.value)
                                    }
                                    type="date"
                                    className={style.formInput}
                                    disabled={
                                        selectedStudentData === undefined ||
                                        confirmedStudentClassId === undefined
                                    }
                                    name="feesPaymentDate"
                                />
                            </div>

                            <div className={style.containerRightSidediv}>
                                <h1 className={style.containerRightSidedivText}>Mode of Payment</h1>
                                <select
                                    name="paymentMethod"
                                    value={feePaymentFormData?.feePaymentMode}
                                    onChange={e =>
                                        handleFeeFormInputChange('feePaymentMode', e.target.value)
                                    }
                                    className={style.formInput}
                                    disabled={
                                        selectedStudentData === undefined ||
                                        confirmedStudentClassId === undefined
                                    }
                                >
                                    <option
                                        value=""
                                        disabled
                                        selected
                                    >
                                        Select Payment Method
                                    </option>
                                    <option value="cash">Cash</option>
                                    <option value="upi">UPI</option>
                                    <option value="internetBanking">Internet Banking</option>
                                    <option value="others">Others</option>
                                </select>
                            </div>
                            {allClasses?.filter(item => item?.id === confirmedStudentClassId)[0]
                                ?.attributes?.academies?.data?.attributes?.bankAccounts &&
                                feePaymentFormData.feePaymentMode !== 'cash' && (
                                    <div className={style.containerRightSidediv}>
                                        <h1 className={style.containerRightSidedivText}>
                                            Amount Received in
                                        </h1>
                                        <select
                                            name="account"
                                            value={feePaymentFormData?.accReceivedIn}
                                            onChange={e =>
                                                handleFeeFormInputChange(
                                                    'accReceivedIn',
                                                    e.target.value
                                                )
                                            }
                                        >
                                            <option value="">Select a Bank Account</option>
                                            {allClasses
                                                ?.filter(
                                                    item => item?.id === confirmedStudentClassId
                                                )[0]
                                                ?.attributes?.academies?.data?.attributes?.bankAccounts?.split(
                                                    ','
                                                )
                                                .map((acc, index) => (
                                                    <option
                                                        key={index}
                                                        value={acc}
                                                    >
                                                        {acc}
                                                    </option>
                                                ))}
                                        </select>
                                    </div>
                                )}
                            <div className={style.containerRightSidediv}>
                                <h1 className={style.containerRightSidedivText}>Message</h1>
                                <textarea
                                    placeholder="Enter your message here"
                                    className={`${style.formInput} resize-none`}
                                    disabled={
                                        selectedStudentData === undefined ||
                                        confirmedStudentClassId === undefined
                                    }
                                    name="message"
                                    rows={3}
                                    value={feePaymentFormData?.feePaymentMessage}
                                    onChange={e =>
                                        handleFeeFormInputChange(
                                            'feePaymentMessage',
                                            e.target.value
                                        )
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <button
                        type="submit"
                        className={style.feecollectButton}
                    >
                        Collect Fees
                    </button>
                </div>
            </form>
        </div>
    );
}

export default FeecollectionForm;
