import React, { useState } from 'react';
import style from './style.module.css';
import { FeePaymentData } from '../../../../models/fee/fee-payment-data';
import { ClassData } from '../../../../models/class/class-data';
import { getFormattedDate, number2text } from '../../../../utils/helpers/helpers';
import { FeePaymentFormDataType } from '../../../view-models/fee-payment-viewmodel';
import { AttendanceAttributesWraper } from '../../../../models/attendance/attendance-attributes-wrapper';

interface NewStudentFormProps {
    activeCollectFeeStudent: FeePaymentData | undefined;
    feePaymentFormData: FeePaymentFormDataType;
    handleFeeCollectSubmit: (e: React.FormEvent) => void;
    allClasses: ClassData[] | undefined;
    handleFeeFormInputChange: (field: string, value: string | number) => void;
    leaveRecordData: AttendanceAttributesWraper[] | undefined;
}

const ExistingFeecollectionForm: React.FC<NewStudentFormProps> = ({
    activeCollectFeeStudent,
    feePaymentFormData,
    handleFeeCollectSubmit,
    allClasses,
    handleFeeFormInputChange,
    leaveRecordData,
}) => {
    const [showFields, setshowFields] = useState(false);
    const [isLeaveRecordOpen, setIsLeaveRecordOpen] = useState(false);

    return (
        <div className={style.container}>
            <form onSubmit={handleFeeCollectSubmit}>
                <div className={style.containermarginx}>
                    <div>
                        <h1 className={style.containerHeader}>Collect Fees</h1>
                        {new Date(
                            activeCollectFeeStudent?.attributes?.fee_dates[0]?.dueDate ?? ''
                        ).getTime() > new Date(feePaymentFormData?.feeEndDate).getTime() && (
                            <div className={style.oldRecordPromtContainer}>
                                <div className={style.oldRecordPromt}>
                                    Receipt will not be sent to parent as this payment will be
                                    considered as old record!
                                </div>
                            </div>
                        )}
                        <div className={style.containerWrapper}>
                            <div className={style.containerLeftSide}>
                                <div className={style.containerLeftSidediv}>
                                    <h1 className={style.containerLeftSidedivText}>Student Name</h1>
                                    <h1>
                                        {
                                            activeCollectFeeStudent?.attributes?.student?.data
                                                ?.attributes?.firstName
                                        }
                                    </h1>
                                </div>
                                <div className={style.containerLeftSidediv}>
                                    <h1 className={style.containerLeftSidedivText}>
                                        Last Received Amount in Rs
                                    </h1>
                                    <h1>
                                        {
                                            activeCollectFeeStudent?.attributes?.fee_dates[
                                                activeCollectFeeStudent?.attributes?.fee_dates
                                                    .length - 1
                                            ].amount
                                        }
                                    </h1>
                                </div>
                                <div className={style.containerLeftSidediv}>
                                    <h1 className={style.containerLeftSidedivText}>
                                        Balance Amount from previous payment in Rs
                                    </h1>
                                    <h1>{activeCollectFeeStudent?.attributes?.balance}</h1>
                                </div>
                                <div className={style.containerLeftSidediv}>
                                    <h1 className={style.containerLeftSidedivText}>
                                        Last Fees Paymnent Schedule
                                    </h1>
                                    <h1>{activeCollectFeeStudent?.attributes.payment_schedule}</h1>
                                </div>
                                <div className={style.containerLeftSidediv}>
                                    <h1 className={style.containerLeftSidedivText}>
                                        Number of Sessions in a Week
                                    </h1>
                                    <h1>{activeCollectFeeStudent?.attributes.sessions}</h1>
                                </div>
                                <div className={style.containerLeftSidediv}>
                                    <h1 className={style.containerLeftSidedivText}>
                                        Current Class Information
                                    </h1>
                                    <h1>
                                        {
                                            activeCollectFeeStudent?.attributes?.class?.data
                                                ?.attributes?.class_name
                                        }
                                    </h1>
                                </div>
                                <div className={style.containerLeftSidediv}>
                                    <h1 className={style.containerLeftSidedivText}>
                                        Fees start date
                                    </h1>
                                    <h1>
                                        {getFormattedDate(
                                            activeCollectFeeStudent?.attributes?.fee_dates[0]
                                                ?.startDate ?? ''
                                        )}
                                    </h1>
                                </div>
                                <div className={style.containerLeftSidediv}>
                                    <h1 className={style.containerLeftSidedivText}>
                                        Fees end date
                                    </h1>
                                    <h1>
                                        {getFormattedDate(
                                            new Date(
                                                activeCollectFeeStudent?.attributes?.fee_dates[0]
                                                    ?.dueDate ?? ''
                                            )
                                        )}
                                    </h1>
                                </div>
                                <div className={style.containerLeftSidediv}>
                                    <h1 className={style.containerLeftSidedivText}>
                                        Fees payment date
                                    </h1>
                                    <h1>
                                        {getFormattedDate(
                                            new Date(
                                                activeCollectFeeStudent?.attributes?.fee_dates[0]
                                                    ?.fee_date ?? ''
                                            )
                                        )}
                                    </h1>
                                </div>
                                <div className={style.containerLeftSidediv}>
                                    <h1 className={style.containerLeftSidedivText}>
                                        Fees to be paid for upcoming sessions in Rs
                                    </h1>
                                    <h1>{activeCollectFeeStudent?.attributes?.amount}</h1>
                                </div>
                                {activeCollectFeeStudent?.attributes?.fee_dates[0]?.message && (
                                    <div className={style.containerLeftSidediv}>
                                        <div className="w-full bg-slate-100 rounded-lg py-1 px-4">
                                            <div>
                                                {
                                                    activeCollectFeeStudent?.attributes
                                                        ?.fee_dates[0]?.message
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )}

                                <div className={style.containerLeftSidediv}>
                                    <h1 className={style.containerLeftSidedivText}></h1>
                                    <h1
                                        className={style.containerLeftSidedivToggle}
                                        onClick={() => setshowFields(!showFields)}
                                    >
                                        Edit Schedule, Number of sessions or Fees amount
                                    </h1>
                                </div>

                                {showFields && (
                                    <>
                                        <div className={style.containerLeftSideTogglediv}>
                                            <h1 className={style.containerLeftSidedivText}>
                                                New Payment Schedule
                                            </h1>

                                            <select
                                                value={feePaymentFormData.payment_schedule}
                                                onChange={e =>
                                                    handleFeeFormInputChange(
                                                        'payment_schedule',
                                                        e.target.value
                                                    )
                                                }
                                            >
                                                <option value="">Choose a payment schedule</option>
                                                <option value="Monthly">Monthly</option>
                                                <option value="Quarterly">Quarterly</option>
                                                <option value="Semi-Annually">Semi-Annually</option>
                                                <option value="Annually">Annually</option>
                                            </select>
                                        </div>

                                        <div className={style.containerLeftSideTogglediv}>
                                            <h1 className={style.containerLeftSidedivText}>
                                                Number of Sessions in a Week
                                            </h1>
                                            <select
                                                value={feePaymentFormData?.sessions ?? ''}
                                                onChange={e =>
                                                    handleFeeFormInputChange(
                                                        'sessions',
                                                        e.target.value
                                                    )
                                                }
                                            >
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                            </select>
                                        </div>

                                        {/* <div
                                            className={
                                                style.containerLeftSideTogglediv
                                            }>
                                            <h1
                                                className={
                                                    style.containerLeftSidedivText
                                                }>
                                                New Class
                                            </h1>

                                            <select
                                                value={feePaymentFormData.class ?? ""}
                                                onChange={(e) => handleFeeFormInputChange("class",e.target.value)}
                                            >
                                                {allClasses?.filter(item => item?.attributes?.discipline === activeCollectFeeStudent?.attributes?.class?.data?.attributes?.discipline).map(item => 
                                                    <option key={item.id} value={item.id}>{item?.attributes?.class_name}</option>    
                                                )}
                                            </select>
                                        </div> */}

                                        <div className={style.containerLeftSideTogglediv}>
                                            <h1 className={style.containerLeftSidedivText}>
                                                Fees to be paid for upcoming sessions in Rs{' '}
                                                <span className="text-red-600">*</span>
                                            </h1>
                                            <input
                                                placeholder="Fees in Rs to be collected due to change in schedule, session or class"
                                                type="number"
                                                className={style.formInput}
                                                onChange={e =>
                                                    handleFeeFormInputChange(
                                                        'amount',
                                                        e.target.value
                                                    )
                                                }
                                                value={feePaymentFormData.amount ?? 0}
                                                disabled={
                                                    activeCollectFeeStudent?.attributes
                                                        .payment_schedule ===
                                                        feePaymentFormData.payment_schedule &&
                                                    activeCollectFeeStudent?.attributes.sessions ==
                                                        feePaymentFormData.sessions &&
                                                    activeCollectFeeStudent?.attributes.class.data
                                                        .id == feePaymentFormData.class
                                                }
                                            />
                                            <span>
                                                {number2text(feePaymentFormData.amount ?? 0)}
                                            </span>
                                        </div>
                                    </>
                                )}

                                <div className={style.containerLeftSidediv}>
                                    <h1 className={style.containerLeftSidedivText}></h1>
                                    <h1
                                        className={style.containerLeftSidedivToggle}
                                        onClick={() => setIsLeaveRecordOpen(prev => !prev)}
                                    >
                                        View leave records
                                    </h1>
                                </div>
                                {isLeaveRecordOpen &&
                                    (leaveRecordData && leaveRecordData.length > 0 ? (
                                        <div className="p-1 border border-gray-50">
                                            <div className="font-semibold">
                                                Leave records: {leaveRecordData.length}
                                            </div>
                                            <div className="flex gap-1 flex-wrap">
                                                {[...leaveRecordData]
                                                    .sort((a, b) => {
                                                        const dateA = new Date(
                                                            a.attributes.attendance_date
                                                        ).getTime();
                                                        const dateB = new Date(
                                                            b.attributes.attendance_date
                                                        ).getTime();
                                                        return dateA - dateB;
                                                    })
                                                    .map(data => (
                                                        <div
                                                            key={data?.id}
                                                            className="p-1 bg-slate-100 rounded"
                                                        >
                                                            {new Date(
                                                                data?.attributes?.attendance_date
                                                            )
                                                                .toDateString()
                                                                .slice(4)}
                                                        </div>
                                                    ))}
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="font-semibold">No Leave records</div>
                                    ))}
                            </div>
                            <div className={style.containerRightSide}>
                                <div className={style.containerRightSidediv}>
                                    <h1 className={style.containerRightSidedivText}>
                                        Fees Received in Rs
                                        <span className="text-red-600">*</span>
                                    </h1>

                                    <input
                                        required
                                        placeholder="Fee amount received in Rs"
                                        type="number"
                                        value={feePaymentFormData?.feePaymentAmount ?? 0}
                                        onChange={e =>
                                            handleFeeFormInputChange(
                                                'feePaymentAmount',
                                                e.target.value
                                            )
                                        }
                                    />
                                    {feePaymentFormData?.feePaymentAmount && (
                                        <span>
                                            {number2text(
                                                Number(feePaymentFormData.feePaymentAmount) ?? 0
                                            )}
                                        </span>
                                    )}
                                </div>
                                <div className={style.containerRightSidediv}>
                                    <h1 className={style.containerRightSidedivText}>
                                        Balance fees in Rs
                                    </h1>

                                    <input
                                        placeholder="Balance fees amount in Rs"
                                        type="number"
                                        value={feePaymentFormData?.balance ?? 0}
                                        onChange={e =>
                                            handleFeeFormInputChange('balance', e.target.value)
                                        }
                                    />
                                    <span>{number2text(feePaymentFormData.balance??0)}</span>
                                </div>

                                <div className={style.containerRightSidediv}>
                                    <h1 className={style.containerRightSidedivText}>
                                        <span className="text-red-600">*</span>
                                        Fees Start Date
                                    </h1>
                                    <input
                                        value={feePaymentFormData?.feeStartDate}
                                        onChange={e =>
                                            handleFeeFormInputChange('feeStartDate', e.target.value)
                                        }
                                        type="date"
                                        name="feesStartDate"
                                    />
                                </div>

                                <div className={style.containerRightSidediv}>
                                    <h1 className={style.containerRightSidedivText}>
                                        <span className="text-red-600">*</span>
                                        Fees End Date
                                    </h1>
                                    <input
                                        value={feePaymentFormData?.feeEndDate}
                                        onChange={e =>
                                            handleFeeFormInputChange('feeEndDate', e.target.value)
                                        }
                                        type="date"
                                        name="feesEndDate"
                                    />
                                </div>

                                <div className={style.containerRightSidediv}>
                                    <h1 className={style.containerRightSidedivText}>
                                        <span className="text-red-600">*</span>
                                        Fees Payment Date
                                    </h1>
                                    <input
                                        value={feePaymentFormData?.feePaymentDate}
                                        onChange={e =>
                                            handleFeeFormInputChange(
                                                'feePaymentDate',
                                                e.target.value
                                            )
                                        }
                                        type="date"
                                        name="feesPaymentDate"
                                    />
                                </div>

                                <div className={style.containerRightSidediv}>
                                    <h1 className={style.containerRightSidedivText}>
                                        Mode of Payment
                                    </h1>
                                    <select
                                        name="paymentMethod"
                                        value={feePaymentFormData?.feePaymentMode}
                                        onChange={e =>
                                            handleFeeFormInputChange(
                                                'feePaymentMode',
                                                e.target.value
                                            )
                                        }
                                    >
                                        <option
                                            value=""
                                            disabled
                                            selected
                                        >
                                            Select Payment Method
                                        </option>
                                        <option value="cash">Cash</option>
                                        <option value="upi">UPI</option>
                                        <option value="internetBanking">Internet Banking</option>
                                        <option value="others">Others</option>
                                    </select>
                                </div>

                                {activeCollectFeeStudent?.attributes?.academy?.data?.attributes
                                    ?.bankAccounts &&
                                    feePaymentFormData.feePaymentMode !== 'cash' && (
                                        <div className={style.containerRightSidediv}>
                                            <h1 className={style.containerRightSidedivText}>
                                                Amount Received in
                                            </h1>
                                            <select
                                                name="account"
                                                value={feePaymentFormData?.accReceivedIn}
                                                onChange={e =>
                                                    handleFeeFormInputChange(
                                                        'accReceivedIn',
                                                        e.target.value
                                                    )
                                                }
                                            >
                                                <option value="">Select a Bank Account</option>
                                                {activeCollectFeeStudent?.attributes?.academy?.data?.attributes?.bankAccounts
                                                    ?.split(',')
                                                    .map((acc, index) => (
                                                        <option
                                                            key={index}
                                                            value={acc}
                                                        >
                                                            {acc}
                                                        </option>
                                                    ))}
                                            </select>
                                        </div>
                                    )}
                                <div className={style.containerRightSidediv}>
                                    <h1 className={style.containerRightSidedivText}>Message</h1>
                                    <textarea
                                        placeholder="Enter your message here"
                                        name="message"
                                        rows={3}
                                        value={feePaymentFormData?.feePaymentMessage}
                                        onChange={e =>
                                            handleFeeFormInputChange(
                                                'feePaymentMessage',
                                                e.target.value
                                            )
                                        }
                                        className="resize-none"
                                    ></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button
                        className={style.feecollectButton}
                        type="submit"
                    >
                        Collect Fees
                    </button>
                </div>
            </form>
        </div>
    );
};

export default ExistingFeecollectionForm;
