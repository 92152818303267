import { invokeApi } from "../utils/helpers/invoke-api";

export class ErrorService {
    private static _instance: ErrorService;
    private constructor() {}

    public static get instance() {
        return this._instance ?? (this._instance = new ErrorService());
    }

    public postError = async (data: object) => {
        return invokeApi({
            route: `error-logs`,
            method: 'POST',
            privateRoute: true,
            data: { data },
        });
    };
}