import React from 'react';
import style from './style.module.css';
import { StudentData } from '../../../../models/student/student-data';

interface FormValues {
    leaveFrom: string;
    leaveTo: string;
    message: string;
}

interface RecordLeaveFormProps {
    studentData: StudentData | undefined;
    formValues: FormValues;
    handleInputChange: (fieldName: string, value: string) => void;
    handleFormSubmit: () => void;
    isRecordPostSuccess: boolean;
}

const RecordLeaveForm = ({
    studentData,
    formValues,
    handleInputChange,
    handleFormSubmit,
    isRecordPostSuccess,
}: RecordLeaveFormProps) => {
    return (
        <div className={style.formWrapper}>
            <div className={style.formContainer}>
                {isRecordPostSuccess ? (
                    <div className={style.successMessage}>Leave Record successfully submitted!</div>
                ) : (
                    <>
                        <div className={style.formTitle}>Record Leaves</div>
                        <div className={style.formInputLabel}>Student Name</div>
                        <div className={style.formInput}>
                            {studentData?.attributes?.firstName ?? ''}{' '}
                            {studentData?.attributes?.middleName ?? ''}{' '}
                            {studentData?.attributes?.lastName ?? ''}
                        </div>
                        <div className={style.formInputLabel}>Class Information</div>
                        <div className={style.formInput}>
                                {studentData?.attributes?.classes?.data
                                .filter(item=>item.attributes.status !== "INACTIVE")
                                .map(classItem => classItem?.attributes?.class_name)
                                .join(', ')}
                        </div>
                        <div className={style.formInputLabel}>Leave From</div>
                        <input
                            className={style.formInput}
                            type="date"
                            placeholder="Leave start date"
                            required
                            value={formValues.leaveFrom}
                            onChange={e => handleInputChange('leaveFrom', e.target.value)}
                        />
                        <div className={style.formInputLabel}>Leave To</div>
                        <input
                            className={style.formInput}
                            type="date"
                            placeholder="Leave end date"
                            required
                            value={formValues.leaveTo}
                            onChange={e => handleInputChange('leaveTo', e.target.value)}
                        />
                        <div className={style.formInputLabel}>Message</div>
                        <textarea
                            className={style.formInput}
                            placeholder="Your message"
                            value={formValues.message}
                            onChange={e => handleInputChange('message', e.target.value)}
                        />
                        <button
                            className={style.formSubmitBtn}
                            onClick={handleFormSubmit}
                        >
                            Record Leaves
                        </button>
                    </>
                )}
            </div>
        </div>
    );
};

export default RecordLeaveForm;
