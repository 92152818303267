import { ErrorService } from "../../services/error-service";

const ErrorLogViewModel = () => {

    const postError = async (data: object) => {
            
        try {
            const response = await ErrorService.instance.postError(data);

            if (response.success) {
                response.data;
            } else {
                throw new Error(response.error?.message);
            }
        } catch (error) {
            console.error(`Error in posting error: ${error}`);
            throw error;
        }
    };
    
    return { postError };
}

export default ErrorLogViewModel;
