import React from 'react';
import style from './style.module.css';

interface DropdownListOption {
    label: string;
    action: string;
}

interface DropdownListProps {
    options: DropdownListOption[];
    goToStudentDetails?: () => void  ;
    goToFeeHistory?: () => void ;
    gotoFeeCollection: () => void;
    openRecordLeaveFormModal: () => void;
    openStudentDropoutConfirmation?: () => void;
    openStudentEditForm?: () => void;
    className?:string
}

const DropdownList = ({
    options,
    goToStudentDetails,
    goToFeeHistory,
    gotoFeeCollection,
    openRecordLeaveFormModal,
    openStudentDropoutConfirmation,
    openStudentEditForm,
    className
}: DropdownListProps) => {
    return (
        <div>
            <ul className={className? 'mt-10 bg-white w-[131px] shadow-[0_0_10px_2px_rgba(0,0,0,0.1)] px-0 py-[5px]' : style.dropdownListContainer}>
                {options.map((option, index) => (
                    <li
                        key={index}
                        className={
                            className
                                ? `${className} leading-5 tracking-[0px] text-[#222222] bg-white cursor-pointer pl-3 pr-1.5 py-1.5 hover:bg-neutral-100;`
                                : style.dropdownListItem
                        }
                        onClick={() => {
                            switch (option.action) {
                                case 'GO_TO_DETAIL':
                                    goToStudentDetails ? goToStudentDetails() : () => {};
                                    break;
                                case 'GO_TO_FEE_HISTORY':
                                    goToFeeHistory ? goToFeeHistory() : () => {};
                                    break;
                                case 'GO_TO_COLLECT_FEE':
                                    gotoFeeCollection();
                                    break;
                                case 'OPEN_RECORD_LEAVE_FORM':
                                    openRecordLeaveFormModal();
                                    break;
                                case 'OPEN_STUDENT_DROPOUT_PROMPT':
                                    openStudentDropoutConfirmation
                                        ? openStudentDropoutConfirmation()
                                        : () => {};
                                    break;
                                case 'OPEN_STUDENT_EDIT_FORM':
                                    openStudentEditForm ? openStudentEditForm() : () => {};
                                    break;
                                default:
                                    break;
                            }
                        }}
                    >
                        {option.label}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default DropdownList;
