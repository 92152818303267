import React, { useEffect } from 'react'
import CertificatePromotionViewModel from '../../view-models/actions-view-models/certificate-promotion-view-model'
import PopupModal from '../../components/widgets/popup-modal';
import CertificatePromotionForm from '../../components/widgets/certificate-promotion-form';
import { CertificationData } from '../../../models/certifications/certification-data';
import { StudentCertificateRecordData } from '../../../models/certifications/student-certificate-record-data';
import FullScreenLoader from '../../components/bits/fullscreen-loader';

interface CertificatePromotionProps {
    isOpen: boolean;
    handleClose: () => void;
    selectedStudents: number[];
    selectedCertificationData: CertificationData;
    certificationsList: CertificationData[];
    certificationStudentRecordsList: StudentCertificateRecordData[];
}

const CertificatePromotion = ({
    isOpen,
    handleClose,
    selectedStudents,
    selectedCertificationData,
    certificationStudentRecordsList,
    certificationsList,
}: CertificatePromotionProps) => {
    const {
        handleModalClose,
        isLoading,
        selectedLevelToPromote,
        setSelectedLevelToPromote,
        handleAddStudentCertificateRecord,
    } = CertificatePromotionViewModel({
        handleClose, 
        selectedStudents, 
        selectedCertificationData, 
        certificationsList, 
        certificationStudentRecordsList
    });

    useEffect(() => {
        setSelectedLevelToPromote(selectedCertificationData?.attributes?.level + 1 ?? 0);
    }, [isOpen,selectedCertificationData]);

    return (
        <>
            {isLoading && <FullScreenLoader/>}
            <PopupModal
                title="Promote Students"
                isOpen={isOpen}
                handleModalClose={handleModalClose}
                background='white'
            >
                <CertificatePromotionForm 
                    selectedStudents={selectedStudents}
                    selectedLevelToPromote={selectedLevelToPromote}
                    setSelectedLevelToPromote={setSelectedLevelToPromote}
                    selectedCertificationData={selectedCertificationData}
                    certificationsList={certificationsList}
                    handleAddStudentCertificateRecord={handleAddStudentCertificateRecord}
                />
            </PopupModal>
        </>
    )
}

export default CertificatePromotion