import { ChangeEvent, useEffect, useState } from 'react';
import {
    ACADEMY_QUERY,
    FEE_PAYMENT_QUERY,
    FEE_STRUCTURES_LIST_DATA_QUERY,
    SCHEDULE_QUERY,
    STUDENT_QUERY,
    STUDENT_SUGGESTION_QUERY,
    STUDENTS_FEE_DETAILS_QUERY,
} from '../../../utils/constants/constants';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { AcademiesService } from '../../../services/academies-service';
import { useSelector } from 'react-redux';
import { RootState } from '../../../utils/redux/store';
import ClassService from '../../../services/class-service';
import { useDebounce } from 'use-debounce';
import { HeaderMenuService } from '../../../services/header-menu-service';
import { StudentData } from '../../../models/student/student-data';
import { FeePaymentData } from '../../../models/fee/fee-payment-data';
import { formatDateToInputDate } from '../../../utils/helpers/helpers';
import { DashboardService } from '../../../services/dashboard-service';
import { addFrequencyToDate } from '../fee-payment-viewmodel';
import { StudentService } from '../../../services/student-service';
import { FeePaymentPostData } from '../../../models/fee/fee-payment-post-data';
import { FeePaymentPostDataWrapper } from '../../../models/fee/fee-payment-post-data-wrapper';
import { FeePaymentService } from '../../../services/fee-payment-service';

export interface FeeStructureFormDataType {
    academy: number | null;
}

export interface FeePaymentFormDataType {
    payment_schedule: string;
    sessions: number | null;
    amount: number | null;
    balance: number | null;
    academy: number | null;
    student: number | null;
    class: number | null;
    feeStartDate: string;
    accReceivedIn: string;
    feeEndDate: string;
    feePaymentDate: string;
    feePaymentAmount: string;
    feePaymentMode: string;
    feePaymentMessage: string;
    owner: number | null;
}

const CollectFeesViewModel = ({
    handleClose,
    isStudentChangable,
}: {
    handleClose: () => void;
    isStudentChangable: boolean;
}) => {
    const userState = useSelector((state: RootState) => state.user);

    // const {isSuccess: isAcademiesFetchSuccess, data: academiesData} = useQuery({
    //     queryKey: [ACADEMY_QUERY],
    //     queryFn: AcademiesService.instance.getAcademyList.bind(
    //         this,
    //         userState?.user?.id ?? 0,
    //     ),
    //     refetchOnWindowFocus: false,
    //     enabled: (userState?.user?.id ?? 0) > 0,
    // });

    const {
        isSuccess: isClassFetchSuccess,
        isLoading: isClassFetching,
        data: allClasses,
    } = useQuery({
        queryKey: [SCHEDULE_QUERY],
        queryFn: () => ClassService.instance.getClasses(userState?.user?.id ?? 0),
        refetchOnWindowFocus: false,
        enabled: (userState?.user?.id ?? 0) > 0,
    });

    const initialFeePaymentFormData = {
        payment_schedule: '',
        sessions: null,
        amount: null,
        balance: null,
        academy: null,
        student: null,
        class: null,
        feeStartDate: '',
        accReceivedIn: '',
        feeEndDate: '',
        feePaymentDate: formatDateToInputDate(new Date()),
        feePaymentAmount: '',
        // feePaymentAmountInWords: '',
        feePaymentMode: '',
        feePaymentMessage: '',
        owner: null,
    };

    const [feePaymentFormData, setFeePaymentFormData] =
        useState<FeePaymentFormDataType>(initialFeePaymentFormData);
    const [searchTerm, setSearchTerm] = useState('');
    const [value] = useDebounce(searchTerm, 1000);
    const [selectedStudentData, setSelectedStudentData] = useState<StudentData>();
    const [selectedStudentClassId, setSelectedStudentClassId] = useState<number>();
    const [isSearchInputOnFocus, setIsSearchInputOnFocus] = useState(false);
    const [confirmedStudentClassId, setConfirmedStudentClassId] = useState<number>();
    const [inactiveFeeData, setInactiveFeeData] = useState<FeePaymentData[]>();
    const [expandInactive, setExpandInactive] = useState<number[]>([]);
    const queryClient = useQueryClient();

    const {
        isSuccess: isStudentSuggesionDataFetchSuccess,
        isLoading: isStudentDataFetchLoading,
        data: studentSuggestionListData,
    } = useQuery({
        queryKey: [STUDENT_SUGGESTION_QUERY, value],
        queryFn: async () => {
            try {
                const response = HeaderMenuService.instance.getStudentList(
                    userState?.user?.id ?? 0,
                    value
                );
                return response;
            } catch (error) {
                console.error('Error fetching student suggestion data:', error);
                throw error;
            }
        },
        refetchOnWindowFocus: false,
        enabled: searchTerm.length >= 0,
    });

    const {
        isSuccess: isStudentsClassFeeDataFetchSuccess,
        isLoading: isStudentsClassFeeDataLoading,
        data: studentsClassFeeData,
    } = useQuery({
        queryKey: [STUDENTS_FEE_DETAILS_QUERY, selectedStudentData?.id],
        queryFn: () =>
            DashboardService.instance.getStudentsClassFeeData(selectedStudentData?.id ?? 0),
        refetchOnWindowFocus: false,
        enabled:
            (userState?.user?.id ?? 0) > 0 &&
            Boolean(selectedStudentClassId) &&
            Boolean(selectedStudentData),
    });

    const { isSuccess: isStudentsFetchSuccess, data: studentsData } = useQuery({
        queryKey: [STUDENT_QUERY],
        queryFn: StudentService.instance.getActiveStudentList.bind(this, userState?.user?.id ?? 0),
        refetchOnWindowFocus: false,
        enabled: (userState?.user?.id ?? 0) > 0,
    });

    const handlesearchInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event?.target?.value);
    };

    useEffect(() => {
        if (selectedStudentData && selectedStudentData?.attributes?.classes?.data?.length === 1) {
            setSelectedStudentClassId(selectedStudentData?.attributes?.classes?.data[0]?.id);
        }
    }, [selectedStudentData]);

    const onStudentSearchListItemClick = (item: StudentData) => {
        setSearchTerm(item?.attributes?.firstName || '' + ' ' + item?.attributes?.lastName || '');
        setSelectedStudentData(item);
    };

    useEffect(() => {
        if (studentsClassFeeData?.data?.data && studentsClassFeeData?.data?.data.length > 0) {
            const selectedStudentFeeData = studentsClassFeeData?.data?.data?.filter(
                fee => fee?.attributes?.class?.data?.id === confirmedStudentClassId
            );
            // console.log(selectedStudentFeeData);
            if (selectedStudentFeeData.length > 0) {
                setFeePaymentFormData({
                    payment_schedule: selectedStudentFeeData[0]?.attributes?.payment_schedule,
                    sessions: selectedStudentFeeData[0]?.attributes?.sessions,
                    amount: selectedStudentFeeData[0]?.attributes?.amount,
                    balance: selectedStudentFeeData[0]?.attributes?.balance,
                    academy: selectedStudentFeeData[0]?.attributes?.academy?.data?.id,
                    // feeStartDate: new Date(selectedStudentFeeData[0].attributes?.fee_dates[1]?.dueDate).toString(),
                    // feeEndDate: new Date(selectedStudentFeeData[0].attributes?.fee_dates[0]?.dueDate).toString(),
                    feeStartDate: selectedStudentFeeData[0]?.attributes?.fee_dates[0]?.dueDate
                        ? formatDateToInputDate(
                              new Date(
                                  new Date(
                                      selectedStudentFeeData[0]?.attributes?.fee_dates[0]?.dueDate
                                  ).getTime() + 86400000
                              )
                          )
                        : '',
                    feeEndDate: formatDateToInputDate(
                        addFrequencyToDate(
                            new Date(selectedStudentFeeData[0]?.attributes?.fee_dates[0]?.dueDate),
                            selectedStudentFeeData[0]?.attributes?.payment_schedule
                        )
                    ),
                    student: selectedStudentFeeData[0]?.attributes?.student?.data?.id,
                    class: selectedStudentFeeData[0]?.attributes?.class?.data?.id,
                    accReceivedIn:
                        selectedStudentFeeData[0]?.attributes?.fee_dates[0].accReceivedIn,
                    feePaymentDate: formatDateToInputDate(new Date()),
                    feePaymentAmount: '',
                    feePaymentMode: '',
                    feePaymentMessage: '',
                    owner: userState.user?.id ?? 0,
                });
            } else {
                const activeClassIds = selectedStudentData?.attributes?.classes?.data.map(
                    classItem => classItem.id
                );
                const inactiveFeeData = studentsClassFeeData?.data?.data?.filter(
                    item => !activeClassIds?.includes(item?.attributes?.class?.data?.id)
                );

                inactiveFeeData.sort(
                    (a, b) =>
                        new Date(b?.attributes.fee_dates[0].fee_date).getTime() -
                        new Date(a?.attributes.fee_dates[0].fee_date).getTime()
                );

                setInactiveFeeData(inactiveFeeData);
            }
        }
    }, [studentsClassFeeData, confirmedStudentClassId]);

    useEffect(() => {
        setFeePaymentFormData(initialFeePaymentFormData);
    }, [selectedStudentClassId]);

    useEffect(() => {
        setFeePaymentFormData(prev => ({
            ...prev,
            feeEndDate: formatDateToInputDate(
                addFrequencyToDate(new Date(prev.feeStartDate), prev.payment_schedule)
            ),
        }));
    }, [feePaymentFormData.payment_schedule]);

    const handleClassInputChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedStudentClassId(parseInt(e.target.value));
        setFeePaymentFormData(initialFeePaymentFormData);
    };

    const handleStudentFeeSearch = () => {
        if (selectedStudentClassId && selectedStudentData) {
            setConfirmedStudentClassId(selectedStudentClassId);
            queryClient.invalidateQueries(STUDENTS_FEE_DETAILS_QUERY);
        } else {
            alert('Please select student and class!');
        }
    };

    const handleFeeFormInputChange = (field: string, value: string | number) => {
        setFeePaymentFormData(prevFormData => ({
            ...prevFormData,
            [field]: value,
        }));
    };

    useEffect(() => {
        
    }, [feePaymentFormData.amount, feePaymentFormData.balance, feePaymentFormData.feePaymentAmount]);

    const updateFeeCollection = async (
        data: FeePaymentPostData,
        id: number
    ): Promise<FeePaymentPostDataWrapper | undefined> => {
        const response = await FeePaymentService.instance.updateFeeCollection(data, id);
        if (response.success) {
            return response?.data;
        } else {
            // console.log(`On Add Class Error ${JSON.stringify(response.error)}`);
            throw new Error(response.error?.message);
        }
    };

    const {
        mutate: editFeeCollection,
        isLoading: isFeeCollectionUpdating,
        isSuccess: isFeeCollectionUpdated,
    } = useMutation(
        ({ feePaymentPostData, id }: { feePaymentPostData: FeePaymentPostData; id: number }) =>
            updateFeeCollection(feePaymentPostData, id),
        {
            onSuccess: data => {
                alert('Added Fee Collection Successfully!');
                handleClose();
                setInactiveFeeData([]);
                setSelectedStudentClassId(undefined);
                setConfirmedStudentClassId(undefined);
                setFeePaymentFormData(initialFeePaymentFormData);
                setSelectedStudentData(undefined);
                setSearchTerm('');
                queryClient.invalidateQueries(STUDENTS_FEE_DETAILS_QUERY);
                queryClient.invalidateQueries(FEE_PAYMENT_QUERY);
            },
            onError: error => {
                alert('Failed to create fee collection! Please try again');
            },
        }
    );

    const postNewFeeCollection = async (
        data: FeePaymentPostData
    ): Promise<FeePaymentPostDataWrapper | undefined> => {
        const response = await FeePaymentService.instance.postNewFeeCollection(data);
        if (response.success) {
            return response?.data;
        } else {
            // console.log(`On Add Class Error ${JSON.stringify(response.error)}`);
            throw new Error(response.error?.message);
        }
    };

    const {
        mutate: addNewFeeCollection,
        isLoading: isFeeCollectionAdding,
        isSuccess: isFeeCollectionAdded,
    } = useMutation(
        (feePaymentPostData: FeePaymentPostData) => postNewFeeCollection(feePaymentPostData),
        {
            onSuccess: data => {
                alert('Added Fee Collection Successfully!');
                handleClose();
                setSelectedStudentClassId(undefined);
                setConfirmedStudentClassId(undefined);
                setFeePaymentFormData(initialFeePaymentFormData);
                setSelectedStudentData(undefined);
                setSearchTerm('');
                queryClient.invalidateQueries(STUDENTS_FEE_DETAILS_QUERY);
                queryClient.invalidateQueries(FEE_PAYMENT_QUERY);
            },
            onError: error => {
                // console.log(`On class add Error:`, error);
                alert('Failed to create fee collection! Please try again');
            },
        }
    );

    const handleFeeCollectSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (confirmedStudentClassId === undefined) {
            alert('Please search for existing fee records before submitting!');
            return;
        }

        if (
            studentsClassFeeData?.data?.data &&
            studentsClassFeeData?.data?.data?.filter(
                item => item.attributes.class.data.id === confirmedStudentClassId
            ).length > 0
        ) {
            const requestBody = {
                payment_schedule: feePaymentFormData.payment_schedule,
                sessions: feePaymentFormData.sessions ?? 0,
                amount: feePaymentFormData.amount ?? 0,
                balance: feePaymentFormData.balance ?? null,
                academy: feePaymentFormData.academy ?? 0,
                student: feePaymentFormData.student ?? 0,
                class: feePaymentFormData.class ?? 0,
                fee_dates: [
                    {
                        dueDate: new Date(feePaymentFormData.feeEndDate),
                        startDate: new Date(feePaymentFormData.feeStartDate),
                        fee_date: new Date(feePaymentFormData.feePaymentDate),
                        amount: parseInt(feePaymentFormData.feePaymentAmount) ?? 0,
                        payment_mode: feePaymentFormData.feePaymentMode,
                        message: feePaymentFormData.feePaymentMessage,
                        ...(feePaymentFormData.feePaymentMode !== 'cash'
                            ? { accReceivedIn: feePaymentFormData.accReceivedIn }
                            : {}),
                    },
                    ...(studentsClassFeeData.data.data[0]?.attributes.fee_dates ?? []),
                ],
                users: [
                    ...(studentsClassFeeData?.data?.data[0]?.attributes.users.data.map(
                        item => item.id
                    ) || []),
                    userState.user?.id ?? 0,
                ],
            };

            editFeeCollection({
                feePaymentPostData: requestBody,
                id: studentsClassFeeData.data.data[0].id ?? 0,
            });
        } else {
            const requestBody = {
                payment_schedule: feePaymentFormData.payment_schedule,
                sessions: feePaymentFormData.sessions ?? 0,
                amount: feePaymentFormData.amount ?? 0,
                balance: feePaymentFormData.balance ?? null,
                academy:
                    allClasses?.data?.data?.filter(item => item?.id === confirmedStudentClassId)[0]
                        ?.attributes?.academies?.data?.id ?? 0,
                student: selectedStudentData?.id ?? 0,
                class: confirmedStudentClassId ?? 0,
                dueDate: feePaymentFormData.feeEndDate,
                fee_dates: [
                    {
                        dueDate: new Date(feePaymentFormData.feeEndDate),
                        startDate: new Date(feePaymentFormData.feeStartDate),
                        fee_date: new Date(feePaymentFormData.feePaymentDate),
                        amount: parseInt(feePaymentFormData.feePaymentAmount) ?? 0,
                        payment_mode: feePaymentFormData.feePaymentMode,
                        message: feePaymentFormData.feePaymentMessage,
                        accReceivedIn: feePaymentFormData.accReceivedIn,
                    },
                ],
                users: [userState.user?.id ?? 0],
            };
            addNewFeeCollection(requestBody);
        }
    };

    const handleStudentFeeReset = () => {
        if (isStudentChangable) {
            setSelectedStudentClassId(undefined);
            setConfirmedStudentClassId(undefined);
            setFeePaymentFormData(initialFeePaymentFormData);
            setSelectedStudentData(undefined);
            setSearchTerm('');
            queryClient.invalidateQueries(STUDENTS_FEE_DETAILS_QUERY);
        } else {
            setSelectedStudentClassId(undefined);
            setConfirmedStudentClassId(undefined);
            setFeePaymentFormData(initialFeePaymentFormData);
            setSearchTerm('');
            queryClient.invalidateQueries(STUDENTS_FEE_DETAILS_QUERY);
        }
    };

    const handleExpandInactive = (index: number) => {
        if (expandInactive.includes(index)) {
            setExpandInactive(expandInactive?.filter(item => item !== index));
        } else {
            setExpandInactive([...expandInactive, index]);
        }
    };

    const handleFeeCollectionModalClose = () => {
        handleClose();
        setInactiveFeeData([]);
        setSelectedStudentClassId(undefined);
        setConfirmedStudentClassId(undefined);
        setFeePaymentFormData(initialFeePaymentFormData);
        setSelectedStudentData(undefined);
        setSearchTerm('');
        queryClient.invalidateQueries(STUDENTS_FEE_DETAILS_QUERY);
        queryClient.invalidateQueries(FEE_PAYMENT_QUERY);
    };

    return {
        isLoading: false,
        isStudentDataFetchLoading,
        studentSuggestionListData,
        searchTerm,
        handlesearchInputChange,
        onStudentSearchListItemClick,
        isSearchInputOnFocus,
        setIsSearchInputOnFocus,
        selectedStudentData,
        handleClassInputChange,
        handleStudentFeeSearch,
        studentsClassFeeData: studentsClassFeeData?.data?.data ?? [],
        feePaymentFormData,
        handleFeeFormInputChange,
        allClasses: allClasses?.data?.data ?? [],
        handleFeeCollectSubmit,
        confirmedStudentClassId,
        handleStudentFeeReset,
        selectedStudentClassId,
        inactiveFeeData,
        expandInactive,
        handleExpandInactive,
        setSelectedStudentData,
        handleFeeCollectionModalClose,
    };
};

export default CollectFeesViewModel;
