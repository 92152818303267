import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../utils/redux/store';
import {
    ACADEMY_QUERY,
    DISCIPLINE_QUERY,
    FEE_PAYMENT_QUERY,
    SCHEDULE_DETAILS_QUERY,
    SCHEDULE_QUERY,
    STUDENT_DETAILS_QUERY,
    USER_QUERY,
} from '../../utils/constants/constants';
import { QueryClient, useMutation, useQuery, useQueryClient } from 'react-query';
import { FeePaymentService } from '../../services/fee-payment-service';
import { FeePaymentState, feePaymentReducer } from '../reducers/fee-payment-reducer';
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { AcademyData } from '../../models/academy/academy-data';
import {
    differenceInDays,
    formatDateToInputDate,
    getFormattedDate,
    getJWTToken,
    isElementPresent,
    setJWTToken,
} from '../../utils/helpers/helpers';
import { setUser, setWalkthroughFlags } from '../../utils/redux/user-slice';
import { useImmerReducer } from 'use-immer';
import { FeeDate } from '../../models/fee/fee-date';
import { FeePaymentData } from '../../models/fee/fee-payment-data';
import { FeeReminderEmailAttributes } from '../../utils/types/fee-reminder-email-attributes';
import { useNavigate } from 'react-router-dom';
import ClassService from '../../services/class-service';
import { FeePaymentPostData } from '../../models/fee/fee-payment-post-data';
import { FeePaymentPostDataWrapper } from '../../models/fee/fee-payment-post-data-wrapper';
import { FeeAttribute } from '../../models/fee/fee-attribute';
import { StudentService } from '../../services/student-service';
import FeeReminderWhatsappMessageData from '../../utils/types/fee-reminder-whatsapp-message-data';
import { StudentData } from '../../models/student/student-data';
import { StudentPostDataWrapper } from '../../models/student/student-post-data-wrapper';
import AttendanceRequest from '../../utils/types/attendance-request';
import { AttendanceListWrapper } from '../../models/attendance/attendance-list-wrapper';
import { Routes } from '../navigation/routes';
import { studentAcademyHistoryPostData } from '../../models/student/student-activity-post-data';
import { AcademiesService } from '../../services/academies-service';
import { StudentPostData } from '../../models/student/student-post-data';
import ApiResponse from '../../utils/types/api-response';
import { useDebounce } from 'use-debounce';
import { DisciplinesService } from '../../services/disciplines-service';
import { DriveStep, driver } from 'driver.js';
import { log } from 'console';
import { UserPostData } from '../../models/user/user-post-data';
import { User } from '../../models/user/user';
import { UserService } from '../../services/user-services';
import { FEE_PAYMENT_PAGE_WALKTHROUGH } from '../../utils/constants/walkthroughConstants';
import { HeaderMenuService } from '../../services/header-menu-service';
import { filter } from 'lodash';
import { number2text } from '../../utils/helpers/helpers';
// import { setUser } from '../../utils/redux/user-slice';

export interface FeePaymentFormDataType {
    payment_schedule: string;
    sessions: number | null;
    amount: number | null;
    balance: number | null;
    academy: number | null;
    student: number | null;
    class: number | null;
    feeStartDate: string;
    accReceivedIn: string;
    feeEndDate: string;
    feePaymentDate: string;
    feePaymentAmount: string;
    feePaymentMode: string;
    feePaymentMessage: string;
    owner: number | null;
}

export interface EditingFeeDetailsFormDataType {
    id?: number;
    received: string;
    balance: string;
    previousSchedule: string;
    sessions: string;
    startDate: string;
    endDate: string;
    paymentDate: string;
    modeOfPayment: string;
    accReceivedIn: string;
    message: string;
    sendEmail: boolean;
}

export function addFrequencyToDate(inputDate: Date, frequency: string): Date {
    const newDate = new Date(inputDate);

    switch (frequency) {
        case 'Monthly':
            newDate.setMonth(newDate.getMonth() + 1);
            break;
        case 'Quarterly':
            newDate.setMonth(newDate.getMonth() + 3);
            break;
        case 'Semi-Annually':
            newDate.setMonth(newDate.getMonth() + 6);
            break;
        case 'Annually':
            newDate.setFullYear(newDate.getFullYear() + 1);
            break;
        case '':
            break;
        default:
            throw new Error('Invalid schedule');
    }

    return newDate;
}

const FeePaymentViewModel = () => {
    const initialState: FeePaymentState = {
        openTab: 1,
        academies: [],
        disciplines: [],
        pendingFee: [],
        receivedFee: [],
        queryString: '',
        currentPage: 1,
    };
    const [state, dispatch] = useImmerReducer(feePaymentReducer, initialState);

    const [dropDown, setDropDown] = useState(false);
    const queryClient = useQueryClient();

    const [isFeeCollectionModalOpen, setIsFeeCollectionModalOpen] = useState(false);
    const [checkedPendingFeeListItems, setCheckedPendingFeeListItems] = useState<FeePaymentData[]>(
        []
    );
    const [checkedReceivedFeeListItems, setCheckedReceivedFeeListItems] = useState<
        FeePaymentData[]
    >([]);
    const userState = useSelector((state: RootState) => state.user);
    const [isSendReminderClicked, setIsSendReminderClicked] = useState<boolean>(false);
    const [allPendingListItemsSelected, setAllPendingListItemsSelected] = useState<boolean>(false);
    const [allReceivedListItemsSelected, setAllReceivedListItemsSelected] =
        useState<boolean>(false);
    const [activeFeeCollection, setActiveFeeCollection] = useState<number>();
    const [FeeItemModelVisible, setFeeItemModelVisible] = useState<number>(0);
    const [selectedAcademiesForFilter, setSelectedAcademiesForFilter] = useState<number[]>([]);
    const [selectedDisciplinesForFilter, setSelectedDisciplinesForFilter] = useState<number[]>([]);
    const [selectedFeeScheduleForFilter, setSelectedFeeScheduleForFilter] = useState<string[]>([]);
    const [selectedBeforeEndDateRangeForFilter, setSelectedBeforeEndDateRangeForFilter] =
        useState<string>('');
    const [selectedBeforeEndDateForFilter, setSelectedBeforeEndDateForFilter] =
        useState<string>('');
    const [isReceivedSortOptionsVisible, setIsReceivedSortOptionsVisible] =
        useState<boolean>(false);
    const [isPendingSortOptionsVisible, setIsPendingSortOptionsVisible] = useState<boolean>(false);
    const [selectedReceivedSortOption, setSelectedReceivedSortOption] = useState<number>(4);
    const [selectedPendingSortOption, setSelectedPendingSortOption] = useState<number>(3);
    const [visibleFilter, setVisibleFilter] = useState<number>(0);
    const [moreActionsOpen, setMoreActionsOpen] = useState<number>(0);
    const [isFeeDetailsModalEditing, setIsFeeDetailsModalEditing] = useState<boolean>(false);
    const [feeStudentSearchTerm, setFeeStudentSearchTerm] = useState('');
    const [feeStudentSearchData] = useDebounce(feeStudentSearchTerm, 1000);

    const { isSuccess, data } = useQuery({
        queryKey: [FEE_PAYMENT_QUERY, state.queryString, feeStudentSearchData],
        queryFn: FeePaymentService.instance.getFeePaymentList.bind(
            this,
            userState?.user?.id ?? 0,
            state.queryString,
            feeStudentSearchData
        ),
        refetchOnWindowFocus: false,
        enabled: (userState?.user?.id ?? 0) > 0,
    });

    const { data: allClassesData } = useQuery({
        queryKey: [SCHEDULE_QUERY],
        queryFn: ClassService.instance.getClasses.bind(this, userState?.user?.id ?? 0),
        refetchOnWindowFocus: false,
        enabled: (userState?.user?.id ?? 0) > 0,
    });

    const { isSuccess: isDisciplineFetchSuccess, data: disciplinesList } = useQuery({
        queryKey: [DISCIPLINE_QUERY],
        queryFn: () => DisciplinesService.instance.getDisciplinesList(),
        refetchOnWindowFocus: false,
        enabled: (userState?.user?.id ?? 0) > 0,
    });

    const { isSuccess: isLeaveRecordFetchSuccess, data: leaveRecordData } = useQuery({
        queryKey: [SCHEDULE_DETAILS_QUERY],
        queryFn: FeePaymentService.instance.getLeaveRecordsList.bind(
            this,
            userState?.user?.id ?? 0
        ),
        refetchOnWindowFocus: false,
        enabled: (userState?.user?.id ?? 0) > 0,
    });

    const initialFeePaymentFormData = {
        payment_schedule: '',
        sessions: null,
        amount: null,
        balance: null,
        academy: null,
        student: null,
        class: null,
        feeStartDate: '',
        feeEndDate: '',
        feePaymentDate: '',
        accReceivedIn: '',
        feePaymentAmount: '',
        feePaymentMode: '',
        feePaymentMessage: '',
        owner: null,
    };
    const [activeCollectFeeStudent, setActiveCollectFeeStudent] = useState<FeePaymentData>();
    const [feePaymentFormData, setFeePaymentFormData] =
        useState<FeePaymentFormDataType>(initialFeePaymentFormData);
    const itemsPerPage = 10;
    const indexOfLastItem = state.currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const feeSchedules: string[] = ['Monthly', 'Quarterly', 'Semi-Annually', 'Annually'];
    const [openFilter, setOpenFilter] = useState(false);
    const [feesInWords, setFeesInWords] = useState('');
    const [feesRecievedInWords, setFeesRecievedInWords] = useState('');
    const [balanceFeesInWords, setBalanceFeesInWords] = useState('');

    const pendingSortDropdownRef = useRef<HTMLDivElement>(null);
    const receivedSortDropDownRef = useRef<HTMLDivElement>(null);
    const filterDropdownRef = useRef<HTMLDivElement>(null);
    const moreActionsDropdownRef = useRef<HTMLDivElement>(null);

    const usedispatch = useDispatch();

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (
                pendingSortDropdownRef.current &&
                !pendingSortDropdownRef.current.contains(event.target as Node)
            ) {
                setIsPendingSortOptionsVisible(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [pendingSortDropdownRef]);

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (
                receivedSortDropDownRef.current &&
                !receivedSortDropDownRef.current.contains(event.target as Node)
            ) {
                setIsReceivedSortOptionsVisible(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [receivedSortDropDownRef]);

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (
                filterDropdownRef.current &&
                !filterDropdownRef.current.contains(event.target as Node)
            ) {
                toggleVisibleFilter(visibleFilter);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [filterDropdownRef]);

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (
                moreActionsDropdownRef.current &&
                !moreActionsDropdownRef.current.contains(event.target as Node)
            ) {
                setMoreActionsOpen(0);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [moreActionsDropdownRef]);

    function handleActionsButton(id: number) {
        if (id === moreActionsOpen) {
            setMoreActionsOpen(0);
        } else {
            setMoreActionsOpen(id);
        }
    }

    const filteredSteps: DriveStep[] | undefined = [];
    if (isElementPresent('pendingFeeTab')) {
        filteredSteps.push({
            element: '#pendingFeeTab',
            popover: {
                title: 'Pending Fee Tab',
                description: 'Shows the data related to pending fee',
                side: 'bottom',
                align: 'start',
            },
        });
    }
    if (isElementPresent('receivedFeeTab')) {
        filteredSteps.push({
            element: '#receivedFeeTab',
            popover: {
                title: 'Received Fee Tab',
                description: 'Shows the data related to received fee',
                side: 'bottom',
                align: 'start',
            },
        });
    }
    if (isElementPresent('reminderBtn')) {
        filteredSteps.push({
            element: '#reminderBtn',
            popover: {
                title: 'Send Reminder',
                description: 'Send reminder for fee payment manually',
                side: 'bottom',
                align: 'start',
            },
        });
    }

    const driverObj = driver({
        popoverClass: 'driverjs-theme',
        doneBtnText: 'Finish Tutorial',
        showProgress: true,
        showButtons: ['next', 'previous'],
        prevBtnText: 'Skip Tour',
        steps: filteredSteps,
        onPrevClick: (element, step, opts) => {
            if (step?.popover?.nextBtnText == 'Finish Tutorial') {
                driverObj.destroy();
                const prevTutorialState = userState.walkthroughFlag
                    ? userState.walkthroughFlag.split(',')
                    : [];
                prevTutorialState?.push(FEE_PAYMENT_PAGE_WALKTHROUGH);
                const newTutorialFlag = prevTutorialState?.join(',');
                usedispatch(setWalkthroughFlags(newTutorialFlag));
            }
            if (
                !driverObj.hasNextStep() ||
                confirm('Are you sure you want to exit the tutorial?')
            ) {
                driverObj.destroy();
            }
        },
        onDestroyStarted: (element, step, opts) => {
            if (step?.popover?.nextBtnText == 'Finish Tutorial') {
                driverObj.destroy();
                const prevTutorialState = userState.walkthroughFlag
                    ? userState.walkthroughFlag.split(',')
                    : [];
                prevTutorialState?.push(FEE_PAYMENT_PAGE_WALKTHROUGH);
                const newTutorialFlag = prevTutorialState?.join(',');
                usedispatch(setWalkthroughFlags(newTutorialFlag));
            }
            if (
                !driverObj.hasNextStep() ||
                confirm('Are you sure you want to exit the tutorial?')
            ) {
                driverObj.destroy();
            }
        },
    });
    const numberOfDays = differenceInDays(new Date(userState.user?.createdAt ?? ''), new Date());
    if (
        numberOfDays < 30 &&
        !(userState.walkthroughFlag === 'FETCHING') &&
        !userState.walkthroughFlag.split(',').includes(FEE_PAYMENT_PAGE_WALKTHROUGH)
    ) {
        setTimeout(() => {
            driverObj.drive();
        }, 2000);
    }

    useEffect(() => {
        console.log('useeffect');

        if (isSuccess) {
            const academies: AcademyData[] = [];
            const disciplines: number[] = [];
            data?.data?.data?.forEach(value => {
                if (state.academies.length == 0) {
                    if (
                        !academies?.find(
                            academy => academy?.id === value.attributes.academy.data?.id
                        )
                    ) {
                        academies.push(value.attributes.academy.data);
                    }
                    dispatch({
                        type: 'setAcademies',
                        payload: academies,
                    });
                }

                // if (state.disciplines.length == 0) {
                //     if (!disciplines.find(discipline => discipline === value?.attributes?.class?.data?.attributes?.class_discipline?.id)){
                //         disciplines.push(
                //             value?.attributes?.class?.data?.attributes?.class_discipline?.id,
                //         );
                //     }
                //     dispatch({
                //         type: 'setDisciplines',
                //         payload: disciplines,
                //     });
                // }
            });
            dispatch({
                type: 'setReceivedFee',
                payload:
                    data.data?.data
                        ?.map(item => {
                            const sortedFeeDates = [...item.attributes.fee_dates].sort(
                                (a, b) =>
                                    new Date(b.dueDate).getTime() - new Date(a.dueDate).getTime()
                            );
                            return {
                                ...item,
                                attributes: {
                                    ...item.attributes,
                                    fee_dates: sortedFeeDates,
                                },
                            };
                        })
                        .filter(value => !isFeesDue(value.attributes))
                        .filter(value => value?.attributes?.archived === false || null) ?? [],
            });
            dispatch({
                type: 'setPendingFee',
                payload:
                    data.data?.data
                        ?.map(item => {
                            const sortedFeeDates = [...item.attributes.fee_dates].sort(
                                (a, b) =>
                                    new Date(b.dueDate).getTime() - new Date(a.dueDate).getTime()
                            );
                            return {
                                ...item,
                                attributes: {
                                    ...item.attributes,
                                    fee_dates: sortedFeeDates,
                                },
                            };
                        })
                        .filter(value => isFeesDue(value.attributes))
                        .filter(value => value?.attributes?.archived === false || null) ?? [],
            });
        }
    }, [isSuccess, data]);

    const setOpenTab = useCallback(
        (openTab: number) => {
            dispatch({
                type: 'setOpenTab',
                payload: openTab,
            });
        },
        [state.openTab]
    );

    const isFeesDue = (attributes: FeeAttribute) => {
        const currentDate = new Date();
        const dueDate = new Date(attributes?.fee_dates[0]?.dueDate);
        if (dueDate < currentDate) {
            return true;
        } else {
            return false;
        }
    };

    const onCollectFeeClicked = (data: FeePaymentData) => {
        setIsFeeCollectionModalOpen(true);
        setActiveFeeCollection(data.id);
        setActiveCollectFeeStudent(data);

        const startDate = data?.attributes?.fee_dates[0]?.dueDate
            ? formatDateToInputDate(
                  new Date(new Date(data.attributes.fee_dates[0].dueDate).getTime() + 86400000)
              )
            : '';
        const endDate = formatDateToInputDate(
            addFrequencyToDate(
                new Date(data?.attributes?.fee_dates[0]?.dueDate),
                data?.attributes?.payment_schedule
            )
        );

        setFeePaymentFormData({
            payment_schedule: data?.attributes?.payment_schedule,
            sessions: data?.attributes?.sessions,
            amount: data?.attributes?.amount,
            balance: data?.attributes?.balance,
            academy: data?.attributes?.academy?.data?.id,
            student: data?.attributes?.student?.data?.id,
            class: data?.attributes?.class?.data?.id,
            feeStartDate: startDate,
            feeEndDate: endDate,
            accReceivedIn: data?.attributes?.fee_dates[0]?.accReceivedIn,
            feePaymentDate: formatDateToInputDate(new Date()),
            feePaymentAmount: '',
            feePaymentMode: '',
            feePaymentMessage: '',
            owner: userState.user?.id ?? 0,
        });
    };

    const postNewFeeCollection = async (
        data: FeePaymentPostData,
        id: number
    ): Promise<FeePaymentPostDataWrapper | undefined> => {
        const response = await FeePaymentService.instance.updateFeeCollection(data, id);
        if (response.success) {
            return response?.data;
        } else {
            // console.log(`On Add Class Error ${JSON.stringify(response.error)}`);
            throw new Error(response.error?.message);
        }
    };

    const {
        mutate: editFeeCollection,
        isLoading: isFeeCollectionUpdating,
        isSuccess: isFeeCollectionUpdated,
    } = useMutation(
        ({ feePaymentPostData, id }: { feePaymentPostData: FeePaymentPostData; id: number }) =>
            postNewFeeCollection(feePaymentPostData, id),
        {
            onSuccess: data => {
                alert('Fee Collection Updated Successfully!');
                setIsFeeCollectionModalOpen(false);
                handleFeeItemModalCloseClick();
                setFeePaymentFormData(initialFeePaymentFormData);
                queryClient.invalidateQueries(FEE_PAYMENT_QUERY);
            },
            onError: error => {
                // console.log(`On fee collection Error:`, error);
                alert('Failed to create fee collection! Please try again');
            },
        }
    );

    useEffect(() => {
        setFeePaymentFormData(prev => ({
            ...prev,
            feeEndDate: formatDateToInputDate(
                addFrequencyToDate(new Date(prev.feeStartDate), prev.payment_schedule)
            ),
        }));
    }, [feePaymentFormData.payment_schedule]);

    const handleFeeFormInputChange = (field: string, value: string | number) => {
        setFeePaymentFormData(prevFormData => ({
            ...prevFormData,
            [field]: value,
        }));
        // if (field === 'feePaymentAmount') {
        //     const rupees = number2text(Number(value));
        //     console.log(rupees);
        //     setFeesRecievedInWords(rupees);
        // }
        if (field === 'balance') {
            const rupees = number2text(Number(value));
            console.log(rupees);
            setBalanceFeesInWords(rupees);
        }
    };

    useEffect(() => {
        if (feePaymentFormData.amount) {
            setFeesInWords(number2text(Number(feePaymentFormData.amount)));
        }
        if (feePaymentFormData.feePaymentAmount) {
            setFeesRecievedInWords(number2text(Number(feePaymentFormData.feePaymentAmount)));
        }
        if (feePaymentFormData.balance) {
            setFeesRecievedInWords(number2text(Number(feePaymentFormData.balance)));
        }
    }, [feePaymentFormData.amount, feePaymentFormData.feePaymentAmount,feePaymentFormData.balance]);

    const handleFeeCollectSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        const requestBody = {
            payment_schedule: feePaymentFormData.payment_schedule,
            sessions: feePaymentFormData.sessions ?? 0,
            amount: feePaymentFormData.amount ?? 0,
            balance: feePaymentFormData.balance ?? 0,
            academy: feePaymentFormData.academy ?? 0,
            student: feePaymentFormData.student ?? 0,
            class: feePaymentFormData.class ?? 0,
            fee_dates: [
                {
                    fee_date: new Date(feePaymentFormData.feePaymentDate),
                    amount: parseInt(feePaymentFormData.feePaymentAmount) ?? 0,
                    payment_mode: feePaymentFormData.feePaymentMode,
                    message: feePaymentFormData.feePaymentMessage,
                    dueDate: new Date(feePaymentFormData.feeEndDate),
                    startDate: new Date(feePaymentFormData.feeStartDate),
                    ...(editingFeeDetailsFormData.modeOfPayment !== 'cash'
                        ? { accReceivedIn: feePaymentFormData.accReceivedIn }
                        : {}),
                },
                ...(activeCollectFeeStudent?.attributes?.fee_dates ?? []),
            ],
            users: [
                ...(activeCollectFeeStudent?.attributes?.users?.data?.map(item => item.id) || []),
                userState.user?.id ?? 0,
            ],
            notify: true,
        };

        editFeeCollection({
            feePaymentPostData: requestBody,
            id: activeFeeCollection ?? 0,
        });
    };

    const [successEmailsDelivered, setSuccessEmailsDelivered] = useState<string[] | []>([]);
    const [successMesssagesDelivered, setSuccessMessagesDelivered] = useState<string[] | []>([]);

    const [failedEmailsDelivered, setFailedEmailsDelivered] = useState<string[] | []>([]);
    const [failedMesssagesDelivered, setFailedMessagesDelivered] = useState<string[] | []>([]);

    const postEmailReminder = async (
        data: FeeReminderEmailAttributes
    ): Promise<FeeReminderEmailAttributes | string> => {
        const response = await FeePaymentService.instance.postFeeReminderEmail(data);
        if (response.success && response.data) {
            setSuccessEmailsDelivered(prev => [...prev, response.data ?? '']);
            return response.data;
        } else {
            setFailedEmailsDelivered(prev => [...prev, data.studentName]);
            throw new Error(response.error?.message);
        }
    };

    const {
        mutate: sendEmailReminder,
        isLoading: isSendingEmailReminder,
        error: isEmailReminderError,
    } = useMutation(postEmailReminder, {
        onSuccess: data => {
            // alert(data);
            queryClient.invalidateQueries(FEE_PAYMENT_QUERY);
        },
        onError: error => {
            // alert('Failed to send Email. please try again!');
            console.error(`On Email Reminder Error:`, error);
            throw error;
        },
    });

    const postMessageReminder = async (
        data: FeeReminderWhatsappMessageData
    ): Promise<FeeReminderWhatsappMessageData | string> => {
        const response = await FeePaymentService.instance.postFeeReminderWhatsappMessage(data);
        if (response.success && response.data) {
            setSuccessMessagesDelivered(prev => [...prev, response.data ?? '']);
            return response.data;
        } else {
            setFailedMessagesDelivered(prev => [...prev, data.studentName]);
            throw new Error(response.error?.message);
        }
    };

    const {
        mutate: sendWhatsappReminder,
        isLoading: isSendingWhatsappReminder,
        error: isWhatsappReminderError,
    } = useMutation(postMessageReminder, {
        onSuccess: data => {
            // alert(data);
        },
        onError: error => {
            // alert('Failed to send whatsapp message. please try again!');
            console.error(`On whatsapp reminder Error:`, error);
            throw error;
        },
    });

    const fetchStudentRelationData = async (studentId: number) => {
        const response = await StudentService.instance.getStudentRelationInfo(studentId);
        return response.data;
    };

    const [sentRemindersPromtModal, setSentRemindersPromtModal] = useState<boolean>(false);

    useEffect(() => {
        const sendReminders = async () => {
            if (state.openTab === 1) {
                try {
                    const emailPromises = checkedPendingFeeListItems
                        ?.filter(value =>
                            value?.attributes?.student?.data?.attributes?.classes?.data
                                ?.map(item => item.id)
                                .includes(value?.attributes?.class?.data?.id)
                        )
                        ?.map(async feeItem => {
                            const studentRelationData = await fetchStudentRelationData(
                                feeItem.attributes.student.data.id
                            );

                            const emailData: FeeReminderEmailAttributes = {
                                id: feeItem?.id,
                                to: feeItem?.attributes?.student?.data?.attributes?.parentEmailId,
                                studentName:
                                    feeItem?.attributes?.student?.data?.attributes?.firstName,
                                pendingAmount: feeItem?.attributes?.balance || 0,
                                dueDate: getFormattedDate(
                                    new Date(feeItem?.attributes?.fee_dates[0].dueDate)
                                ),
                                className: feeItem?.attributes?.class?.data?.attributes?.class_name,
                                academyName: feeItem?.attributes?.academy?.data?.attributes?.name,
                                parentName:
                                    studentRelationData?.data?.attributes?.studentRelation[0]
                                        ?.name ?? 'Sir/Madam',
                                totalFees: feeItem?.attributes?.amount || 0,
                                isDue: true,
                                academyEmail:
                                    feeItem?.attributes?.academy?.data?.attributes?.email ?? '',
                                user: userState?.user?.id ?? 0,
                                academyPhone:
                                    feeItem?.attributes?.academy?.data?.attributes?.contactNo,
                            };

                            const messageData: FeeReminderWhatsappMessageData = {
                                academyName: feeItem?.attributes?.academy?.data?.attributes?.name,
                                parentName:
                                    studentRelationData?.data?.attributes?.studentRelation[0]
                                        ?.name ?? 'Sir/Madam',
                                className: feeItem?.attributes?.class?.data?.attributes?.class_name,
                                schedule: feeItem?.attributes?.payment_schedule,
                                studentName:
                                    feeItem?.attributes?.student?.data?.attributes?.firstName,
                                dueDate: getFormattedDate(
                                    new Date(feeItem?.attributes?.fee_dates[0].dueDate)
                                ),
                                amount: feeItem?.attributes?.amount || 0,
                                contactNumber:
                                    feeItem?.attributes?.student?.data?.attributes?.parentContactNo,
                                isDue: true,
                                user: userState?.user?.id ?? 0,
                                academyEmail:
                                    feeItem?.attributes?.academy?.data?.attributes?.email ?? '',
                                academyPhone:
                                    feeItem?.attributes?.academy?.data?.attributes?.contactNo,
                            };

                            await sendWhatsappReminder(messageData);
                            await sendEmailReminder(emailData);
                        });

                    await Promise.all(emailPromises);
                    setIsSendReminderClicked(false);
                    // alert("Reminders sent successfully!");
                } catch (error) {
                    console.error('Error sending one or more reminders:', error);
                    // alert("Failed to send one or more reminders! Please try again");
                    setIsSendReminderClicked(false);
                }
            } else if (state.openTab === 2) {
                try {
                    const emailPromises = checkedReceivedFeeListItems
                        ?.filter(value =>
                            value?.attributes?.student?.data?.attributes?.classes?.data
                                ?.map(item => item.id)
                                .includes(value?.attributes?.class?.data?.id)
                        )
                        ?.map(async feeItem => {
                            const studentRelationData = await fetchStudentRelationData(
                                feeItem.attributes.student.data.id
                            );

                            const emailData: FeeReminderEmailAttributes = {
                                id: feeItem?.id,
                                to: feeItem?.attributes?.student?.data?.attributes?.parentEmailId,
                                studentName:
                                    feeItem?.attributes?.student?.data?.attributes?.firstName,
                                pendingAmount: feeItem?.attributes?.balance || 0,
                                dueDate: getFormattedDate(
                                    new Date(feeItem?.attributes?.fee_dates[0].dueDate)
                                ),
                                className: feeItem?.attributes?.class?.data?.attributes?.class_name,
                                academyName: feeItem?.attributes?.academy?.data?.attributes?.name,
                                parentName:
                                    studentRelationData?.data?.attributes?.studentRelation[0]
                                        ?.name ?? 'Sir/Madam',
                                totalFees: feeItem?.attributes?.amount || 0,
                                isDue: false,
                                academyEmail: feeItem?.attributes?.academy?.data?.attributes?.email,
                                user: userState?.user?.id ?? 0,
                                academyPhone:
                                    feeItem?.attributes?.academy?.data?.attributes?.contactNo,
                            };

                            const messageData: FeeReminderWhatsappMessageData = {
                                academyName: feeItem?.attributes?.academy?.data?.attributes?.name,
                                parentName:
                                    studentRelationData?.data?.attributes?.studentRelation[0]
                                        ?.name ?? 'Sir/Madam',
                                schedule: feeItem?.attributes?.payment_schedule,
                                studentName:
                                    feeItem?.attributes?.student?.data?.attributes?.firstName,
                                dueDate: getFormattedDate(
                                    new Date(feeItem?.attributes?.fee_dates[0].dueDate)
                                ),
                                className: feeItem?.attributes?.class?.data?.attributes?.class_name,
                                amount: feeItem?.attributes?.amount || 0,
                                contactNumber:
                                    feeItem?.attributes?.student?.data?.attributes?.parentContactNo,
                                isDue: false,
                                user: userState?.user?.id ?? 0,
                                academyEmail:
                                    feeItem?.attributes?.academy?.data?.attributes?.email ?? '',
                                academyPhone:
                                    feeItem?.attributes?.academy?.data?.attributes?.contactNo,
                            };

                            await sendWhatsappReminder(messageData);
                            await sendEmailReminder(emailData);
                        });

                    await Promise.all(emailPromises);
                    setIsSendReminderClicked(false);
                    // alert("Reminders sent successfully!");
                } catch (error) {
                    console.error('Error sending one or more reminders:', error);
                    // alert("Failed to send one or more reminders! Please try again");
                    setIsSendReminderClicked(false);
                }
            }
        };

        if (isSendReminderClicked) {
            if (state.openTab === 1) {
                if (checkedPendingFeeListItems.length > 0) {
                    setSentRemindersPromtModal(true);
                    sendReminders();
                } else {
                    alert('Please select students to send fee reminders!');
                    setIsSendReminderClicked(false);
                }
            } else if (state.openTab === 2) {
                if (checkedReceivedFeeListItems.length > 0) {
                    setSentRemindersPromtModal(true);
                    sendReminders();
                } else {
                    alert('Please select students to send fee reminders!');
                    setIsSendReminderClicked(false);
                }
            }
        }
    }, [isSendReminderClicked]);

    function handleSendEmailReminder() {
        setIsSendReminderClicked(true);
    }

    const handleSentRemindersPromptModalClose = () => {
        setSentRemindersPromtModal(false);
        setSuccessEmailsDelivered([]);
        setSuccessMessagesDelivered([]);
    };

    const handleFeeListItemCheck = (value: FeePaymentData) => {
        if (state.openTab === 1) {
            setCheckedPendingFeeListItems(prevItems => {
                const isAlreadyChecked = prevItems.some(item => item.id === value.id);

                if (isAlreadyChecked) {
                    return prevItems?.filter(item => item.id !== value.id);
                } else {
                    return [...prevItems, value];
                }
            });
        } else if (state.openTab === 2) {
            setCheckedReceivedFeeListItems(prevItems => {
                const isAlreadyChecked = prevItems.some(item => item.id === value.id);

                if (isAlreadyChecked) {
                    return prevItems?.filter(item => item.id !== value.id);
                } else {
                    return [...prevItems, value];
                }
            });
        }
    };
    const openFilterInModileResponsive = () => {
        setOpenFilter(true);
    };
    const handleAllPendingListSelect = (e: ChangeEvent<HTMLInputElement>) => {
        setAllPendingListItemsSelected(e.target.checked);

        setCheckedPendingFeeListItems(prevItems => {
            console.log(state.pendingFee);

            return e.target.checked ? [...state.pendingFee] : [];
        });
        console.log(checkedPendingFeeListItems);
    };

    const handleAllReceivedListSelect = (e: ChangeEvent<HTMLInputElement>) => {
        setAllReceivedListItemsSelected(e.target.checked);

        setCheckedReceivedFeeListItems(prevItems => {
            return e.target.checked ? [...state.receivedFee] : [];
        });
    };

    const handleFeeCollectionModalClose = () => {
        setIsFeeCollectionModalOpen(false);
    };

    const handleOpenFilter = () => {
        setOpenFilter(false);
    };

    const toggleVisibleFilter = (filter: number) => {
        if (filter === visibleFilter) {
            setVisibleFilter(0);
        } else {
            setVisibleFilter(filter);
        }
    };

    const handleViewDetailsClick = (id: number) => {
        setFeeItemModelVisible(id);
    };

    const handleFeeItemModalCloseClick = () => {
        setFeeItemModelVisible(0);
        setIsFeeDetailsModalEditing(false);
        setEditingFeeDetailsFormData(initialEditingFeeDetailsFormData);
    };

    const handleAcademyFilterSelect = (id: number) => {
        setSelectedAcademiesForFilter(prev => {
            const index = prev.indexOf(id);
            if (index !== -1) {
                return prev?.filter(item => item !== id);
            } else {
                return [...prev, id];
            }
        });
    };

    const handleDisciplineFilterSelect = (discipline: number) => {
        setSelectedDisciplinesForFilter(prev => {
            const index = prev.indexOf(discipline);
            if (index !== -1) {
                return prev.filter(item => item !== discipline);
            } else {
                return [...prev, discipline];
            }
        });
    };

    const handleFeeScheduleFilterSelected = (schedule: string) => {
        setSelectedFeeScheduleForFilter(prev => {
            const index = prev.indexOf(schedule);
            if (index !== -1) {
                return prev.filter(item => item !== schedule);
            } else {
                return [...prev, schedule];
            }
        });
    };

    const handleBeforeEndDateRangeFilterSelected = (days: string) => {
        setSelectedBeforeEndDateRangeForFilter(days);
    };

    const handleBeforeEndDateFilterSelected = (date: string) => {
        setSelectedBeforeEndDateForFilter(date);
    };

    const handleReceivedSortDropBtnClick = () => {
        setIsReceivedSortOptionsVisible(prev => !prev);
    };

    const handlePendingSortDropBtnClick = () => {
        setIsPendingSortOptionsVisible(prev => !prev);
    };

    const handlePendingSortOptionChange = (option: number) => {
        setSelectedPendingSortOption(option);
    };

    const handleReceivedSortOptionChange = (option: number) => {
        setSelectedReceivedSortOption(option);
    };

    const sortedPendingFee = [...state.pendingFee].sort((a, b) => {
        const dateA = new Date(a?.attributes?.fee_dates[0]?.dueDate).getTime();
        const dateB = new Date(b?.attributes?.fee_dates[0]?.dueDate).getTime();
        const firstNameA = a?.attributes?.student?.data?.attributes?.firstName;
        const firstNameB = b?.attributes?.student?.data?.attributes?.firstName;

        const overdueA = differenceInDays(
            new Date(),
            new Date(a?.attributes?.fee_dates[0]?.dueDate)
        );
        const overdueB = differenceInDays(
            new Date(),
            new Date(b?.attributes?.fee_dates[0]?.dueDate)
        );

        const nameA = firstNameA.toLowerCase();
        const nameB = firstNameB.toLowerCase();

        if (selectedPendingSortOption === 1) {
            return dateB - dateA; // Sort from newest to oldest
        } else if (selectedPendingSortOption === 2) {
            return dateA - dateB; // Sort from oldest to newest
        } else if (selectedPendingSortOption === 3) {
            // Sort by name
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        } else if (selectedPendingSortOption === 4) {
            //sort by overdue date
            return overdueA - overdueB;
        }
        return 0;
    });

    const filteredPendingFee = sortedPendingFee
        .filter(item => {
            if (selectedAcademiesForFilter.length > 0) {
                return selectedAcademiesForFilter.includes(item?.attributes?.academy?.data?.id);
            } else {
                return true;
            }
        })
        .filter(item => {
            if (selectedDisciplinesForFilter.length > 0) {
                return selectedDisciplinesForFilter.includes(
                    item?.attributes?.class?.data?.attributes?.class_discipline?.data?.id
                );
            } else {
                return true;
            }
        })
        .filter(item => {
            if (selectedFeeScheduleForFilter.length > 0) {
                return selectedFeeScheduleForFilter.includes(item?.attributes?.payment_schedule);
            } else {
                return true;
            }
        });

    const sortedReceivedFee = [...state.receivedFee].sort((a, b) => {
        const paymentDateA = new Date(a?.attributes?.fee_dates[0]?.fee_date).getTime();
        const payementDateB = new Date(b?.attributes?.fee_dates[0]?.fee_date).getTime();
        const endDateA = new Date(a?.attributes?.fee_dates[0]?.dueDate).getTime();
        const endDateB = new Date(b?.attributes?.fee_dates[0]?.dueDate).getTime();
        const firstNameA = a?.attributes?.student?.data?.attributes?.firstName;
        const firstNameB = b?.attributes?.student?.data?.attributes?.firstName;

        const nameA = firstNameA.toLowerCase();
        const nameB = firstNameB.toLowerCase();

        const remainingA = differenceInDays(
            new Date(),
            new Date(a?.attributes?.fee_dates[0]?.dueDate)
        );
        const remainingB = differenceInDays(
            new Date(),
            new Date(b?.attributes?.fee_dates[0]?.dueDate)
        );

        if (selectedReceivedSortOption === 1) {
            return payementDateB - paymentDateA; // Sort from newest to oldest
        } else if (selectedReceivedSortOption === 2) {
            return paymentDateA - payementDateB; // Sort from oldest to newest
        } else if (selectedReceivedSortOption === 3) {
            return endDateA - endDateB;
        } else if (selectedReceivedSortOption === 4) {
            // Sort by name
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        } else if (selectedReceivedSortOption === 5) {
            return remainingA - remainingB;
        }
        return 0;
    });

    const filteredReceivedFee = sortedReceivedFee
        .filter(item => {
            if (selectedAcademiesForFilter.length > 0) {
                return selectedAcademiesForFilter.includes(item?.attributes?.academy?.data?.id);
            } else {
                return true;
            }
        })
        .filter(item => {
            if (selectedDisciplinesForFilter.length > 0) {
                return selectedDisciplinesForFilter.includes(
                    item?.attributes?.class?.data?.attributes?.class_discipline?.data?.id
                );
            } else {
                return true;
            }
        })
        .filter(item => {
            if (selectedFeeScheduleForFilter.length > 0) {
                return selectedFeeScheduleForFilter.includes(item?.attributes?.payment_schedule);
            } else {
                return true;
            }
        })
        .filter(item => {
            if (selectedBeforeEndDateRangeForFilter) {
                const currentDate = new Date();
                switch (selectedBeforeEndDateRangeForFilter) {
                    case '7':
                        return (
                            new Date(item?.attributes?.fee_dates[0].dueDate) <=
                            new Date(currentDate.getTime() + 7 * 24 * 60 * 60 * 1000)
                        );
                    case '30':
                        return (
                            new Date(item?.attributes?.fee_dates[0].dueDate) <=
                            new Date(currentDate.getTime() + 30 * 24 * 60 * 60 * 1000)
                        );
                    case '45':
                        return (
                            new Date(item?.attributes?.fee_dates[0].dueDate) <=
                            new Date(currentDate.getTime() + 45 * 24 * 60 * 60 * 1000)
                        );
                    default:
                        return false;
                }
            } else {
                return true;
            }
        })
        .filter(item => {
            if (selectedBeforeEndDateForFilter) {
                return (
                    new Date(item?.attributes?.fee_dates[0]?.dueDate) <
                    new Date(selectedBeforeEndDateForFilter)
                );
            } else {
                return true;
            }
        });

    const initialEditingFeeDetailsFormData = {
        id: 0,
        received: '',
        balance: '',
        previousSchedule: '',
        sessions: '',
        startDate: '',
        endDate: '',
        paymentDate: '',
        modeOfPayment: '',
        accReceivedIn: '',
        message: '',
        sendEmail: false,
    };

    const [editingFeeDetailsFormData, setEditingFeeDetailsFormData] =
        useState<EditingFeeDetailsFormDataType>(initialEditingFeeDetailsFormData);

    const handleFeeDetailsEditBtnClick = (data: FeePaymentData) => {
        setEditingFeeDetailsFormData({
            id: data?.id,
            received: data?.attributes?.fee_dates[0]?.amount?.toString(),
            balance: data?.attributes?.balance?.toString(),
            previousSchedule: data?.attributes?.payment_schedule,
            sessions: data?.attributes?.sessions?.toString(),
            startDate: new Date(data?.attributes?.fee_dates[0]?.startDate)?.toString(),
            endDate: new Date(data?.attributes?.fee_dates[0]?.dueDate)?.toString(),
            paymentDate: new Date(data?.attributes?.fee_dates[0]?.fee_date)?.toString(),
            modeOfPayment: data?.attributes?.fee_dates[0]?.payment_mode,
            accReceivedIn: data?.attributes?.fee_dates[0]?.accReceivedIn,
            message: data?.attributes?.fee_dates[0]?.message,
            sendEmail: false,
        });
        setIsFeeDetailsModalEditing(true);
    };

    const handleFeeDetailFormInputChange = (field: string, value: string | boolean) => {
        setEditingFeeDetailsFormData(prevFormData => ({
            ...prevFormData,
            [field]: value,
        }));
    };

    const handleFeeDetailsUpdate = (data: FeePaymentData) => {
        const updateData = {
            payment_schedule: editingFeeDetailsFormData.previousSchedule,
            sessions: parseInt(editingFeeDetailsFormData.sessions),
            balance: parseInt(editingFeeDetailsFormData.balance),
            dueDate: new Date(editingFeeDetailsFormData.endDate),
            fee_dates: [
                {
                    fee_date: new Date(editingFeeDetailsFormData.paymentDate),
                    startDate: new Date(editingFeeDetailsFormData.startDate),
                    dueDate: new Date(editingFeeDetailsFormData.endDate),
                    amount: parseInt(editingFeeDetailsFormData.received),
                    payment_mode: editingFeeDetailsFormData.modeOfPayment,
                    message: editingFeeDetailsFormData.message,
                    ...(feePaymentFormData.feePaymentMode !== 'cash'
                        ? { accReceivedIn: feePaymentFormData.accReceivedIn }
                        : {}),
                    feeRemindersSent:
                        (data &&
                            data.attributes &&
                            data.attributes.fee_dates &&
                            data.attributes.fee_dates[0] &&
                            data.attributes.fee_dates[0].feeRemindersSent) ||
                        [],
                },
                ...(data && data.attributes && data.attributes.fee_dates
                    ? data.attributes.fee_dates.filter((item, index) => index !== 0)
                    : []),
            ],
            notify: editingFeeDetailsFormData.sendEmail,
        };
        editFeeCollection({ feePaymentPostData: updateData, id: data?.id ?? 0 });
    };

    const postFeeCollectionAsArchived = async (
        data: FeePaymentPostData,
        id: number
    ): Promise<FeePaymentPostDataWrapper | undefined> => {
        const response = await FeePaymentService.instance.updateFeeCollection(data, id);
        if (response.success) {
            return response?.data;
        } else {
            // console.log(`On Add Class Error ${JSON.stringify(response.error)}`);
            throw new Error(response.error?.message);
        }
    };

    const {
        mutate: archiveFeeCollection,
        isLoading: isFeeCollectionarchieving,
        isSuccess: isFeeCollectionArchieved,
    } = useMutation(
        ({ feePaymentPostData, id }: { feePaymentPostData: FeePaymentPostData; id: number }) =>
            postFeeCollectionAsArchived(feePaymentPostData, id),
        {
            onSuccess: data => {
                alert('Archived Fee Collection Successfully!');
                queryClient.invalidateQueries(FEE_PAYMENT_QUERY);
            },
            onError: error => {
                // console.log(`On fee collection Error:`, error);
                alert('Failed to archieve fee collection! Please try again');
            },
        }
    );

    const handleArchieveFeeCollection = (id: number) => {
        const result = window.confirm(
            `Are you sure that you want to archieve? \nit will be removed from active fee lists!`
        );
        if (result) {
            const data = {
                archived: true,
            };
            archiveFeeCollection({ feePaymentPostData: data, id });
        } else {
            return;
        }
    };

    const totalDue = filteredPendingFee
        .map(fee => (fee?.attributes?.balance ?? 0) + (fee?.attributes?.amount ?? 0))
        ?.reduce((accumulator, currentValue) => accumulator + currentValue, 0);

    const [isChangeClassModalOpen, setIsChangeClassModalOpen] = useState<boolean>(false);
    const [changeClassInputValue, setChangeClassInputValue] = useState<number>(0);

    const handleChangeClassFormOpen = (item: FeePaymentData) => {
        setActiveCollectFeeStudent(item);
        setChangeClassInputValue(item?.attributes?.class?.data?.id);
        setIsChangeClassModalOpen(true);
    };

    const handleChangeClassModalClose = () => {
        setIsChangeClassModalOpen(false);
    };

    const handleClassChangeFormInputChange = (id: number) => {
        setChangeClassInputValue(id);
    };

    const changeStudentClass = async (
        data: StudentPostDataWrapper
    ): Promise<StudentData | undefined> => {
        const response = await StudentService.instance.updateStudent(
            activeCollectFeeStudent?.attributes?.student?.data?.id || 0,
            data
        );
        if (response.success) {
            return response.data;
        } else {
            // console.log(
            //     `On class change Error ${JSON.stringify(response.error)}`,
            // );
            throw new Error(response.error?.message);
        }
    };

    const {
        mutate: updateStudentClass,
        isLoading: isStudentClassUpdating,
        isSuccess: isStudentClassUpdated,
    } = useMutation(changeStudentClass, {
        onSuccess: data => {
            alert(`Updated student's class Successfully!`);
            setIsChangeClassModalOpen(false);
            setChangeClassInputValue(0);
            queryClient.invalidateQueries(FEE_PAYMENT_QUERY);
        },
        onError: error => {
            // console.log(`On class change Error:`, error);
            alert(`Failed to update the student's class! Please try again`);
        },
    });

    const handleClassChange = () => {
        const data = {
            classes: activeCollectFeeStudent?.attributes?.student?.data?.attributes?.classes?.data
                ?.map(item => item.id)
                ?.map(classItem =>
                    classItem === activeCollectFeeStudent?.attributes?.class?.data?.id
                        ? changeClassInputValue
                        : classItem
                ),
        };
        updateStudentClass({ data });
    };

    const [isRecordLeaveModalOpen, setIsRecordLeaveModalOpen] = useState<boolean>(false);
    const [isRecordPostSuccess, setIsRecordPostSuccess] = useState(false);
    const [formValues, setFormValues] = useState({
        leaveFrom: '',
        leaveTo: '',
        message: '',
    });
    const [recordLeaveModalStudentData, setRecordLeaveModalStudentData] = useState<StudentData>();

    const handleRecordLeaveModalClose = () => {
        setIsRecordLeaveModalOpen(false);
        setIsRecordPostSuccess(false);
    };

    const handleRecordLeaveFormInputChange = (fieldName: string, value: string) => {
        setFormValues(prevValues => ({
            ...prevValues,
            [fieldName]: value,
        }));
    };

    const openRecordLeaveFormModal = (student: StudentData) => {
        setIsRecordLeaveModalOpen(true);
        setRecordLeaveModalStudentData(student);
        setFormValues({
            leaveFrom: '',
            leaveTo: '',
            message: '',
        });
    };

    const generateAttendanceRecords = () => {
        const startDate = new Date(formValues.leaveFrom);
        const endDate = new Date(formValues.leaveTo);
        const attendanceRecords: AttendanceRequest[] = [];

        // Iterate over each class in the classes array
        recordLeaveModalStudentData?.attributes?.classes?.data.forEach(classData => {
            // Iterate over dates from leaveFrom to leaveTo
            for (
                let date = new Date(startDate);
                date <= endDate;
                date.setDate(date.getDate() + 1)
            ) {
                const formattedDate = date.toISOString().split('T')[0]; // Format date as "YYYY-MM-DD"
                const attendanceRecord = {
                    present: false,
                    on_leave: true,
                    note: formValues.message,
                    attendance_date: formattedDate,
                    class: classData?.id || 0,
                    student: recordLeaveModalStudentData?.id || 0,
                    time: new Date().toLocaleTimeString(),
                    markedBy: userState?.user?.id ?? 0,
                    systemMarked: false,
                };
                attendanceRecords.push(attendanceRecord);
            }
        });

        return attendanceRecords;
    };

    const postLeaveRecord = async (
        data: AttendanceRequest
    ): Promise<AttendanceListWrapper | undefined> => {
        const response = await StudentService.instance.postLeaveRecord(data);
        if (response.success) {
            return response.data;
        } else {
            throw new Error(response.error?.message);
        }
    };

    const {
        mutate: addLeaveRecord,
        isLoading: isLeaveRecordPosting,
        // error: isLeaveRecordPostError,
        isSuccess: isLeaveRecordPosted,
    } = useMutation(postLeaveRecord, {
        onSuccess: data => {
            queryClient.invalidateQueries(SCHEDULE_DETAILS_QUERY);
            setIsRecordPostSuccess(true);
        },
        onError: error => {
            // console.log(`On Attendance Error:`, error);
        },
    });

    const handleRecordLeavesFormSubmit = () => {
        const attendanceRecords = generateAttendanceRecords();
        attendanceRecords?.map(data => {
            if (data.class !== undefined && data.student !== undefined) {
                addLeaveRecord(data);
            }
        });
    };

    const navigate = useNavigate();
    const goToStudentDetails = useCallback(
        (studentId: number) => {
            navigate(Routes.Students + '/' + studentId);
        },
        [navigate]
    );

    const [isDropStudentFormOpen, setIsDropStudentFormOpen] = useState(false);
    const [studentActiveAcademies, setStudentActiveAcademies] = useState<AcademyData[]>();
    const [selectedAcademiesIdToDropStudentFrom, setSelectedAcademiesIdToDropStudentFrom] =
        useState<number[]>([]);
    const [checkedAcademiesToDropStudent, setCheckedAcademiesToDropStudent] = useState<number[]>(
        []
    );
    const [selectedStudentData, setSelectedStudentData] = useState<StudentData | undefined>();

    const handleDropStudentModalClose = () => {
        setIsDropStudentFormOpen(false);
    };

    const { isSuccess: isAcademiesFetchSuccess, data: academiesData } = useQuery({
        queryKey: [ACADEMY_QUERY],
        queryFn: AcademiesService.instance.getAcademyList.bind(this, userState?.user?.id ?? 0),
        refetchOnWindowFocus: false,
        enabled: (userState?.user?.id ?? 0) > 0,
    });

    const {
        isSuccess: isClassFetchSuccess,
        isLoading,
        data: classes,
    } = useQuery({
        queryKey: [SCHEDULE_QUERY, userState?.user?.id],
        queryFn: ClassService.instance.getClasses.bind(this, userState?.user?.id ?? 0),
        refetchOnWindowFocus: false,
        enabled: (userState?.user?.id ?? 0) > 0,
    });

    const dropActiveStudent = async (
        id: number,
        data: StudentPostData
    ): Promise<ApiResponse<StudentPostDataWrapper> | undefined> => {
        const response = await StudentService.instance.dropStudent(id, data);
        if (response.success) {
            return response?.data;
        } else {
            // console.log(
            //     `On Drop Student Error ${JSON.stringify(response.error)}`,
            // );
            throw new Error(response.error?.message);
        }
    };

    const {
        mutate: dropSelectedStudent,
        isLoading: isStudentDropping,
        isSuccess: isStudentDropped,
    } = useMutation<
        ApiResponse<StudentPostDataWrapper> | undefined,
        Error,
        { id: number; data: StudentPostData }
    >(({ id, data }) => dropActiveStudent(id, data), {
        onSuccess: data => {
            alert(`Student was dropped successfully!`);
            setIsDropStudentFormOpen(false);
            queryClient.invalidateQueries(FEE_PAYMENT_QUERY);
        },
        onError: error => {
            // console.log('Mutation Error:', error);
            alert(`There was an error while dropping student. Please try again!`);
        },
    });

    const handleStudentDropAction = () => {
        if (checkedAcademiesToDropStudent.length <= 0) {
            alert('Please select at least one academy to proceed dropping the student!');
        } else if (selectedStudentData) {
            const allAcadamiesId = academiesData?.data?.data?.map(item => item.id);
            const studentsAcademies = studentActiveAcademies?.map(item => item.id);
            const studentAcademiesAfterDropout = studentsAcademies?.filter(
                academyId => !checkedAcademiesToDropStudent.includes(academyId)
            );
            const allAvailableStudentsClassAfterDropout = classes?.data?.data
                ?.filter(item => {
                    const academyId = item?.attributes?.academies?.data?.id;
                    return (
                        academyId !== undefined && studentAcademiesAfterDropout?.includes(academyId)
                    );
                })
                ?.map(item => item.id);
            const studentsClassesBeforeDropout = selectedStudentData?.attributes?.classes.data?.map(
                item => item.id
            );
            const studentClassesAfterDropout = studentsClassesBeforeDropout?.filter(id =>
                allAvailableStudentsClassAfterDropout?.includes(id)
            );

            const result = window.confirm(
                `Are you sure that you want to drop ${selectedStudentData?.attributes?.firstName}? \n${selectedStudentData?.attributes?.firstName} will be removed from Active students list if you proceed!`
            );
            if (result) {
                const data: studentAcademyHistoryPostData[] = checkedAcademiesToDropStudent?.map(
                    item => {
                        return {
                            supplier: userState.user?.id || 0,
                            academy: item,
                            status: 'Inactive',
                            statusDate: new Date(),
                            enrolmentDate: new Date(),
                        };
                    }
                );

                dropSelectedStudent({
                    id: selectedStudentData.id,
                    data: {
                        studentAcademyHistory: [
                            ...(selectedStudentData?.attributes?.studentAcademyHistory || []),
                            ...data,
                        ],
                        classes: studentClassesAfterDropout,
                        academies: studentAcademiesAfterDropout,
                    },
                });
            } else {
                return;
            }
        }
        return;
    };

    const handleDropStudentAcademyCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
        const academyId = parseInt(e.target.value, 10);

        setCheckedAcademiesToDropStudent(prevCheckedAcademies => {
            if (prevCheckedAcademies.includes(academyId)) {
                // Academy is already in the list, so remove it
                return prevCheckedAcademies.filter(id => id !== academyId);
            } else {
                // Academy is not in the list, so add it
                return [...prevCheckedAcademies, academyId];
            }
        });
    };

    const { isSuccess: isAllAcademiesFetchSuccess, data: allAcademiesOfSupplier } = useQuery({
        queryKey: [ACADEMY_QUERY],
        queryFn: AcademiesService.instance.getAcademyList.bind(this, userState?.user?.id ?? 0),
        refetchOnWindowFocus: false,
        enabled: (userState?.user?.id ?? 0) > 0,
    });

    const openStudentDropoutConfirmation = (student: StudentData | undefined) => {
        setSelectedStudentData(student);
        const allAcademiesId = allAcademiesOfSupplier?.data?.data?.map(item => item.id);
        const acadamiesActiveIn = student?.attributes?.academies?.data?.filter(item =>
            allAcademiesId?.includes(item.id)
        );

        if (acadamiesActiveIn && acadamiesActiveIn.length > 0) {
            setStudentActiveAcademies(acadamiesActiveIn);
            setIsDropStudentFormOpen(true);
        } else {
            alert(
                `${student?.attributes?.firstName} is not an active student in any of the academies!`
            );
        }
    };

    //Student Search Bar
    const handleFeeStudentSearchInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        setFeeStudentSearchTerm(event?.target?.value);
    };

    const handleFeeStudentSearchInputClear = () => {
        setFeeStudentSearchTerm('');
    };

    return {
        isSuccess,
        state,
        setOpenTab,
        totalDue,
        feeStudentSearchTerm,
        handleFeeStudentSearchInputClear,
        handleFeeStudentSearchInputChange,
        feeSchedules,
        onCollectFeeClicked,
        handleFeeCollectionModalClose,
        isFeeCollectionModalOpen,
        handleSendEmailReminder,
        handleFeeListItemCheck,
        allReceivedListItemsSelected,
        allPendingListItemsSelected,
        handleAllPendingListSelect,
        handleAllReceivedListSelect,
        setOpenFilter,
        openFilter,
        dropDown,
        setDropDown,
        dispatch,
        itemsPerPage,
        indexOfLastItem,
        indexOfFirstItem,
        currentPage: state.currentPage,
        filteredPendingFee,
        pendingFee: filteredPendingFee?.slice(indexOfFirstItem, indexOfLastItem),
        pendingFeeTotal: filteredPendingFee?.length ?? 0,
        filteredReceivedFee,
        receivedFee: filteredReceivedFee?.slice(indexOfFirstItem, indexOfLastItem),
        receivedFeeTotal: filteredReceivedFee?.length ?? 0,
        openFilterInModileResponsive,
        handleOpenFilter,
        handleFeeCollectSubmit,
        activeCollectFeeStudent,
        allClasses: allClassesData?.data?.data,
        handleFeeFormInputChange,
        feePaymentFormData,
        visibleFilter,
        toggleVisibleFilter,
        successEmailsDelivered,
        successMesssagesDelivered,
        sentRemindersPromtModal,
        handleSentRemindersPromptModalClose,
        checkedPendingFeeListItems,
        checkedReceivedFeeListItems,
        failedEmailsDelivered,
        failedMesssagesDelivered,
        FeeItemModelVisible,
        handleViewDetailsClick,
        handleAcademyFilterSelect,
        handleDisciplineFilterSelect,
        handleFeeScheduleFilterSelected,
        handleBeforeEndDateFilterSelected,
        handleBeforeEndDateRangeFilterSelected,
        selectedBeforeEndDateForFilter,
        selectedAcademiesForFilter,
        selectedDisciplinesForFilter,
        selectedFeeScheduleForFilter,
        selectedBeforeEndDateRangeForFilter,
        isReceivedSortOptionsVisible,
        isPendingSortOptionsVisible,
        selectedReceivedSortOption,
        selectedPendingSortOption,
        handleReceivedSortDropBtnClick,
        handlePendingSortDropBtnClick,
        handlePendingSortOptionChange,
        handleReceivedSortOptionChange,
        leaveRecordData: leaveRecordData?.data?.data,
        handleActionsButton,
        moreActionsOpen,
        pendingSortDropdownRef,
        receivedSortDropDownRef,
        filterDropdownRef,
        moreActionsDropdownRef,
        handleFeeItemModalCloseClick,
        handleFeeDetailsEditBtnClick,
        isFeeDetailsModalEditing,
        editingFeeDetailsFormData,
        handleFeeDetailFormInputChange,
        handleFeeDetailsUpdate,
        handleArchieveFeeCollection,
        handleChangeClassFormOpen,
        isChangeClassModalOpen,
        handleChangeClassModalClose,
        changeClassInputValue,
        handleClassChangeFormInputChange,
        handleClassChange,
        isRecordLeaveModalOpen,
        handleRecordLeaveModalClose,
        handleRecordLeaveFormInputChange,
        handleRecordLeavesFormSubmit,
        formValues,
        recordLeaveModalStudentData,
        isRecordPostSuccess,
        openRecordLeaveFormModal,
        goToStudentDetails,
        handleDropStudentModalClose,
        isDropStudentFormOpen,
        studentActiveAcademies,
        selectedAcademiesIdToDropStudentFrom,
        handleStudentDropAction,
        handleDropStudentAcademyCheck,
        checkedAcademiesToDropStudent,
        openStudentDropoutConfirmation,
        isFeeCollectionUpdating,
        isFeeCollectionarchieving,
        isStudentClassUpdating,
        isLeaveRecordPosting,
        isStudentDropping,
        userState,
        feesInWords,
        feesRecievedInWords,
        balanceFeesInWords,
        allDisciplines:
            disciplinesList?.data?.data.filter(d =>
                allAcademiesOfSupplier?.data?.data
                    .flatMap(a => a?.attributes?.selectedDisciplines?.data?.map(d => d.id))
                    .includes(d.id)
            ) ?? [],
    };
};

export default FeePaymentViewModel;
