import { useSelector } from 'react-redux';
import { RootState } from '../../../utils/redux/store';
import { FormEvent, useState } from 'react';
import { CertificationService } from '../../../services/certification-service';
import { StudentCertificateRecordPostData } from '../../../models/certifications/student-certificate-record-post-data';
import { StudentCertificateRecordDataWrapper } from '../../../models/certifications/student-certificate-record-data-wrapper';
import { useMutation, useQueryClient } from 'react-query';
import { CERTIFICATION_STUDENT_RECORDS_LIST } from '../../../utils/constants/constants';
import { CertificationData } from '../../../models/certifications/certification-data';
import { StudentCertificateRecordData } from '../../../models/certifications/student-certificate-record-data';


const CertificatePromotionViewModel = ({
    handleClose,
    selectedStudents,
    selectedCertificationData,
    certificationsList,
    certificationStudentRecordsList,
}: {
    handleClose: () => void;
    selectedStudents: number[];
    selectedCertificationData: CertificationData;
    certificationsList: CertificationData[];
    certificationStudentRecordsList: StudentCertificateRecordData[];
}) => {
    const userState = useSelector((state: RootState) => state.user);
    const queryClient = useQueryClient();

    const [selectedLevelToPromote, setSelectedLevelToPromote] = useState<number>(0);
    
    const handleModalClose = () => {
        handleClose();
    }

    const handleFormSubmit = (e: FormEvent) => {
        e.preventDefault();
    }

    const postStudentCertificateRecord = async (
        data: StudentCertificateRecordPostData
    ): Promise<StudentCertificateRecordDataWrapper | undefined> => {
        const response = await CertificationService.instance.postNewStudentCertificationRecord(data);
        if (response.success) {
            return response.data;
        } else {
            throw new Error(response.error?.message);
        }
    };

    const {
        mutate: addStudentCertificateRecord,
        isLoading: isStudentCertificateRecordAdding,
    } = useMutation(postStudentCertificateRecord, {
        onSuccess: (data) => {
            queryClient.invalidateQueries(CERTIFICATION_STUDENT_RECORDS_LIST);
        },
        onError: (error) => {
            queryClient.invalidateQueries(CERTIFICATION_STUDENT_RECORDS_LIST);
        },
    });

    const handleAddStudentCertificateRecord = async () => {
        const addRecordPromises = certificationStudentRecordsList
            .filter(r => selectedStudents.includes(r?.attributes?.student?.data?.id))
            .map(async (record) => {
                return addStudentCertificateRecord({
                    student: record?.attributes?.student?.data?.id,
                    certificationInfo: certificationsList.find(c => c?.attributes?.level === selectedLevelToPromote)?.id ?? 0,
                    previousLevel: certificationStudentRecordsList?.find(r => r.attributes?.student?.data?.id === record?.attributes?.student?.data?.id)?.id ?? 0,
                });
            });
    
        await Promise.all(addRecordPromises);
    
        handleModalClose();
    };

    return {
        isLoading: isStudentCertificateRecordAdding,
        handleModalClose,
        handleFormSubmit,
        selectedLevelToPromote,
        setSelectedLevelToPromote,
        handleAddStudentCertificateRecord,
    };
};

export default CertificatePromotionViewModel;
