import { ChangeEvent, useCallback, useEffect, useReducer, useRef, useState } from 'react';
import { StudentsState, studentsReducer } from '../reducers/students-reducer';
import { useSelector } from 'react-redux';
import { RootState } from '../../utils/redux/store';
import {
    ACADEMY_QUERY,
    ALL_APARTMENTS_QUERY,
    ALL_SCHOOLS_QUERY,
    FEE_PAYMENT_QUERY,
    PARENT_LIST_QUERY,
    SCHEDULE_DETAILS_QUERY,
    SCHEDULE_QUERY,
    STUDENT_DETAILS_QUERY,
    STUDENT_ENROLLMENT_FORM_QUERY,
    STUDENT_ENROLMENT_DATA_QUERY,
    STUDENT_QUERY,
} from '../../utils/constants/constants';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { StudentService } from '../../services/student-service';
import { useNavigate } from 'react-router-dom';
import { Routes } from '../navigation/routes';
import { StudentData } from '../../models/student/student-data';
import AttendanceRequest from '../../utils/types/attendance-request';
import { AttendanceListWrapper } from '../../models/attendance/attendance-list-wrapper';
import { StudentPostDataWrapper } from '../../models/student/student-post-data-wrapper';
import ApiResponse from '../../utils/types/api-response';
import { studentAcademyHistory } from '../../models/student/student-activity';
import { StudentPostData } from '../../models/student/student-post-data';
import { AcademyData } from '../../models/academy/academy-data';
import { studentAcademyHistoryPostData } from '../../models/student/student-activity-post-data';
import { useDebounce } from 'use-debounce';
import {
    convertToCSV,
    csvToJson,
    formatDateToInputDate,
    generateUniqueSlug,
    getEnrollmentQuery,
    getStudentQuery,
    isValidDate,
    verifyBulkStudentCsvRow,
} from '../../utils/helpers/helpers';
import { ClassData } from '../../models/class/class-data';
import { UploadService } from '../../services/upload-service';
import { AcademiesService } from '../../services/academies-service';
import ClassService from '../../services/class-service';
import { FeePaymentPostData } from '../../models/fee/fee-payment-post-data';
import { FeePaymentPostDataWrapper } from '../../models/fee/fee-payment-post-data-wrapper';
import { FeePaymentService } from '../../services/fee-payment-service';
import { DashboardService } from '../../services/dashboard-service';
import { FeePaymentFormDataType, addFrequencyToDate } from './fee-payment-viewmodel';
import { Roles } from '../../utils/auth/roles';
import StudentDetails from '../pages/student-details';
import StudentDetailsViewModel from './student-details-view-model';
import { FeePaymentData } from '../../models/fee/fee-payment-data';
import { FeeDate } from '../../models/fee/fee-date';
import Academies from '../pages/academies';
import { StudentDetailsWrapper } from '../../models/student/student-details-wrapper';
import BulkStudentAttendanceDataType from '../../utils/types/bulk-student-attendance-data-type';
import { BulkStudentsPostData } from '../../models/student/bulk-student-post-data';
import { EnrollmentDataListWrapper } from '../../models/enrollment-data/enrollment-data-list-wrapper';
import { StudentEnrollmentService } from '../../services/student-enrollment-service';
import { EnrolmentPostDataWrapper } from '../../models/enrollment-data/enrolment-post-data-wrapper';
import { useImmerReducer } from 'use-immer';
import { EnrollmentData } from '../../models/enrollment-data/enrollment-data';
import { ParentService } from '../../services/parent-service';

export interface StudentFormDataType {
    firstName: string;
    lastName: string;
    middleName: string;
    gender: string;
    DateOfBirth: string;
    BloodGroup: string;
    isPhysicallyHandicapped: string | null;
    academies: number[];
    skillLevel: string;
    class: number[];
    studentAcademyHistory?: studentAcademyHistory[];
    parentNumber: string;
    parentEmail: string;
    photo: string;
    city: string;
    state: string;
    currentAddress: string;
    apartmentName: string;
    relationOneRelationship: string;
    relationOneFullName: string;
    relationOneContact: string;
    relationOneEmail: string;
    relationOneGender: string;
    relationOneProfession: string;
    relationTwoRelationship: string;
    relationTwoFullName: string;
    relationTwoContact: string;
    relationTwoEmail: string;
    relationTwoGender: string;
    relationTwoProfession: string;
}

export const studentFormDataInitialState = {
    firstName: '',
    lastName: '',
    middleName: '',
    gender: '',
    DateOfBirth: '',
    BloodGroup: '',
    isPhysicallyHandicapped: null,
    academies: [],
    skillLevel: '',
    class: [],
    studentAcademyHistory: [],
    parentNumber: '',
    photo: '',
    parentEmail: '',
    city: '',
    state: '',
    currentAddress: '',
    apartmentName: '',
    relationOneRelationship: '',
    relationOneFullName: '',
    relationOneContact: '',
    relationOneEmail: '',
    relationOneGender: '',
    relationOneProfession: '',
    relationTwoRelationship: '',
    relationTwoFullName: '',
    relationTwoContact: '',
    relationTwoEmail: '',
    relationTwoGender: '',
    relationTwoProfession: '',
};
export interface EnrolmentCard {
    email?: string;
    academyId: number[];
    classId: number[];
    isExisting: boolean;
    amount?: number;
    phoneNumber?: string;
}

const StudentsViewModel = () => {
    const initialState: StudentsState = {
        openTab: 1,
        selectedStudentIndex: -1,
        currentPage: 1,
        queryString: '',
        enrollmentQueryString: '',
        academies: [],
        students: [],
        selfEnrolment: [],
    };

    const queryClient = useQueryClient();
    const [formValues, setFormValues] = useState({
        leaveFrom: '',
        leaveTo: '',
        message: '',
    });
    const initialEnrolmentState: EnrolmentCard = {
        email: '',
        academyId: [],
        classId: [],
        isExisting: false,
        amount: 0,
        phoneNumber: '',
    };

    const [activeTab, setActiveTab] = useState(1);
    const [formData, setFormData] = useState<StudentFormDataType>(studentFormDataInitialState);

    const [isDropStudentFormOpen, setIsDropStudentFormOpen] = useState(false);
    const [isfilterModalOpen, setIsfilterModalOpen] = useState(false);
    const [selectedStudentData, setSelectedStudentData] = useState<StudentData | undefined>();
    const [studentActiveAcademies, setStudentActiveAcademies] = useState<AcademyData[]>();
    const [isRecordLeaveModalOpen, setIsRecordLeaveModalOpen] = useState(false);
    const [isNewStudentModalOpen, setIsNewStudentModalOpen] = useState(false);
    const [isEnrolmentOrNewStudentModalOpen, setIsEnrolmentOrNewStudentModalOpen] = useState(false);
    const [isNewEnrolmentFormDetailsOpen, setIsNewEnrolmentFormDetailsOpen] = useState(false);
    const [enrolmentData, setEnrolmentData] = useState<EnrolmentCard[]>([]);
    const [currentEnrolmentData, setCurrentEnrolmentData] =
        useState<EnrolmentCard>(initialEnrolmentState);
    const [isRecordPostSuccess, setIsRecordPostSuccess] = useState(false);
    const [editingStudentData, setEditingStudentData] = useState<StudentData>();
    const [isEditStudentModalOpen, setIsEditStudentModalOpen] = useState<boolean>(false);
    const [recordLeaveModalStudentData, setRecordLeaveModalStudentData] = useState<StudentData>();
    const userState = useSelector((state: RootState) => state.user);
    const [state, dispatch] = useImmerReducer(studentsReducer, initialState);
    const itemsPerPage = 10;
    const indexOfLastItem = state.currentPage * itemsPerPage;
    const [confirmedStudentClassId, setConfirmedStudentClassId] = useState<number>();
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const navigate = useNavigate();
    const [imageid, setImageId] = useState<number>();
    const [profileImageInputKey, setProfileImageInputKey] = useState<number>(0);

    const [searchTerm, setSearchTerm] = useState('');
    const [value] = useDebounce(searchTerm, 1000);
    const [isSearchInputOnFocus, setIsSearchInputOnFocus] = useState(false);

    const [selectedAcademiesForFilter, setSelectedAcademiesForFilter] = useState<number[]>([]);
    const [selectedClassesForFilter, setSelectedClassesForFilter] = useState<number[]>([]);
    const [selectedDobFilterType, setSelectedDobFilterType] = useState<string>('after');
    const [selectedDobFilterDate, setSelectedDobFilterDate] = useState<string>('');
    const [selectedApartmentForFilter, setSelectedApartmentForFilter] = useState<string[]>([]);
    const [selectedAgesForFilter, setSelectedAgesForFilter] = useState<string[]>([]);
    const [statusForFilter, setStatusForFilter] = useState<number>(-1);
    const [selectedSchoolForFilter, setSelectedSchoolForFilter] = useState<string[]>([]);
    const [selectedGradeForFilter, setSelectedGradeForFilter] = useState<string[]>([]);
    const [selectedBoardForFilter, setSelectedBoardForFilter] = useState<string[]>([]);

    const [selectedEnrollmentStatusForFilter, setSelectedEnrollmentStatusForFilter] = useState<
        string[]
    >(['FORM_SUBMITTED']);
    const [isSortOptionsVisible, setIsSortOptionsVisible] = useState<boolean>(false);
    const [selectedSortOption, setSelectedSortOption] = useState<number>(1);

    const setCurrentPage = useCallback(
        (currentPage: number) => {
            dispatch({
                type: 'setCurrentPage',
                payload: currentPage,
            });
        },
        [state.openTab]
    );

    const setOpenTab = useCallback(
        (openTab: number) => {
            setSearchTerm('');
            setCurrentPage(1);
            dispatch({
                type: 'setOpenTab',
                payload: openTab,
            });
        },
        [state.openTab]
    );

    const { isSuccess: isAcademiesFetchSuccess, data: academiesData } = useQuery({
        queryKey: [ACADEMY_QUERY],
        queryFn: AcademiesService.instance.getAcademyList.bind(this, userState?.user?.id ?? 0),
        refetchOnWindowFocus: false,
        enabled: (userState?.user?.id ?? 0) > 0,
    });

    const {
        isSuccess: isClassFetchSuccess,
        isLoading: isClassFetching,
        data: classesData,
    } = useQuery({
        queryKey: [SCHEDULE_QUERY, userState?.user?.id],
        queryFn: ClassService.instance.getClasses.bind(this, userState?.user?.id ?? 0),
        refetchOnWindowFocus: false,
        enabled: (userState?.user?.id ?? 0) > 0,
    });

    const { isSuccess: isAllAcademiesFetchSuccess, data: allAcademiesOfSupplier } = useQuery({
        queryKey: [ACADEMY_QUERY],
        queryFn: AcademiesService.instance.getAcademyList.bind(this, userState?.user?.id ?? 0),
        refetchOnWindowFocus: false,
        enabled: (userState?.user?.id ?? 0) > 0,
    });

    const {
        isSuccess: isStudentsFetchSuccess,
        isLoading,
        data: studentsData,
    } = useQuery({
        queryKey: [STUDENT_QUERY, state.queryString, value, state.currentPage],
        queryFn: StudentService.instance.getStudentList.bind(
            this,
            userState?.user?.id ?? 0,
            state.queryString,
            value,
            state.currentPage
        ),
        refetchOnWindowFocus: false,
        enabled: state.openTab === 1 && (userState?.user?.id ?? 0) > 0,
    });

    const { isSuccess: isEnrolmentFormFetchSuccess, data: enrolmentFormData } = useQuery({
        queryKey: [
            STUDENT_ENROLMENT_DATA_QUERY,
            value,
            state.currentPage,
            state.enrollmentQueryString,
        ],
        queryFn: StudentEnrollmentService.instance.getEnrolmentFormList.bind(
            this,
            userState?.user?.id ?? 0,
            value,
            state.currentPage,
            state.enrollmentQueryString
        ),
        refetchOnWindowFocus: false,
        enabled: state.openTab === 2 && (userState?.user?.id ?? 0) > 0,
    });

    const {
        isSuccess: isStudentApartmentsFetchSuccess,
        isLoading: isStudentApartmentsLoading,
        data: studentApartmentsData,
    } = useQuery({
        queryKey: [ALL_APARTMENTS_QUERY],
        queryFn: StudentService.instance.getStudentApartmentsList.bind(
            this,
            userState?.user?.id ?? 0
        ),
        refetchOnWindowFocus: false,
        enabled: (userState?.user?.id ?? 0) > 0,
    });

    const {
        isSuccess: isStudentSchoolsFetchSuccess,
        isLoading: isStudentSchoolsLoading,
        data: studentSchoolsData,
    } = useQuery({
        queryKey: [ALL_SCHOOLS_QUERY],
        queryFn: StudentService.instance.getStudentSchoolsList.bind(this, userState?.user?.id ?? 0),
        refetchOnWindowFocus: false,
        enabled: (userState?.user?.id ?? 0) > 0,
    });

    const {
        isSuccess: isAllParentsFetchSuccess,
        isLoading: isAllParentsLoading,
        data: allParentProfiles,
    } = useQuery({
        queryKey: [PARENT_LIST_QUERY],
        queryFn: () => ParentService.instance.getAllParentProfiles(),
        refetchOnWindowFocus: false,
        enabled: (userState?.user?.id ?? 0) > 0,
    });

    const [expandInactive, setExpandInactive] = useState<number[]>([]);

    const handleExpandInactive = (index: number) => {
        if (expandInactive.includes(index)) {
            setExpandInactive(expandInactive?.filter(item => item !== index));
        } else {
            setExpandInactive([...expandInactive, index]);
        }
    };

    const [apartmentData, setApartmentData] = useState<string[]>([]);
    const [schoolData, setSchoolData] = useState<string[]>([]);

    useEffect(() => {
        setApartmentData([
            ...new Set(
                studentApartmentsData?.data?.data
                    ?.map(item => item.attributes.apartmentName)
                    ?.filter(Boolean)
            ),
        ]);
    }, [studentApartmentsData]);

    useEffect(() => {
        setSchoolData([
            ...new Set(
                studentSchoolsData?.data?.data?.map(item => item.attributes.school)?.filter(Boolean)
            ),
        ]);
    }, [studentSchoolsData]);

    const [isFeeCollectionModalOpen, setIsFeeCollectionModalOpen] = useState(false);

    const onCollectFeeClicked = (item: StudentData) => {
        setSelectedStudentData(item);
        setIsFeeCollectionModalOpen(true);
    };

    const {
        isSuccess: isStudentDataFetchedSuccess,
        isLoading: isStudentDataFetchLoading,
        data: AcademyData,
    } = useQuery({
        queryKey: [userState?.user?.id],
        queryFn: () => StudentService.instance.getStudentListDetailes(userState?.user?.id ?? 0),
        refetchOnWindowFocus: false,
        enabled: true,
    });

    const getUserId = () => {
        if (userState?.user?.role?.type === Roles.AUTHENTICATED_USER) {
            return userState?.user?.id ?? 0;
        } else if (userState?.user?.role?.type === Roles.RESTRICTED_USER) {
            return userState?.userDetails?.attributes?.users.data[0].id ?? 0;
        }
        return 0; // Default to 0 if no valid role is found
    };

    const { data: allClassesData } = useQuery({
        queryKey: [SCHEDULE_QUERY],
        queryFn: () => ClassService.instance.getClasses(getUserId()),
        refetchOnWindowFocus: false,
        enabled: getUserId() > 0,
    });

    useEffect(() => {
        const academisFilter =
            AcademyData?.data?.data
                ?.map((item, index) => item?.attributes?.academies?.data)
                .flat() || [];

        const filteredAcademis = [];
        const uniqueIds = new Set();

        for (let i = 0; i < academisFilter?.length; i++) {
            const currentId = academisFilter[i]?.id;
            const currentAttributes = academisFilter[i]?.attributes;

            // Use a combination of id and attributes to check for uniqueness
            const key = `${currentId}-${JSON.stringify(currentAttributes)}`;

            if (currentId && !uniqueIds.has(key)) {
                uniqueIds.add(key);

                // If you want to store both id and attributes in the result array
                filteredAcademis.push({
                    id: currentId,
                    attributes: currentAttributes,
                });
            }
        }

        // Dispatch the filtered academies to Redux
        dispatch({
            type: 'setAcademies',
            payload: filteredAcademis,
        });
    }, [dispatch, AcademyData]);

    useEffect(() => {
        return dispatch({
            type: 'setQueryString',
            payload: getStudentQuery(
                selectedAgesForFilter,
                selectedAcademiesForFilter,
                selectedClassesForFilter,
                { date: selectedDobFilterDate, type: selectedDobFilterType },
                selectedApartmentForFilter,
                statusForFilter,
                allAcademiesOfSupplier?.data?.data.map(id => id.id),
                selectedSchoolForFilter,
                selectedGradeForFilter,
                selectedBoardForFilter,
                selectedSortOption
            ),
        });
    }, [
        selectedAgesForFilter,
        selectedAcademiesForFilter,
        selectedClassesForFilter,
        selectedDobFilterType,
        selectedApartmentForFilter,
        statusForFilter,
        selectedSchoolForFilter,
        selectedGradeForFilter,
        selectedBoardForFilter,
        selectedSortOption,
    ]);

    useEffect(() => {
        dispatch({
            type: 'setEnrollmentQueryString',
            payload: getEnrollmentQuery(selectedEnrollmentStatusForFilter),
        });
    }, [selectedEnrollmentStatusForFilter]);

    const handleEnrollmentStatusFilterChange = (value: string) => {
        setSelectedEnrollmentStatusForFilter(prev => {
            const index = prev.indexOf(value);
            if (index !== -1) {
                return prev?.filter(item => item !== value);
            } else {
                return [...prev, value];
            }
        });
    };

    useEffect(() => {
        dispatch({
            type: 'setSelfEnrolment',
            payload: enrolmentFormData?.data?.data ?? [],
        });
    }, [enrolmentFormData]);

    const handleAcademyFilterChange = (id: number) => {
        setSelectedAcademiesForFilter(prev => {
            const index = prev.indexOf(id);
            if (index !== -1) {
                return prev?.filter(item => item !== id);
            } else {
                return [...prev, id];
            }
        });
    };

    //Function to handle class filters

    const handleClassFilterChange = (id: number) => {
        setSelectedClassesForFilter(prev => {
            const index = prev.indexOf(id);
            if (index !== -1) {
                return prev?.filter(item => item !== id);
            } else {
                return [...prev, id];
            }
        });
    };

    const handleBoardFilterChange = (board: string) => {
        setSelectedBoardForFilter(prev => {
            const index = prev.indexOf(board);
            if (index !== -1) {
                return prev?.filter(item => item !== board);
            } else {
                return [...prev, board];
            }
        });
    };

    const handleGradeFilterChange = (grade: string) => {
        setSelectedGradeForFilter(prev => {
            const index = prev.indexOf(grade);
            if (index !== -1) {
                return prev?.filter(item => item !== grade);
            } else {
                return [...prev, grade];
            }
        });
    };

    const studentFilterDropDownRef = useRef<HTMLDivElement>(null);
    const enrollmentFilterDropDownRef = useRef<HTMLDivElement>(null);
    const moreActionsDropDownRef = useRef<HTMLDivElement>(null);
    const sortDropdownRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (
                studentFilterDropDownRef.current &&
                !studentFilterDropDownRef.current.contains(event.target as Node)
            ) {
                toggleVisibleFilter(visibleFilter);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [studentFilterDropDownRef]);

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (
                enrollmentFilterDropDownRef.current &&
                !enrollmentFilterDropDownRef.current.contains(event.target as Node)
            ) {
                toggleVisibleFilter(visibleFilter);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [enrollmentFilterDropDownRef]);

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (
                moreActionsDropDownRef.current &&
                !moreActionsDropDownRef.current.contains(event.target as Node)
            ) {
                dispatch({
                    type: 'setSelectedStudent',
                    payload: state.selectedStudentIndex,
                });
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [moreActionsDropDownRef]);

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (
                sortDropdownRef.current &&
                !sortDropdownRef.current.contains(event.target as Node)
            ) {
                setIsSortOptionsVisible(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [sortDropdownRef]);

    const handleActionsButton = useCallback(
        (index: number) => {
            if (state.selectedStudentIndex === index) {
                dispatch({ type: 'deselectStudent' });
            } else {
                dispatch({ type: 'setSelectedStudent', payload: index });
            }
        },
        [state.selectedStudentIndex]
    );

    const handleSortDropBtnClick = (event: any) => {
        setIsSortOptionsVisible(prev => !prev);
    };

    const handleSortOptionChange = (option: number) => {
        setSelectedSortOption(option);
    };

    const goToStudentDetails = useCallback(
        (studentId: number) => {
            navigate(Routes.Students + '/' + studentId);
        },
        [navigate]
    );

    const goToFeeHistory = useCallback(
        (studentId: number) => {
            navigate(Routes.Students + '/' + studentId, { state: 3 });
        },
        [navigate]
    );

    const openRecordLeaveFormModal = (student: StudentData) => {
        setIsRecordLeaveModalOpen(true);
        setRecordLeaveModalStudentData(student);
        setFormValues({
            leaveFrom: '',
            leaveTo: '',
            message: '',
        });
    };

    const handleRecordLeaveFormInputChange = (fieldName: string, value: string) => {
        setFormValues(prevValues => ({
            ...prevValues,
            [fieldName]: value,
        }));
    };

    const postLeaveRecord = async (
        data: AttendanceRequest
    ): Promise<AttendanceListWrapper | undefined> => {
        const response = await StudentService.instance.postLeaveRecord(data);
        if (response.success) {
            return response.data;
        } else {
            // console.log(
            //     `On Post Attendacnce Error ${JSON.stringify(response.error)}`,
            // );
            throw new Error(response.error?.message);
        }
    };

    const {
        mutate: addLeaveRecord,
        isLoading: isLeaveRecordPosting,
        // error: isLeaveRecordPostError,
        isSuccess: isLeaveRecordPosted,
    } = useMutation(postLeaveRecord, {
        onSuccess: data => {
            queryClient.invalidateQueries(SCHEDULE_DETAILS_QUERY);
            setIsRecordPostSuccess(true);
        },
        onError: error => {
            // console.log(`On Attendance Error:`, error);
            // Error actions
        },
    });

    const handleRecordLeaveModalClose = () => {
        setIsRecordLeaveModalOpen(false);
        setIsRecordPostSuccess(false);
    };

    const generateAttendanceRecords = () => {
        const startDate = new Date(formValues.leaveFrom);
        const endDate = new Date(formValues.leaveTo);
        const attendanceRecords: AttendanceRequest[] = [];

        // Iterate over each class in the classes array
        recordLeaveModalStudentData?.attributes?.classes?.data.forEach(classData => {
            // Iterate over dates from leaveFrom to leaveTo
            for (
                let date = new Date(startDate);
                date <= endDate;
                date.setDate(date.getDate() + 1)
            ) {
                const formattedDate = date.toISOString().split('T')[0]; // Format date as "YYYY-MM-DD"
                const attendanceRecord = {
                    present: false,
                    on_leave: true,
                    note: formValues.message,
                    attendance_date: formattedDate,
                    class: classData?.id || 0,
                    student: recordLeaveModalStudentData?.id || 0,
                    time: new Date().toLocaleTimeString(),
                    markedBy: userState?.user?.id ?? 0,
                    systemMarked: false,
                };
                attendanceRecords.push(attendanceRecord);
            }
        });

        return attendanceRecords;
    };

    const handleRecordLeavesFormSubmit = () => {
        const attendanceRecords = generateAttendanceRecords();
        attendanceRecords?.map(data => {
            if (data.class !== undefined && data.student !== undefined) {
                addLeaveRecord(data);
            }
        });
    };

    const handleAddNewStudentBtnClick = () => {
        setIsEnrolmentOrNewStudentModalOpen(true);
    };

    const switchTab = (tabNumber: number, isGoingBack: boolean) => {
        if (isGoingBack) {
            setActiveTab(tabNumber);
        } else {
            if (validateCurrentTab()) {
                setActiveTab(tabNumber);
            }
        }
    };

    const validateCurrentTab = () => {
        switch (activeTab) {
            case 1:
                if (!formData.firstName || !formData.gender || !formData.DateOfBirth) {
                    alert('Please fill in all required fields.');
                    return false;
                } else if (formData.academies.length <= 0) {
                    alert('Please Choose an academy.');
                    return false;
                } else if (formData.class.length <= 0) {
                    alert('Please Choose a class.');
                    return false;
                }
                break;
            case 2:
                if (!formData.parentNumber || !formData.parentEmail || !formData.currentAddress) {
                    alert('Please fill in all required fields.');
                    return false;
                } else if (!isValidEmail(formData.parentEmail)) {
                    alert('Invalid email format. Please enter a valid parent email address.');
                    return false;
                }
                break;
            case 3:
                break;
            default:
                break;
        }
        return true;
    };

    const handleInputChange = (field: string, value: string | number[]) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            [field]: value,
        }));
    };

    const isValidEmail = (email: string): boolean => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const postNewStudent = async (
        data: StudentPostDataWrapper
    ): Promise<StudentDetailsWrapper | undefined> => {
        const response = await StudentService.instance.postNewStudent(data);
        if (response.success) {
            return response.data;
        } else {
            // console.log(
            //     `On Add Student Error ${JSON.stringify(response.error)}`,
            // );
            throw new Error(response.error?.message);
        }
    };

    const {
        mutate: addNewStudent,
        isLoading: isNewStudentAdding,
        // error: isLeaveRecordPostError,
        isSuccess: isNewStudentAdded,
    } = useMutation(postNewStudent, {
        onSuccess: data => {
            alert('Added a student Successfully!');
            setIsNewStudentModalOpen(false);
            setActiveTab(1);
            setFormData(studentFormDataInitialState);
            queryClient.invalidateQueries(STUDENT_QUERY);
        },
        onError: error => {
            // console.log(`On student add Error:`, error);
            alert('Failed to add student! Please try again');
            // Error actions
        },
    });

    const updateExistingStudent = async (
        data: StudentPostDataWrapper
    ): Promise<StudentData | undefined> => {
        const response = await StudentService.instance.updateStudent(
            editingStudentData?.id || 0,
            data
        );
        if (response.success) {
            return response.data;
        } else {
            // console.log(
            //     `On Update Student Error ${JSON.stringify(response.error)}`,
            // );
            throw new Error(response.error?.message);
        }
    };

    const {
        mutate: updateStudentDetails,
        isLoading: isStudentDetailsUpdating,
        // error: isLeaveRecordPostError,
        isSuccess: isStudentDetailsUpdated,
    } = useMutation(updateExistingStudent, {
        onSuccess: data => {
            alert('Updated student Successfully!');
            setIsEditStudentModalOpen(false);
            setFormData(studentFormDataInitialState);
            setActiveTab(1);
            queryClient.invalidateQueries(STUDENT_QUERY);
            queryClient.invalidateQueries(STUDENT_DETAILS_QUERY);
        },
        onError: error => {
            // console.log(`On student add Error:`, error);
            alert('Failed to update the student! Please try again');
        },
    });

    useEffect(() => {
        const academyIds = new Set(formData.academies);
        const filteredClassIds = formData.class
            ?.map(classId => classesData?.data?.data?.find(classItem => classItem?.id === classId))
            ?.filter(classItem => {
                const academyId = classItem?.attributes?.academies?.data?.id;
                return academyId !== undefined && academyIds.has(academyId);
            })
            ?.map(classItem => classItem?.id)
            ?.filter((id): id is number => id !== undefined);

        setFormData(prevData => ({
            ...prevData,
            class: filteredClassIds,
        }));
    }, [formData.academies, classesData?.data?.data]);

    const mapAcademiesToOptions = (): { value: number; label: string }[] | [] => {
        if (academiesData?.data?.data) {
            return academiesData?.data?.data
                ?.filter(
                    academy =>
                        academy?.attributes?.email !== null &&
                        academy?.attributes?.email !== '' &&
                        academy?.attributes?.contactNo !== null &&
                        academy?.attributes?.contactNo !== ''
                )
                .map(academy => {
                    const { id, attributes } = academy;
                    return {
                        value: id,
                        label: attributes.name,
                    };
                });
        }
        return [];
    };

    const mapClassesToOptions = (): { value: number; label: string }[] | [] => {
        if (classesData?.data?.data && formData.academies) {
            return classesData?.data?.data
                ?.filter((item: ClassData) =>
                    formData?.academies.includes(item?.attributes?.academies?.data?.id)
                )
                ?.map((item: ClassData) => ({
                    value: item?.id,
                    label: item?.attributes?.class_name,
                }));
        }
        return [];
    };

    const mapAllClassesToOptionsIrrespectiveOfAcademy = ():
        | { value: number; label: string }[]
        | [] => {
        if (classesData?.data?.data) {
            return classesData?.data?.data?.map((item: ClassData) => ({
                value: item.id,
                label: item?.attributes?.class_name,
            }));
        }
        return [];
    };

    const dropActiveStudent = async (
        id: number,
        data: StudentPostData
    ): Promise<ApiResponse<StudentPostDataWrapper> | undefined> => {
        const response = await StudentService.instance.dropStudent(id, data);
        if (response.success) {
            return response?.data;
        } else {
            // console.log(
            //     `On Drop Student Error ${JSON.stringify(response.error)}`,
            // );
            throw new Error(response.error?.message);
        }
    };

    const {
        mutate: dropSelectedStudent,
        isLoading: isStudentDropping,
        isSuccess: isStudentDropped,
    } = useMutation<
        ApiResponse<StudentPostDataWrapper> | undefined,
        Error,
        { id: number; data: StudentPostData }
    >(({ id, data }) => dropActiveStudent(id, data), {
        onSuccess: data => {
            alert(`Student was dropped successfully!`);
            setIsDropStudentFormOpen(false);
            queryClient.invalidateQueries(STUDENT_QUERY);
            queryClient.invalidateQueries(STUDENT_DETAILS_QUERY);
        },
        onError: error => {
            // console.log('Mutation Error:', error);
            alert(`There was an error while dropping student. Please try again!`);
        },
    });

    const openStudentDropoutConfirmation = (student: StudentData | undefined) => {
        setSelectedStudentData(student);

        const allAcademiesId = allAcademiesOfSupplier?.data?.data?.map(item => item.id);
        const acadamiesActiveIn = student?.attributes?.academies?.data?.filter(item =>
            allAcademiesId?.includes(item.id)
        );

        if (acadamiesActiveIn && acadamiesActiveIn.length > 0) {
            setStudentActiveAcademies(acadamiesActiveIn);
            setIsDropStudentFormOpen(true);
        } else {
            alert(
                `${student?.attributes?.firstName} is not an active student in any of the academies!`
            );
        }
    };

    const [checkedAcademiesToDropStudent, setCheckedAcademiesToDropStudent] = useState<number[]>(
        []
    );

    const handleDropStudentAcademyCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
        const academyId = parseInt(e.target.value, 10);

        setCheckedAcademiesToDropStudent(prevCheckedAcademies => {
            if (prevCheckedAcademies.includes(academyId)) {
                // Academy is already in the list, so remove it
                return prevCheckedAcademies?.filter(id => id !== academyId);
            } else {
                // Academy is not in the list, so add it
                return [...prevCheckedAcademies, academyId];
            }
        });
    };

    const PostStudentImage = async (data: FormData) => {
        try {
            const response = await UploadService.instance.uploadImage(data);

            if (response.success) {
                response.data &&
                    response?.data?.map(item => {
                        if (item.url) {
                            setFormData(prevFormData => ({
                                ...prevFormData,
                                photo: item.url,
                            }));
                        }
                        if (item.id) {
                            setImageId(item.id);
                        }
                    });
            } else {
                throw new Error(response.error?.message);
            }
        } catch (error) {
            console.error(`Error in postStudentProfileImageUpload: ${error}`);
            throw error;
        }
    };

    const {
        mutate: UploadStudentImage,
        isLoading: isProfileImagePosting,
        error: isAcademyImagePostingError,
    } = useMutation(PostStudentImage, {
        onSuccess: data => {
            console.log('image upload successfull!');
        },
        onError: error => {
            alert('Failed to upload! please try again.');
            // Error actions
        },
    });

    const handleProfileImgChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFiles = e.target.files;
        if (selectedFiles) {
            for (let i = 0; i < selectedFiles.length; i++) {
                const fileSizeInBytes = selectedFiles[i].size;
                const fileSizeInKB = fileSizeInBytes / 1024;

                if (fileSizeInKB > 10240) {
                    alert('Image size exceeds 10MB. Please choose a smaller file.');
                    return;
                }
            }

            const formData = new FormData();

            for (let i = 0; i < selectedFiles.length; i++) {
                formData.append(`files`, selectedFiles[i]);
            }

            const data = formData;
            UploadStudentImage(data);

            setProfileImageInputKey(prev => prev + 1);
        }
    };

    const handleStudentDropAction = () => {
        if (checkedAcademiesToDropStudent.length <= 0) {
            alert('Please select at least one academy to proceed dropping the student!');
        } else if (selectedStudentData) {
            const allAcadamiesId = academiesData?.data?.data?.map(item => item.id);
            const studentsAcademies = studentActiveAcademies?.map(item => item.id);
            const studentAcademiesAfterDropout = studentsAcademies?.filter(
                academyId => !checkedAcademiesToDropStudent.includes(academyId)
            );
            const allAvailableStudentsClassAfterDropout = classesData?.data?.data
                ?.filter(item => {
                    const academyId = item?.attributes?.academies?.data?.id;
                    return (
                        academyId !== undefined && studentAcademiesAfterDropout?.includes(academyId)
                    );
                })
                ?.map(item => item.id);
            const studentsClassesBeforeDropout = selectedStudentData?.attributes?.classes.data?.map(
                item => item?.id
            );
            const studentClassesAfterDropout = studentsClassesBeforeDropout?.filter(id =>
                allAvailableStudentsClassAfterDropout?.includes(id)
            );

            const result = window.confirm(
                `Are you sure that you want to drop ${selectedStudentData?.attributes?.firstName}? \n${selectedStudentData?.attributes?.firstName} will be removed from Active students list if you proceed!`
            );
            if (result) {
                const data: studentAcademyHistoryPostData[] = checkedAcademiesToDropStudent?.map(
                    item => {
                        return {
                            supplier: userState.user?.id || 0,
                            academy: item,
                            status: 'Inactive',
                            statusDate: new Date(),
                            enrolmentDate: new Date(),
                        };
                    }
                );

                dropSelectedStudent({
                    id: selectedStudentData.id,
                    data: {
                        studentAcademyHistory: [
                            ...(selectedStudentData?.attributes?.studentAcademyHistory || []),
                            ...data,
                        ],
                        classes: studentClassesAfterDropout,
                        academies: studentAcademiesAfterDropout,
                    },
                });
            } else {
                return;
            }
        }
        return;
    };

    const openStudentEditForm = (data: StudentData) => {
        setIsEditStudentModalOpen(true);
        setEditingStudentData(data);
    };

    const handleStudentDropout = () => {
        openStudentDropoutConfirmation(editingStudentData);
    };

    const handleEditStudentModalClose = () => {
        setIsEditStudentModalOpen(false);
    };

    const handleNewStudentModalClose = () => {
        setIsNewStudentModalOpen(false);
    };

    const handleFeeCollectionModalClose = () => {
        setFeePaymentFormData(initialFeePaymentFormData);
        setInactiveFeeData([]);
        setIsFeeCollectionModalOpen(false);
    };

    const handleDropStudentModalClose = () => {
        setIsDropStudentFormOpen(false);
    };

    const handlesearchInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event?.target?.value);
    };

    const onStudentSearchListItemClick = (name: string) => {
        setSearchTerm(name);
    };

    const handleAgeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;

        if (selectedAgesForFilter.includes(value)) {
            setSelectedAgesForFilter(selectedAgesForFilter?.filter(age => age !== value));
        } else {
            setSelectedAgesForFilter([...selectedAgesForFilter, value]);
        }
    };

    const handleStatusChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;

        setStatusForFilter(parseInt(value));
    };

    // Filter students based on selected filter option
    const filteredStudents = studentsData?.data?.data;

    const handleFilterModalClose = () => {
        setIsfilterModalOpen(false);
    };

    const handleFilterBtnClick = () => {
        setIsfilterModalOpen(true);
    };

    const [selectedStudentClassId, setSelectedStudentClassId] = useState<number>();

    const [inactiveFeeData, setInactiveFeeData] = useState<FeePaymentData[]>();

    const {
        isSuccess: isStudentsClassFeeDataFetchSuccess,
        isLoading: isStudentsClassFeeDataLoading,
        data: studentsClassFeeData,
        refetch: refetchStudentsClassFeeData,
    } = useQuery({
        queryKey: [FEE_PAYMENT_QUERY],
        queryFn: DashboardService.instance.getStudentsClassFeeData.bind(
            this,
            selectedStudentData?.id ?? 0
            // selectedStudentClassId ?? 0,
        ),
        refetchOnWindowFocus: false,
        enabled: (userState?.user?.id ?? 0) > 0,
    });

    useEffect(() => {
        if (studentsClassFeeData?.data?.data && studentsClassFeeData?.data?.data.length > 0) {
            const selectedStudentFeeData = studentsClassFeeData?.data?.data?.filter(
                fee => fee?.attributes?.class?.data?.id === confirmedStudentClassId
            );
            // console.log(selectedStudentFeeData);
            if (selectedStudentFeeData.length > 0) {
                setFeePaymentFormData({
                    payment_schedule: selectedStudentFeeData[0]?.attributes?.payment_schedule,
                    sessions: selectedStudentFeeData[0]?.attributes?.sessions,
                    amount: selectedStudentFeeData[0]?.attributes?.amount,
                    balance: selectedStudentFeeData[0]?.attributes?.balance,
                    academy: selectedStudentFeeData[0]?.attributes?.academy?.data?.id,
                    // feeStartDate: new Date(selectedStudentFeeData[0].attributes?.fee_dates[1]?.dueDate).toString(),
                    // feeEndDate: new Date(selectedStudentFeeData[0].attributes?.fee_dates[0]?.dueDate).toString(),
                    feeStartDate: selectedStudentFeeData[0]?.attributes?.fee_dates[0]?.dueDate
                        ? formatDateToInputDate(
                              new Date(
                                  new Date(
                                      selectedStudentFeeData[0]?.attributes?.fee_dates[0]?.dueDate
                                  ).getTime() + 86400000
                              )
                          )
                        : '',
                    feeEndDate: formatDateToInputDate(
                        addFrequencyToDate(
                            new Date(selectedStudentFeeData[0]?.attributes?.fee_dates[0]?.dueDate),
                            selectedStudentFeeData[0]?.attributes?.payment_schedule
                        )
                    ),
                    student: selectedStudentFeeData[0]?.attributes?.student?.data?.id,
                    class: selectedStudentFeeData[0]?.attributes?.class?.data?.id,
                    accReceivedIn:
                        selectedStudentFeeData[0]?.attributes?.fee_dates[0].accReceivedIn,
                    feePaymentDate: formatDateToInputDate(new Date()),
                    feePaymentAmount: '',
                    feePaymentMode: '',
                    feePaymentMessage: '',
                    owner: userState.user?.id ?? 0,
                });
            } else {
                const activeClassIds = studentsData?.data?.data
                    ?.filter(id => id?.id === selectedStudentData?.id)[0]
                    ?.attributes?.classes?.data.map(classItem => classItem?.id);
                const inactiveFeeData = studentsClassFeeData.data.data?.filter(
                    item => !activeClassIds?.includes(item?.attributes?.class?.data?.id)
                );

                inactiveFeeData.sort(
                    (a, b) =>
                        new Date(b?.attributes?.fee_dates[0]?.fee_date).getTime() -
                        new Date(a?.attributes?.fee_dates[0]?.fee_date).getTime()
                );

                setInactiveFeeData(inactiveFeeData);
            }
        }
    }, [studentsClassFeeData, confirmedStudentClassId]);
    // console.log(studentsClassFeeData);

    useEffect(() => {
        setFeePaymentFormData(initialFeePaymentFormData);
    }, [selectedStudentClassId]);

    useEffect(() => {
        if (selectedStudentData && selectedStudentData?.attributes?.classes?.data?.length === 1) {
            setSelectedStudentClassId(selectedStudentData?.attributes?.classes?.data[0]?.id);
        }
    }, [selectedStudentData]);

    const initialFeePaymentFormData = {
        payment_schedule: '',
        sessions: null,
        amount: null,
        balance: null,
        academy: null,
        student: null,
        class: null,
        feeStartDate: '',
        accReceivedIn: '',
        feeEndDate: '',
        feePaymentDate: formatDateToInputDate(new Date()),
        feePaymentAmount: '',
        feePaymentMode: '',
        feePaymentMessage: '',
        owner: null,
    };

    const [feePaymentFormData, setFeePaymentFormData] =
        useState<FeePaymentFormDataType>(initialFeePaymentFormData);

    const handleClassInputChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedStudentClassId(parseInt(e.target.value));
        setFeePaymentFormData(initialFeePaymentFormData);
    };

    useEffect(() => {
        setFeePaymentFormData(prev => ({
            ...prev,
            feeEndDate: formatDateToInputDate(
                addFrequencyToDate(new Date(prev.feeStartDate), prev.payment_schedule)
            ),
        }));
    }, [feePaymentFormData.payment_schedule]);

    const handleStudentFeeSearch = () => {
        if (selectedStudentClassId) {
            setConfirmedStudentClassId(selectedStudentClassId);
            setFeePaymentFormData(prevFormData => ({
                ...prevFormData,
                class: selectedStudentClassId,
            }));
            refetchStudentsClassFeeData();
        } else {
            alert('Please select class!');
        }
    };

    const handleStudentFeeReset = () => {
        setSelectedStudentClassId(undefined);
        setConfirmedStudentClassId(undefined);
        setFeePaymentFormData(initialFeePaymentFormData);
        // setSelectedStudentData(undefined)
        setSearchTerm('');
        refetchStudentsClassFeeData();
    };

    const handleFeeFormInputChange = (field: string, value: string | number) => {
        setFeePaymentFormData(prevFormData => ({
            ...prevFormData,
            [field]: value,
        }));
    };

    const updateFeeCollection = async (
        data: FeePaymentPostData,
        id: number
    ): Promise<FeePaymentPostDataWrapper | undefined> => {
        const response = await FeePaymentService.instance.updateFeeCollection(data, id);
        if (response.success) {
            return response?.data;
        } else {
            // console.log(`On Add Class Error ${JSON.stringify(response.error)}`);
            throw new Error(response.error?.message);
        }
    };

    const {
        mutate: editFeeCollection,
        isLoading: isFeeCollectionUpdating,
        isSuccess: isFeeCollectionUpdated,
    } = useMutation(
        ({ feePaymentPostData, id }: { feePaymentPostData: FeePaymentPostData; id: number }) =>
            updateFeeCollection(feePaymentPostData, id),
        {
            onSuccess: data => {
                alert('Added Fee Collection Successfully!');
                setIsFeeCollectionModalOpen(false);
                setSelectedStudentClassId(undefined);
                setConfirmedStudentClassId(undefined);
                setFeePaymentFormData(initialFeePaymentFormData);
                refetchStudentsClassFeeData();
            },
            onError: error => {
                // console.log(`On class add Error:`, error);
                alert('Failed to create fee collection! Please try again');
            },
        }
    );

    const postNewFeeCollection = async (
        data: FeePaymentPostData
    ): Promise<FeePaymentPostDataWrapper | undefined> => {
        const response = await FeePaymentService.instance.postNewFeeCollection(data);
        if (response.success) {
            return response?.data;
        } else {
            // console.log(`On Add Class Error ${JSON.stringify(response.error)}`);
            throw new Error(response.error?.message);
        }
    };

    const {
        mutate: addNewFeeCollection,
        isLoading: isFeeCollectionAdding,
        isSuccess: isFeeCollectionAdded,
    } = useMutation(
        (feePaymentPostData: FeePaymentPostData) => postNewFeeCollection(feePaymentPostData),
        {
            onSuccess: data => {
                alert('Added Fee Collection Successfully!');
                setIsFeeCollectionModalOpen(false);
                setSelectedStudentClassId(undefined);
                setConfirmedStudentClassId(undefined);
                setFeePaymentFormData(initialFeePaymentFormData);
                refetchStudentsClassFeeData();
            },
            onError: error => {
                // console.log(`On class add Error:`, error);
                alert('Failed to create fee collection! Please try again');
            },
        }
    );

    const handleFeeCollectSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        if (
            studentsClassFeeData?.data?.data &&
            studentsClassFeeData?.data?.data?.filter(
                item => item?.attributes?.class?.data?.id === confirmedStudentClassId
            ).length > 0
        ) {
            const requestBody = {
                payment_schedule: feePaymentFormData.payment_schedule,
                sessions: feePaymentFormData.sessions ?? 0,
                amount: feePaymentFormData.amount ?? 0,
                balance: feePaymentFormData.balance ?? null,
                academy: feePaymentFormData.academy ?? 0,
                student: feePaymentFormData.student ?? 0,
                class: feePaymentFormData.class ?? 0,
                dueDate: feePaymentFormData.feeEndDate,
                fee_dates: [
                    {
                        fee_date: new Date(feePaymentFormData.feePaymentDate),
                        startDate: new Date(feePaymentFormData.feeStartDate),
                        dueDate: new Date(feePaymentFormData.feeEndDate),
                        amount: parseInt(feePaymentFormData.feePaymentAmount) ?? 0,
                        payment_mode: feePaymentFormData.feePaymentMode,
                        message: feePaymentFormData.feePaymentMessage,
                        accReceivedIn: feePaymentFormData.accReceivedIn,
                    },
                    ...(studentsClassFeeData.data.data[0]?.attributes.fee_dates ?? []),
                ],
                users: [
                    ...(studentsClassFeeData?.data?.data[0]?.attributes.users.data.map(
                        item => item.id
                    ) || []),
                    userState.user?.id ?? 0,
                ],
            };
            editFeeCollection({
                feePaymentPostData: requestBody,
                id: studentsClassFeeData.data.data[0].id ?? 0,
            });
        } else {
            const requestBody = {
                payment_schedule: feePaymentFormData.payment_schedule,
                sessions: feePaymentFormData.sessions ?? 0,
                amount: feePaymentFormData.amount ?? 0,
                balance: feePaymentFormData.balance ?? 0,
                academy:
                    allClassesData?.data?.data?.filter(
                        item => item?.id === confirmedStudentClassId
                    )[0]?.attributes?.academies?.data?.id ?? 0,
                student: selectedStudentData?.id ?? 0,
                class: confirmedStudentClassId ?? 0,
                fee_dates: [
                    {
                        fee_date: new Date(feePaymentFormData.feePaymentDate),
                        startDate: new Date(feePaymentFormData.feeStartDate),
                        dueDate: new Date(feePaymentFormData.feeEndDate),
                        amount: parseInt(feePaymentFormData.feePaymentAmount) ?? 0,
                        payment_mode: feePaymentFormData.feePaymentMode,
                        message: feePaymentFormData.feePaymentMessage,
                        accReceivedIn: feePaymentFormData.accReceivedIn,
                    },
                ],
                users: [userState.user?.id ?? 0],
            };
            addNewFeeCollection(requestBody);
        }
    };

    function hasMatchingNumber(array1: number[] | undefined, array2: number[] | undefined) {
        if (array1 && array2) {
            for (let i = 0; i < array1.length; i++) {
                for (let j = 0; j < array2.length; j++) {
                    if (array1[i] === array2[j]) {
                        return true; // Found a match
                    }
                }
            }

            return false; // No match found
        }
    }

    const studentStatus = studentsData?.data?.data?.map(item => {
        const totalAcademy: number[] | undefined = academiesData?.data?.data?.map(item => item.id);

        const activeStudents = studentsData.data?.data
            ?.map(item => item.attributes.academies.data?.map(value => value.id))
            .flat();

        const result = hasMatchingNumber(activeStudents, totalAcademy);

        if (result) {
            return item.id;
        }
        // const lastActivity =
        //     .length > 0
        //         ? studentActivity[studentActivity.length - 1]
        //         : null;
    });

    // Log the result
    // console.log(studentStatus, '..........LLLLLL');

    const resultArray = studentStatus?.map(id => {
        const matchingObject = studentsData?.data?.data.find(item => item.id === id);
        return matchingObject
            ? { id: matchingObject.id, attributes: matchingObject.attributes }
            : null;
    });

    const [visibleFilter, setVisibleFilter] = useState<number>(0);

    const toggleVisibleFilter = (filter: number) => {
        if (filter === visibleFilter) {
            setVisibleFilter(0);
        } else {
            setVisibleFilter(filter);
        }
    };

    const [apartmentSerachTerm, setApartmentSearchTerm] = useState<string>('');
    const [schoolSerachTerm, setschoolSearchTerm] = useState<string>('');
    const handleApartmentSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        setApartmentSearchTerm(e.target.value ?? '');
    };

    const handleSchoolSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        setschoolSearchTerm(e.target.value ?? '');
    };

    const handleApartmentNameClick = (value: string) => {
        setSelectedApartmentForFilter(prev => {
            if (!prev.includes(value)) {
                return [...prev, value];
            }
            return prev;
        });
    };
    const handleSchoolNameClick = (value: string) => {
        setSelectedSchoolForFilter(prev => {
            if (!prev.includes(value)) {
                return [...prev, value];
            }
            return prev;
        });
    };

    const handleRemoveSelectedApartment = (value: string) => {
        setSelectedApartmentForFilter(prev => prev?.filter(item => item !== value));
    };

    const handleRemoveSelectedSchool = (value: string) => {
        setSelectedSchoolForFilter(prev => prev?.filter(item => item !== value));
    };

    const pagesToShow = 15; // Number of pages to show around the selected page

    // Calculate the total number of pages
    const totalPages = Math.ceil((studentsData?.data?.meta?.pagination?.total ?? 0) / itemsPerPage);

    const totalPagesForEnrolment = Math.ceil(
        (enrolmentFormData?.data?.meta?.pagination?.total ?? 0) / itemsPerPage
    );

    // Calculate the range of pages to display
    let startPage = Math.max(1, state.currentPage - Math.floor(pagesToShow / 2));
    let endPage = Math.min(totalPages, startPage + pagesToShow - 1);

    let endPageForEnrolment = Math.min(totalPagesForEnrolment, startPage + pagesToShow - 1);

    if (endPageForEnrolment - startPage + 1 < pagesToShow) {
        endPageForEnrolment = totalPagesForEnrolment;
    }

    // Adjust the range if the number of pages to show exceeds the total number of pages
    if (endPage - startPage + 1 < pagesToShow) {
        startPage = Math.max(1, totalPages - pagesToShow + 1);
        endPage = totalPages;
    }

    useEffect(() => {
        dispatch({
            type: 'setCurrentPage',
            payload: 1,
        });
    }, [
        searchTerm,
        selectedAcademiesForFilter,
        selectedAgesForFilter,
        selectedDobFilterType,
        selectedDobFilterDate,
        selectedApartmentForFilter,
        statusForFilter,
    ]);

    const handleDownloadSampleCsv = () => {
        const allClasses =
            allClassesData?.data?.data.map(classItem => classItem?.attributes?.class_name) || [];

        const data: BulkStudentAttendanceDataType[] = [
            {
                firstName: 'John',
                middleName: 'L',
                lastName: 'Doe',
                enrolementDate: '2024-12-28',
                gender: 'male',
                apartmentName: 'Fortune Sunnyvale Apartment',
                currentAddress:
                    'AECS Layout, A Block, 15th Main, Fortune Sunnyvale Apartment, Flat 205',
                dob: '2024-12-28',
                parentContactNo: '9876543210',
                parentEmailId: 'parent@mail.com',
                state: 'Karnataka',
                city: 'Bengaluru',
                relation1: 'Father',
                relation1Name: 'Harish',
                relation1Gender: 'male',
                relation1ContactNo: '9876543210',
                relation1EmailId: 'relation@mail.com',
                relation2: 'Father',
                relation2Name: 'Harish',
                relation2Gender: 'male',
                relation2ContactNo: '9876543220',
                relation2EmailId: 'relation@mail.com',
                classes: allClasses.join(','),
                photo: '29',
            },
        ];

        const csvData = convertToCSV(data, allClasses);

        // Create a Blob from the CSV data
        const blob = new Blob([csvData], { type: 'text/csv' });

        // Create a link to download the file
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'sample_student_data.csv');

        // Append to the document and trigger the download
        document.body.appendChild(link);
        link.click();

        // Clean up and remove the link
        link.parentNode?.removeChild(link);
    };

    const [isBulkUploadFormOpen, setIsBulkUploadFormOpen] = useState<boolean>(false);
    const [selectedCsvFileData, setSelectedCsvFileData] = useState<BulkStudentAttendanceDataType[]>(
        []
    );
    const csvFileInputRef = useRef<HTMLInputElement>(null);

    const handleBulkUploadStudentsModalOpen = () => {
        setIsBulkUploadFormOpen(true);
    };

    const handleBulkUploadStudentsModalClose = () => {
        setIsBulkUploadFormOpen(false);
        if (csvFileInputRef.current) {
            csvFileInputRef.current.value = '';
        }
        setSelectedCsvFileData([]);
    };

    const handleCsvFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0]; // Get the first file from the input
        if (file) {
            // Assuming you are dealing with a CSV file
            const reader = new FileReader();

            // Define what happens when FileReader finishes loading
            reader.onload = e => {
                if (e.target?.result) {
                    const csvString: string = e.target.result as string;
                    const jsonData = csvToJson(csvString); // Convert CSV string to JSON
                    const filteredData = jsonData.filter(data => {
                        return !Object.values(data).every(
                            value => value === undefined || value === ''
                        );
                    });
                    setSelectedCsvFileData(filteredData);
                    // Here you can process jsonData as needed
                }
            };

            // Read the file as text
            reader.readAsText(file);
        }
    };

    const postBulkStudents = async (data: BulkStudentsPostData[]): Promise<string | undefined> => {
        const response = await StudentService.instance.postBulkStudents(data);
        if (response.success) {
            return response.data;
        } else {
            throw new Error(response.error?.message);
        }
    };

    const {
        mutate: addBulkStudents,
        isLoading: isBulkStudentsAdding,
        isSuccess: isBulkStudentsAdded,
    } = useMutation(postBulkStudents, {
        onSuccess: data => {
            alert(data);
            queryClient.invalidateQueries(STUDENT_QUERY);
            handleBulkUploadStudentsModalClose();
        },
        onError: error => {
            // console.log(`On student add Error:`, error);
            alert('Failed to add students! Please try again');
            // Error actions
        },
    });

    const handleBulkStudentAddSubmit = (data: BulkStudentAttendanceDataType[]) => {
        const reqBody: BulkStudentsPostData[] = data.map(item => ({
            ...item,
            relation1Gender:
                item.relation1Gender === 'male' || item.relation1Gender === 'female'
                    ? item.relation1Gender
                    : null,
            relation2Gender:
                item.relation2Gender === 'male' || item.relation2Gender === 'female'
                    ? item.relation2Gender
                    : null,
            relation1EmailId: isValidEmail(item.relation1EmailId) ? item.relation1EmailId : null,
            relation2EmailId: isValidEmail(item.relation2EmailId) ? item.relation2EmailId : null,
            enrolementDate: isValidDate(item.enrolementDate) ? item.enrolementDate : null,
            dob: isValidDate(item.dob) ? item.dob : null,
            parentEmailId: isValidEmail(item.parentEmailId) ? item.parentEmailId : null,
            academies:
                allClassesData?.data?.data
                    .filter(data => item.classes?.split(',').includes(data?.attributes?.class_name))
                    .map(classItem => classItem?.attributes?.academies?.data?.id) ?? [],
            classes:
                allClassesData?.data?.data
                    .filter(data => item.classes?.split(',').includes(data?.attributes?.class_name))
                    .map(classItem => classItem?.id) ?? [],
            users: [userState?.user?.id ?? 0],
        }));

        addBulkStudents(reqBody);
    };

    const handleExportInvaidStudentsDataToCsv = () => {
        const allClasses =
            allClassesData?.data?.data.map(classItem => classItem?.attributes?.class_name) || [];
        const invalidStudentsData = selectedCsvFileData.filter(data =>
            verifyBulkStudentCsvRow(data, allClasses)
        );
        setSelectedCsvFileData(prev =>
            prev.filter(data => !verifyBulkStudentCsvRow(data, allClasses))
        );

        const csvData = convertToCSV(invalidStudentsData, allClasses);

        // Create a Blob from the CSV data
        const blob = new Blob([csvData], { type: 'text/csv' });

        // Create a link to download the file
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `invalid_students_data-${new Date().toString()}.csv`);

        // Append to the document and trigger the download
        document.body.appendChild(link);
        link.click();

        // Clean up and remove the link
        link.parentNode?.removeChild(link);
    };

    const handleIsEnrolmentOrNewStudentModalClose = () => {
        setIsEnrolmentOrNewStudentModalOpen(false);
    };

    const handleAddEnrollmentFormOpen = () => {
        setIsNewEnrolmentFormDetailsOpen(true);
        setIsEnrolmentOrNewStudentModalOpen(false);
    };

    const handleAddNewStudentFormOpen = () => {
        setIsNewStudentModalOpen(true);
        setIsEnrolmentOrNewStudentModalOpen(false);
    };

    const handleEnrolmentFormDetailsModalClose = () => {
        const conf = confirm('Are you sure you want to close?');
        if (conf) {
            setIsNewEnrolmentFormDetailsOpen(false);
            setEnrolmentData([]);
            setCurrentEnrolmentData(initialEnrolmentState);
        } else {
            return;
        }
    };

    const postNewEnrolmentData = async (
        data: EnrolmentPostDataWrapper
    ): Promise<EnrollmentDataListWrapper | undefined> => {
        const response = await StudentEnrollmentService.instance.postEnrolmentData(data);
        if (response.success) {
            return response.data;
        } else {
            throw new Error(response.error?.message);
        }
    };

    const {
        mutate: addNewEnrolment,
        isLoading: isNewEnrolmentLoading,
        isSuccess: isNewEnrolmentAdded,
    } = useMutation(postNewEnrolmentData, {
        onSuccess: data => {
            console.log('Added Enrolment Forms Successfully!');
            setIsNewEnrolmentFormDetailsOpen(false);
            setEnrolmentData([]);
            setCurrentEnrolmentData(initialEnrolmentState);
        },
        onError: error => {
            alert('Failed to add Enrolment Form. Please try again!');
        },
    });

    const handleEnrolmentFormDetailsSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        enrolmentData.map(async formData => {
            let newEnrolmentData: EnrolmentPostDataWrapper;
            if (formData.phoneNumber !== '') {
                newEnrolmentData = {
                    data: {
                        slug: generateUniqueSlug(),
                        users: userState?.user?.id || 0,
                        academies: formData.academyId,
                        classes: formData.classId,
                        isExistingStudent: formData.isExisting,
                        amountCollectedAtEnrolment: formData.amount,
                        phoneNumber: parseInt(formData.phoneNumber ?? ''),
                        status: 'SUBMISSION_PENDING',
                    },
                };
            } else {
                newEnrolmentData = {
                    data: {
                        slug: generateUniqueSlug(),
                        users: userState?.user?.id || 0,
                        email: formData.email,
                        academies: formData.academyId,
                        classes: formData.classId,
                        isExistingStudent: formData.isExisting,
                        amountCollectedAtEnrolment: formData.amount,
                        status: 'SUBMISSION_PENDING',
                    },
                };
            }
            await addNewEnrolment(newEnrolmentData);
        });
        setIsNewEnrolmentFormDetailsOpen(false);
    };

    const handleEnrolmentDataInputChange = (
        field: string,
        value: string | number[] | number | boolean | undefined
    ) => {
        setCurrentEnrolmentData(prev => {
            return {
                ...prev,
                [field]: value,
            };
        });
    };

    const handleEnrolmentFormAddClick = (e: React.FormEvent) => {
        if (
            currentEnrolmentData.academyId.length === 0 ||
            currentEnrolmentData.classId.length === 0 ||
            (currentEnrolmentData.email === '' && currentEnrolmentData.phoneNumber === '')
        ) {
            alert('Kindly fill the required fields.');
            e.preventDefault();
            return;
        }
        e.preventDefault();
        setEnrolmentData([...enrolmentData, currentEnrolmentData]);
        setCurrentEnrolmentData(initialEnrolmentState);
    };

    const mapClassestoOptionsForEnrolmentForm = (): { value: number; label: string }[] | [] => {
        if (classesData?.data?.data && currentEnrolmentData.academyId) {
            return (
                classesData.data.data
                    ?.filter(classItem=>classItem.attributes.status !== "INACTIVE")
                    .filter(classItem =>
                        currentEnrolmentData.academyId.includes(
                            classItem.attributes.academies.data.id
                        )
                    )
                    ?.map(item => ({
                        value: item.id,
                        label: item.attributes.class_name,
                    })) ?? []
            );
        }
        return [];
    };

    const handleEnrolmentCardEditClick = (index: number) => {
        setCurrentEnrolmentData(enrolmentData[index]);
        enrolmentData[index]?.email !== '' ? setIsEmail(true) : setIsEmail(false);
        setEnrolmentData(enrolmentData.filter((card, id) => id !== index));
    };

    const handleEnrolmentCardDeleteClick = (index: number) => {
        setEnrolmentData(enrolmentData.filter((card, id) => id !== index));
    };
    const [isEmail, setIsEmail] = useState(true);

    const handleEmailSwitch = () => {
        setIsEmail(!isEmail);
    };

    const [allSelfEnrolmentStudentChecked, setAllSelfEnrolmentStudentChecked] =
        useState<boolean>(false);
    const [checkedEnrolmentListItems, setCheckedEnrolmentListItems] = useState<EnrollmentData[]>(
        []
    );

    const handleEnrolmentStudentItemCheck = (value: EnrollmentData) => {
        setCheckedEnrolmentListItems(prevItems => {
            const isAlreadyChecked = prevItems.some(item => item.id === value.id);

            if (isAlreadyChecked) {
                return prevItems?.filter(item => item.id !== value.id);
            } else {
                return [...prevItems, value];
            }
        });
    };

    const handleAllEnrolmentStudentSelect = (e: ChangeEvent<HTMLInputElement>) => {
        setAllSelfEnrolmentStudentChecked(e.target.checked);
        setCheckedEnrolmentListItems(prevItems => {
            return e.target.checked
                ? [
                      ...state.selfEnrolment.filter(
                          item => item.attributes.status !== 'STUDENT_ADDED'
                      ),
                  ]
                : [];
        });
    };

    const [enrolmentStudentId, setEnrolmentStudentId] = useState<number>();

    const putEnrolmentData = async (
        data: EnrolmentPostDataWrapper
    ): Promise<EnrollmentDataListWrapper | undefined> => {
        const response = await StudentEnrollmentService.instance.putEnrolmentData(
            data,
            data?.id || 0
        );
        if (response.success) {
            return response.data;
        } else {
            throw new Error(response.error?.message);
        }
    };

    const {
        mutate: updateExistingEnrolment,
        isLoading: isEnrolmentUpdating,
        isSuccess: isNewEnrolmentJsonAdded,
    } = useMutation(putEnrolmentData, {
        onSuccess: data => {
            queryClient.invalidateQueries(STUDENT_ENROLMENT_DATA_QUERY);
            // alert("Updated Enrolment Successfully!");
        },
        onError: error => {
            // alert("Failed to Update Enrolment. Please try again!")
        },
    });

    const handleEnrollButtonClick = async () => {
        const newStudents = checkedEnrolmentListItems.filter(
            item => item.attributes.status !== 'STUDENT_ADDED'
        );

        newStudents.map(async student => {
            setEnrolmentStudentId(student.id);

            const newStudentData: StudentPostDataWrapper = {
                data: {
                    firstName: student.attributes.formData?.firstName,
                    lastName: student.attributes.formData?.lastName || null,
                    middleName: student.attributes.formData?.middleName || null,
                    gender: student.attributes.formData?.gender,
                    dob: student.attributes.formData?.dob,
                    bloodGroup: student.attributes.formData?.bloodGroup || null,
                    parentContactNo: student?.attributes?.phoneNumber ?? '',
                    parentEmailId: student.attributes.email,
                    currentAddress: student.attributes.formData?.address || null,
                    photo: student?.attributes?.image?.data?.id || null,
                    studentAcademyHistory: student.attributes.academies.data.map(academy => {
                        return {
                            supplier: userState.user?.id,
                            academy: academy.id,
                            status: 'Active',
                            statusDate: new Date(),
                            enrolmentDate: new Date(),
                            remarks: '',
                        };
                    }),
                    studentRelation:
                        [
                            {
                                relation: student.attributes.formData?.relationWithStudent || null,
                                name: student.attributes.formData?.relationName || null,
                                contactNo: student.attributes.formData?.relationNumber || null,
                                emailId: student.attributes.formData?.relationEmail || null,
                                gender: formData.relationOneGender || null,
                                profession: formData.relationOneProfession || null,
                            },
                            {
                                relation:
                                    student.attributes.formData?.secondRelationWithStudent || null,
                                name: student.attributes.formData?.secondRelationName || null,
                                contactNo:
                                    student.attributes.formData?.secondRelationNumber || null,
                                emailId: student.attributes.formData?.secondRelationEmail || null,
                                gender: formData.relationOneGender || null,
                                profession: formData.relationOneProfession || null,
                            },
                        ] || null,
                    academies: student.attributes.academies.data.map(item => item.id),
                    classes: student.attributes.classes.data.map(item => item.id),
                    users: [userState?.user?.id || 0],
                    school: student?.attributes?.formData?.school || null,
                    grade: student?.attributes?.formData?.grade || null,
                    board: student?.attributes?.formData?.board || null,
                    parentLinkedTo:
                        allParentProfiles?.data?.data.filter(
                            parent =>
                                parent?.attributes?.userInfo?.data?.attributes?.email ===
                                student?.attributes?.email
                        )[0]?.attributes?.userInfo?.data?.id ?? null,
                },
            };

            await addNewStudent(newStudentData);
            await updateExistingEnrolment({ data: { status: 'STUDENT_ADDED' }, id: student.id });
        });

        setCheckedEnrolmentListItems([]);
    };

    const handleRejectButtonClick = async () => {
        const checked = checkedEnrolmentListItems.filter(
            item => item.attributes.status !== 'STUDENT_ADDED'
        );

        checked.map(async student => {
            setEnrolmentStudentId(student.id);
            await updateExistingEnrolment({ data: { status: 'REJECTED' }, id: student.id });
        });

        setCheckedEnrolmentListItems([]);
    };

    return {
        handleClassFilterChange,
        selectedStudentIndex: state.selectedStudentIndex,
        dispatch,
        isStudentsFetchSuccess,
        isLoading,
        isBulkStudentsAdding,
        studentsData,
        currentPage: state.currentPage,
        students: filteredStudents,
        itemsPerPage,
        indexOfLastItem,
        indexOfFirstItem,
        total: studentsData?.data?.meta?.pagination?.total ?? 0,
        handleActionsButton,
        goToStudentDetails,
        goToFeeHistory,
        isRecordLeaveModalOpen,
        handleRecordLeaveModalClose,
        openRecordLeaveFormModal,
        recordLeaveModalStudentData,
        formValues,
        handleRecordLeaveFormInputChange,
        handleRecordLeavesFormSubmit,
        isRecordPostSuccess,
        handleProfileImgChange,
        handleFeeCollectionModalClose,
        onCollectFeeClicked,
        isFeeCollectionModalOpen,
        handleAddNewStudentBtnClick,
        isNewStudentModalOpen,
        handleNewStudentModalClose,
        mapAcademiesToOptions,
        mapClassesToOptions,
        openStudentDropoutConfirmation,
        openStudentEditForm,
        isEditStudentModalOpen,
        handleEditStudentModalClose,
        handleStudentDropout,
        isDropStudentFormOpen,
        handleDropStudentModalClose,
        studentActiveAcademies,
        handleStudentDropAction,
        handleDropStudentAcademyCheck,
        checkedAcademiesToDropStudent,
        setSearchTerm,
        onStudentSearchListItemClick,
        isSearchInputOnFocus,
        setIsSearchInputOnFocus,
        searchTerm,
        handlesearchInputChange,
        studentSuggestionListData: studentsData?.data?.data?.slice(
            indexOfFirstItem,
            indexOfLastItem
        ),
        selectedStudentData,
        isSuccess: isStudentsFetchSuccess,
        state,
        setSelectedAgesForFilter,
        handleAgeChange,
        filteredStudents,
        selectedAgesForFilter,
        handleFilterModalClose,
        isfilterModalOpen,
        profileImageInputKey,
        handleFilterBtnClick,
        isProfileImagePosting,
        allClasses: allClassesData?.data?.data,
        handleFeeFormInputChange,
        isStudentDataFetchLoading,
        handleClassInputChange,
        handleStudentFeeSearch,
        studentsClassFeeData: studentsClassFeeData?.data?.data?.filter(
            item => item?.attributes?.class?.data?.id === confirmedStudentClassId
        ),
        feePaymentFormData,
        handleFeeCollectSubmit,
        confirmedStudentClassId,
        handleStudentFeeReset,
        selectedStudentClassId,
        selectedDobFilterType,
        selectedDobFilterDate,
        setSelectedDobFilterDate,
        setSelectedDobFilterType,
        visibleFilter,
        toggleVisibleFilter,
        handleApartmentSearchInputChange,
        apartmentData,
        apartmentSerachTerm,
        handleApartmentNameClick,
        selectedApartmentForFilter,
        handleRemoveSelectedApartment,
        handleAcademyFilterChange,
        selectedAcademiesForFilter,
        selectedClassesForFilter,
        isLeaveRecordPosting,
        isStudentDropping,
        isFeeCollectionUpdating,
        isFeeCollectionAdding,
        userState,
        pagesToShow,
        startPage,
        endPage,
        studentFilterDropDownRef,
        enrollmentFilterDropDownRef,
        moreActionsDropDownRef,
        inactiveFeeData,
        expandInactive,
        handleExpandInactive,
        handleStatusChange,
        statusForFilter,
        handleDownloadSampleCsv,
        handleBulkUploadStudentsModalOpen,
        handleBulkUploadStudentsModalClose,
        isBulkUploadFormOpen,
        isClassFetching,
        handleCsvFileInputChange,
        selectedCsvFileData,
        csvFileInputRef,
        handleBulkStudentAddSubmit,
        handleExportInvaidStudentsDataToCsv,
        setIsEditStudentModalOpen,
        editingStudentData,
        handleIsEnrolmentOrNewStudentModalClose,
        isEnrolmentOrNewStudentModalOpen,
        handleEnrolmentFormDetailsModalClose,
        handleEnrolmentFormDetailsSubmit,
        isNewEnrolmentFormDetailsOpen,
        handleEnrolmentDataInputChange,
        enrolmentData,
        handleEnrolmentFormAddClick,
        mapClassestoOptionsForEnrolmentForm,
        isNewEnrolmentLoading,
        currentEnrolmentData,
        mapAllClassesToOptionsIrrespectiveOfAcademy,
        selfEnrolment: state.selfEnrolment,
        student: state.students.sort((a, b) =>
            a?.attributes.firstName.localeCompare(b?.attributes?.firstName)
        ),
        setOpenTab,
        totalEnrolment: enrolmentFormData?.data?.meta?.pagination?.total ?? 0,
        enrolmentFormData: enrolmentFormData?.data?.data,
        studentsEnrolled: enrolmentFormData?.data?.data?.filter(
            item => item.attributes.status === 'STUDENT_ADDED'
        ).length,
        isEnrolmentFormFetchSuccess,
        totalPagesForEnrolment,
        endPageForEnrolment,
        handleEnrolmentCardEditClick,
        handleEnrolmentCardDeleteClick,
        handleEmailSwitch,
        isEmail,
        allSelfEnrolmentStudentChecked,
        handleAllEnrolmentStudentSelect,
        checkedEnrolmentListItems,
        handleEnrolmentStudentItemCheck,
        isAllChecked:
            enrolmentFormData?.data?.data.filter(item => item.attributes.status !== 'STUDENT_ADDED')
                .length === checkedEnrolmentListItems.length,
        handleEnrollButtonClick,
        allParentEmails: allParentProfiles?.data?.data.map(
            parent => parent?.attributes?.userInfo?.data?.attributes?.email
        ),
        handleAddEnrollmentFormOpen,
        handleAddNewStudentFormOpen,
        selectedSchoolForFilter,
        selectedGradeForFilter,
        selectedBoardForFilter,
        handleBoardFilterChange,
        handleGradeFilterChange,
        handleSchoolSearchInputChange,
        schoolSerachTerm,
        schoolData,
        handleRemoveSelectedSchool,
        handleSchoolNameClick,
        handleRejectButtonClick,
        isEnrolmentUpdating,
        selectedEnrollmentStatusForFilter,
        handleEnrollmentStatusFilterChange,
        sortDropdownRef,
        handleSortDropBtnClick,
        isSortOptionsVisible,
        selectedSortOption,
        handleSortOptionChange,
        academiesWithoutContactInfo: academiesData?.data?.data?.filter(
            academy =>
                academy?.attributes?.email === null ||
                academy?.attributes?.email === '' ||
                academy?.attributes?.contactNo === null ||
                academy?.attributes?.contactNo === ''
        ),
    };
};

export default StudentsViewModel;
