import React, { useState, useEffect, ChangeEvent, FormEvent } from 'react';
import { CertificationFormDataType } from '../../../view-models/actions-view-models/add-certifications-view-model';
import { AcademyData } from '../../../../models/academy/academy-data';
import { DisciplineData } from '../../../../models/discipline/discipline-data';
import MinusIcon from '../../../../assets/icons/minus.png';
import PlusIcon from '../../../../assets/icons/plus.png';

interface AddEditCertificationFormProps {
    formData: CertificationFormDataType;
    handleInputChange: (value: string | number, field: string) => void;
    handleFormSubmit: (e: FormEvent) => void;
    existingLevels: number[];
    academySelected: AcademyData;
    disciplineSelected: DisciplineData;
    isEditing: boolean;
}

const AddEditCertificationForm: React.FC<AddEditCertificationFormProps> = ({
    formData,
    handleInputChange,
    handleFormSubmit,
    existingLevels,
    isEditing,
    academySelected,
    disciplineSelected,
}) => {

    return (
        <div className="p-2 rounded-lg sm:w-[460px] max-w-lg overflow-y-scroll">
            <form onSubmit={handleFormSubmit}>
                <div className="mb-4">
                    <label className="block text-gray-700">Name</label>
                    <input
                        type="text"
                        name="Name"
                        required
                        value={formData.name}
                        onChange={(e) => handleInputChange(e.target.value, 'name')}
                        className="w-full px-4 py-2 border rounded-lg"
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700">Level</label>
                    <div className='flex items-center gap-2'>
                        <div 
                            className=' cursor-pointer p-2 flex items-center justify-center text-xl bg-slate-400 rounded active:scale-95 transition-all'
                            onClick={() => {
                                if (formData.level > 0) {
                                    handleInputChange(formData.level - 1, 'level');
                                }
                            }}
                        >
                            <img
                                src={MinusIcon}
                                className='h-6 w-6 invert'
                            />
                        </div>
                        <input
                            type="number"
                            name="level"
                            required
                            value={formData.level}
                            onChange={(e) => handleInputChange(Number(e.target.value), 'level')}
                            className={`
                                w-full px-4 py-2 border rounded-lg outline-0
                                ${(existingLevels.includes(formData.level) && !isEditing) ? 'ring ring-red-400 border-0' : ''}
                            `}
                        />
                        <div 
                            className='cursor-pointer p-2 flex items-center justify-center text-xl bg-slate-400 rounded active:scale-95 transition-all'
                            onClick={() => handleInputChange(formData.level + 1, 'level')}
                        >
                            <img
                                src={PlusIcon}
                                className=' h-6 w-6 invert'
                            />
                        </div>
                    </div>
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700">Remarks</label>
                    <input
                        type="text"
                        name="Remarks"
                        value={formData.remarks}
                        onChange={(e) => handleInputChange(e.target.value, 'remarks')}
                        className="w-full px-4 py-2 border rounded-lg"
                    />
                </div>
                {/* <div className="mb-4">
                    <label className="block text-gray-700">Assignment Techniques</label>
                    <Select
                        isMulti
                        options={mapTechniqueToOptions()}
                        className="text-sm text-gray-500 resize-none border-gray-500 border-opacity-30 rounded disabled:bg-slate-100 w-full"
                        classNamePrefix="Select Classes"
                        onChange={handleTechniqueChange}
                        value={mapTechniqueToOptions().filter((item: OptionType) =>
                            formData.assignment_technique_requests?.includes(item.value)
                        )}
                    />
                </div> */}
                <div className="flex justify-between items-center">
                    <div className='text-sm text-red-400'>
                        {(existingLevels.includes(formData.level) && !isEditing) ? 'Level already exists' : ''}
                    </div>
                    <button
                        type="submit"
                        className="px-4 font-semibold py-2 bg-primary text-white rounded disabled:opacity-25"
                        disabled={existingLevels.includes(formData.level) && !isEditing}
                    >
                        Submit
                    </button>
                </div>
            </form>
        </div>
    );
};

export default AddEditCertificationForm;
