import React from 'react';
import { useRouteError } from 'react-router-dom';
import errorImage from '../../../assets/graphics/error-image.png';
import ErrorLogViewModel from '../../view-models/error-log-view-model';
import { useSelector } from 'react-redux';
import { RootState } from '../../../utils/redux/store';

const ErrorPage = () => {

    const { postError } = ErrorLogViewModel();
        const userState = useSelector((state: RootState) => state.user);

    const error: any = useRouteError(); 
    const errorData = {
        status: error.status || 'Unknown Status',
        statusText: error.statusText || 'Unknown Status Text',
        message: error.message || 'No message available',
        url: window.location.href, 
        stack: error.stack || 'No stack trace available', 
    };
    const errorBody = {
        errorMessage: error.message,
        errorData,
        timestamp: new Date(),
        loggedFrom: 'ACADEMY_APP',
        user:userState.user?.id
    }

    return (
        <div className="h-screen flex flex-col items-center justify-center gap-3">
            <img src={errorImage} />
            <h1 className="font-bold text-xl">
                {`Something isn't right. If you need immediate support, please let us know`}
            </h1>
            {/* {error.status && <p>Status Code : {error.status}</p>}
            {error.statusText && <p>Status Text : {error.statusText}</p>}
            {error.message && <p className='text-lg'>Error Message : {error.message}</p>} */}
            <div className='flex gap-6 items-center'>
                <button 
                    className="border-2 border-slate-700 w-28 h-12 rounded-lg text-slate-700 font-semibold"
                    onClick={() => window.history.back()}
                >
                    Go Back
                </button>
                <button
                    className="bg-slate-700 w-28 h-12 border rounded-lg text-white font-semibold"
                    onClick={() => postError(errorBody)}
                >
                    Report
                </button>
            </div>
        </div>
    );
}

export default ErrorPage;