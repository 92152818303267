import React from 'react';
import style from './style.module.css';
import UserIcon from '../../../../assets/icons/user.png';
import GroupUserIcon from '../../../../assets/icons/group-users.png';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import chevrondownIcon from '../../../../assets/icons/chevrondown.png';
import { ClassAttributes } from '../../../../models/class/class-attributes';
import { formatTime } from '../../../../utils/helpers/helpers';
import { useCollapse } from 'react-collapsed';
import { ClassPostData } from '../../../../models/class/class-post-data';
import { ClassData } from '../../../../models/class/class-data';

interface ClassListItemProps {
    classAttributes: ClassAttributes;
    handleItemExpand: () => void;
    isClassItemExpanded: boolean;
    handleEditClassModalOpen: () => void;
    viewOnly: boolean;
}

const ClassListItem = ({
    classAttributes,
    handleItemExpand,
    isClassItemExpanded,
    handleEditClassModalOpen,
    viewOnly,
}: ClassListItemProps) => {
    const { getCollapseProps } = useCollapse({
        isExpanded: isClassItemExpanded,
    });

    return (
        <div
            className={style.ClassListItemWrapperContainer}
            onClick={handleItemExpand}
        >
            <div className={style.ClassListItemWrapper}>
                <div className={style.classNameWithIconWrapper}>
                    <div className={style.className}>{classAttributes.class_name}</div>
                    <div className={style.classTypeIconContainer}>
                        <img
                            className={style.classTypeIcon}
                            src={
                                classAttributes.class_type === 'Individual'
                                    ? UserIcon
                                    : GroupUserIcon
                            }
                            alt="User Icon"
                        />
                    </div>
                </div>
                <div
                    className={
                        isClassItemExpanded
                            ? style.classInfoWhenExpandedWrapper
                            : style.classInfoWhenMinimizedWrapper
                    }
                >
                    <div
                        className={
                            isClassItemExpanded
                                ? style.academyNameInfoWhenExpanded
                                : style.academyNameInfo
                        }
                    >
                        Academy: {classAttributes?.academies?.data?.attributes?.name}
                    </div>
                    {isClassItemExpanded && (
                        <div
                            className={
                                isClassItemExpanded
                                    ? style.skillLevelInfoWhenExpanded
                                    : style.skillLevelInfo
                            }
                        >
                            Skill level: {classAttributes?.skill_level}
                        </div>
                    )}
                    <div
                        className={
                            isClassItemExpanded
                                ? style.disciplineInfoWhenExpanded
                                : style.disciplineInfo
                        }
                    >
                        Discipline: {classAttributes?.class_discipline?.data?.attributes?.name}
                    </div>
                    <div
                        className={
                            isClassItemExpanded
                                ? style.weekDayCircleItemContainerWhenExpanded
                                : style.weekDayCircleItemContainer
                        }
                    >
                        <div
                            className={`${style.weekDayCircleItem}
                                ${classAttributes?.class_timings?.some(item => item.day === 'Monday') ? 'bg-cyan-400' : 'bg-gray-200'}
                            `}
                        >
                            M
                        </div>
                        <div
                            className={`${style.weekDayCircleItem}
                                ${classAttributes?.class_timings?.some(item => item.day === 'Tuesday') ? 'bg-cyan-400' : 'bg-gray-200'}
                            `}
                        >
                            T
                        </div>
                        <div
                            className={`${style.weekDayCircleItem}
                                ${classAttributes?.class_timings?.some(item => item.day === 'Wednesday') ? 'bg-cyan-400' : 'bg-gray-200'}
                            `}
                        >
                            W
                        </div>
                        <div
                            className={`${style.weekDayCircleItem}
                                ${classAttributes?.class_timings?.some(item => item.day === 'Thursday') ? 'bg-cyan-400' : 'bg-gray-200'}
                            `}
                        >
                            T
                        </div>
                        <div
                            className={`${style.weekDayCircleItem}
                                ${classAttributes?.class_timings?.some(item => item.day === 'Friday') ? 'bg-cyan-400' : 'bg-gray-200'}
                            `}
                        >
                            F
                        </div>
                        <div
                            className={`${style.weekDayCircleItem}
                                ${classAttributes?.class_timings?.some(item => item.day === 'Saturday') ? 'bg-cyan-400' : 'bg-gray-200'}
                            `}
                        >
                            S
                        </div>
                        <div
                            className={`${style.weekDayCircleItem}
                                ${classAttributes?.class_timings?.some(item => item.day === 'Sunday') ? 'bg-cyan-400' : 'bg-gray-200'}
                            `}
                        >
                            S
                        </div>
                    </div>
                    {isClassItemExpanded && (
                        <div
                            className={
                                isClassItemExpanded
                                    ? style.sessionsInfoWhenExpanded
                                    : style.sessionsInfo
                            }
                        >
                            Sessions per week: {classAttributes?.class_timings?.length}
                        </div>
                    )}
                </div>
                <div className="flex justify-end">
                    {isClassItemExpanded && !viewOnly && (
                        <div className={style.editClassBtnWrapper}>
                            <button
                                className={style.editClassBtn}
                                onClick={handleEditClassModalOpen}
                            >
                                Edit class
                            </button>
                        </div>
                    )}
                </div>
                <div
                    className={style.classTimingInfoTableContainer}
                    {...getCollapseProps()}
                >
                    <table className="border border-collapse border-solid border-gray-300 m-0 p-0 w-full table-fixed">
                        <caption className="text-lg my-2 py-3">Class Timings</caption>
                        <thead>
                            <tr className="bg-gray-200 border border-solid border-gray-300 p-1">
                                <th
                                    className="p-2 text-center text-md tracking-wide uppercase"
                                    scope="col"
                                >
                                    Day
                                </th>
                                <th
                                    className="p-2 text-center text-md tracking-wide uppercase"
                                    scope="col"
                                >
                                    Start time
                                </th>
                                <th
                                    className="p-2 text-center text-md tracking-wide uppercase"
                                    scope="col"
                                >
                                    End time
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {classAttributes?.class_timings
                                ?.slice()
                                .sort((a, b) => {
                                    const daysOrder = [
                                        'Monday',
                                        'Tuesday',
                                        'Wednesday',
                                        'Thursday',
                                        'Friday',
                                        'Saturday',
                                        'Sunday',
                                    ];
                                    return daysOrder.indexOf(a.day) - daysOrder.indexOf(b.day);
                                })
                                .map(item => (
                                    <tr
                                        key={item.id}
                                        className="bg-gray-200 border border-solid border-gray-300 p-1"
                                    >
                                        <td
                                            className="p-2 text-center"
                                            data-label="Day"
                                        >
                                            {item.day}
                                        </td>
                                        <td
                                            className="p-2 text-center"
                                            data-label="Start time"
                                        >
                                            {formatTime(item.start_time)}
                                        </td>
                                        <td
                                            className="p-2 text-center"
                                            data-label="End time"
                                        >
                                            {formatTime(item.end_time)}
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </div>
            </div>
            {!isClassItemExpanded && (
                <button
                    className={style.listItemButton}
                    onClick={handleItemExpand}
                >
                    View Class{' '}
                    <img
                        className={style.listItemDownIcon}
                        src={chevrondownIcon}
                        alt="down"
                    />
                </button>
            )}
        </div>
    );
};

export default ClassListItem;
