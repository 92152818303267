import React from 'react'
import PopupModal from '../../components/widgets/popup-modal'
import AddCertificationsViewModel from '../../view-models/actions-view-models/add-certifications-view-model'
import AddEditCertificationForm from '../../components/widgets/add-edit-certification-form';
import { CertificationData } from '../../../models/certifications/certification-data';
import { AcademyData } from '../../../models/academy/academy-data';
import { DisciplineData } from '../../../models/discipline/discipline-data';

interface AddCertificationsProps {
    isOpen: boolean;
    handleClose: () => void;
    certificationsList: CertificationData[];
    academySelected: AcademyData;
    disciplineSelected: DisciplineData;
    isEditing: boolean;
    editingCertificationData: CertificationData | null;
}

const AddCertifications = ({
    isOpen,
    handleClose,
    certificationsList,
    academySelected,
    disciplineSelected,
    editingCertificationData,
    isEditing,
}: AddCertificationsProps) => {

    const {
        isLoading,
        handleModalClose,
        certificationFormData,
        handleInputChange,
        handleFormSubmit,
    } = AddCertificationsViewModel({
        handleClose,
        academySelected,
        disciplineSelected,
        editingCertificationData,
        isEditing,
    });

    return (
        <PopupModal
            title={isEditing ? "Edit Certification" : "Add Certification"}
            isOpen={isOpen}
            handleModalClose={handleModalClose}
            background='white'
        >
            <AddEditCertificationForm
                formData={certificationFormData}
                handleInputChange={handleInputChange}
                handleFormSubmit={handleFormSubmit}
                existingLevels={certificationsList?.map(c => c?.attributes?.level)}
                academySelected={academySelected}
                disciplineSelected={disciplineSelected}
                isEditing={isEditing}
            />
        </PopupModal>
    )
}

export default AddCertifications