import { useSelector } from 'react-redux';
import { RootState } from '../../../utils/redux/store';
import { ClassData } from '../../../models/class/class-data';
import { FormEvent, useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { CertificationService } from '../../../services/certification-service';
import { CertificationPostDataWrapper } from '../../../models/certifications/certification-post-data-wrapper';
import { CertificationPostData } from '../../../models/certifications/certification-post-data';
import { CertificationDataWrapper } from '../../../models/certifications/certification-data-wrapper';
import { CERTIFICATIONS_LIST_FOR_ACADEMY_AND_DISCIPLINE } from '../../../utils/constants/constants';
import { AcademyData } from '../../../models/academy/academy-data';
import { DisciplineData } from '../../../models/discipline/discipline-data';
import { CertificationData } from '../../../models/certifications/certification-data';

export interface CertificationFormDataType {
    name: string;
    level: number;
    remarks: string;
}

const AddCertificationsViewModel = ({
    handleClose,
    academySelected,
    disciplineSelected,
    editingCertificationData,
    isEditing,
}: {
    handleClose: () => void;
    academySelected: AcademyData;
    disciplineSelected: DisciplineData;
    editingCertificationData: CertificationData | null;
    isEditing: boolean;
}) => {
    const userState = useSelector((state: RootState) => state.user);
    const queryClient = useQueryClient();

    const initialCertificationFormData = {
        name: '',
        level: 0,
        remarks: '',
    }
    const [certificationFormData, setCertificationFormData] = useState<CertificationFormDataType>(initialCertificationFormData);

    const handleInputChange = (value: string | number, field: string) => {
        setCertificationFormData({
            ...certificationFormData,
            [field]: value
        });
    }

    useEffect(() => {
        if (editingCertificationData) {
            setCertificationFormData({
                name: editingCertificationData?.attributes?.name ?? '',
                level: editingCertificationData?.attributes?.level ?? 0,
                remarks: editingCertificationData?.attributes?.remarks ?? '',
            })
        }
    },[editingCertificationData])
    
    const handleModalClose = () => {
        setCertificationFormData(initialCertificationFormData);
        handleClose();
    }

    const postCertificateRecord = async (
        data: CertificationPostData
    ): Promise<CertificationDataWrapper | undefined> => {
        const response = await CertificationService.instance.addCertification(data);
        if (response.success) {
            return response.data;
        } else {
            throw new Error(response.error?.message);
        }
    };

    const {
        mutate: addNewCertification,
        isLoading: isNewCertificationAdding,
    } = useMutation(postCertificateRecord, {
        onSuccess: (data) => {
            handleClose();
            queryClient.invalidateQueries(CERTIFICATIONS_LIST_FOR_ACADEMY_AND_DISCIPLINE);
        },
        onError: (error) => {
            alert('Failed to add certification! Please try again');
        },
    });

    const putCertificateRecord = async (
        data: CertificationPostData
    ): Promise<CertificationDataWrapper | undefined> => {
        const response = await CertificationService.instance.updateCertification(data, editingCertificationData?.id ?? 0);
        if (response.success) {
            return response.data;
        } else {
            throw new Error(response.error?.message);
        }
    };

    const {
        mutate: editCertification,
        isLoading: isCertificationEditing,
    } = useMutation(putCertificateRecord, {
        onSuccess: (data) => {
            handleClose();
            queryClient.invalidateQueries(CERTIFICATIONS_LIST_FOR_ACADEMY_AND_DISCIPLINE);
        },
        onError: (error) => {
            alert('Failed to edit certification! Please try again');
        },
    });

    const handleFormSubmit = (e: FormEvent) => {
        e.preventDefault();
        if(isEditing) {
            editCertification({
                name: certificationFormData.name,
                level: certificationFormData.level,
                remarks: certificationFormData.remarks,
            })
        }else{
            addNewCertification({
                name: certificationFormData.name,
                level: certificationFormData.level,
                academy: academySelected?.id,
                discipline: disciplineSelected?.id,
                remarks: certificationFormData.remarks,
            })
        }
    }

    return {
        isLoading: isNewCertificationAdding || isCertificationEditing,
        handleModalClose,
        certificationFormData,
        handleInputChange,
        handleFormSubmit,
    };
};

export default AddCertificationsViewModel;
