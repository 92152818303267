import React from 'react'
import { StudentData } from '../../../../models/student/student-data';
import defaultProfilePic from '../../../../assets/icons/user.png';
import { CertificationData } from '../../../../models/certifications/certification-data';

interface CertifyNewStudentFormProps {
    availableNewStudentsToCertify: StudentData[];
    allAvailableStudents: StudentData[];
    handleAddStudent: (id: number) => void;
    handleRemoveStudent: (id: number) => void;
    serchTerm: string;
    setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
    selectedStudents: number[];
    isSearchInputOnFocus: boolean;
    setIsSearchInputOnFocus: React.Dispatch<React.SetStateAction<boolean>>;
    selectedCertificationData: CertificationData;
    handleCertifyNewStudentsFormSubmit: () => void;
}

const CertifyNewStudentForm = ({
    availableNewStudentsToCertify,
    allAvailableStudents,
    handleAddStudent,
    handleRemoveStudent,
    serchTerm,
    selectedStudents,
    setSearchTerm,
    isSearchInputOnFocus,
    setIsSearchInputOnFocus,
    selectedCertificationData,
    handleCertifyNewStudentsFormSubmit,
}:CertifyNewStudentFormProps) => {
  return (
    <div className='w-[85vw] sm:w-[600px] p-2'>
        <div className='relative'>
            <input 
                type='text'
                className='rounded p-1 w-full'
                placeholder='Search Students'
                value={serchTerm}
                onChange={e => setSearchTerm(e.target.value)}
                onFocus={() => setIsSearchInputOnFocus(true)}
                onBlur={() => setTimeout(() => setIsSearchInputOnFocus(false), 200)}
            />
            <div className={`absolute bg-white top-full w-full h-[200px] border shadow shadow-gray-700 overflow-y-auto thin-scrollbar transition-transform ${isSearchInputOnFocus ? 'block opacity-100' : 'opacity-0 hidden'}`}>
                {availableNewStudentsToCertify?.map(student => (
                    <div 
                        key={student?.id} 
                        className='flex items-center gap-2 p-1 px-4 bg-slate-50 rounded overflow-y-auto hover:bg-slate-200 cursor-pointer'
                        onClick={() => handleAddStudent(student?.id)}
                    >
                        <img 
                            src={student?.attributes?.photo?.data?.attributes?.formats?.thumbnail?.url ?? defaultProfilePic}
                            className='w-10 h-10 rounded-full object-cover'
                        />
                        <div className='text-gray-700 font-semibold'>
                            {student?.attributes?.firstName} {student?.attributes?.lastName ?? ''}
                        </div>
                    </div>
                ))}
            </div>
        </div>
        <div className='mt-2 rounded p-1 flex flex-col gap-1 border overflow-y-auto thin-scrollbar bg-slate-100' style={{height: '280px'}}>
            {allAvailableStudents?.filter(s => selectedStudents.includes(s?.id)).map(student => (
                <div 
                    key={student?.id} 
                    className='flex items-center gap-2 p-1 px-4 bg-slate-50 rounded hover:bg-red-200 cursor-pointer'
                    onClick={() => handleRemoveStudent(student?.id)}
                >
                    <img 
                        src={student?.attributes?.photo?.data?.attributes?.formats?.thumbnail?.url ?? defaultProfilePic}
                        className='w-10 h-10 rounded-full object-cover'
                    />
                    <div className='text-gray-700 font-semibold'>
                        {student?.attributes?.firstName} {student?.attributes?.lastName ?? ''}
                    </div>
                </div>
            ))}
        </div>
        <div className='flex items-center justify-between mt-6 gap-4'>
            <div className='text-slate-600 border rounded px-2 py-1'>Certifying <span className='font-semibold'>{selectedStudents.length}</span> students with `<span className='font-semibold'>{`${selectedCertificationData?.attributes?.name} - Level ${selectedCertificationData?.attributes?.level}`}</span> Certification.</div>
            <button 
                className='text-white text-lg font-semibold px-2 py-1 bg-slate-700 rounded cursor-pointer transition-all active:scale-95 disabled:opacity-40'
                disabled={selectedStudents.length === 0}
                onClick={handleCertifyNewStudentsFormSubmit}
            >
                Continue
            </button>
        </div>
    </div>
  )
}

export default CertifyNewStudentForm