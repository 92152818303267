/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import style from './style.module.css';
import NavMenu from '../../components/widgets/nav-menu';
import GrowthBarchart from '../../components/widgets/growth-bar-chart';
import DashboardViewModel from '../../view-models/dashboard-view-model';
import { AiOutlinePlus } from 'react-icons/ai';
import { SlOptions } from 'react-icons/sl';
import { formatTime, getFormattedWeekDay } from '../../../utils/helpers/helpers';
import FullScreenLoader from '../../components/bits/fullscreen-loader';
import { Roles } from '../../../utils/auth/roles';
import AddStudent from '../../actions/add-student';
import CollectFees from '../../actions/collect-fees';

const QuickActionComponent = () => {
    const {
        onCollectFeeClicked,
        isFeeCollectionModalOpen,
        handleFeeCollectionModalClose,
        handleNewStudentModalClose,
        isNewStudentModalOpen,
        handleAddNewStudentBtnClick,
        userState,
    } = DashboardViewModel();

    return (
        <div
            id="quickAction"
            className={style.cardContainer}
        >
            <div className={style.cardHeaderNoBorder}>Quick Action </div>
            <div className={style.actionButtonWrapper}>
                {((userState?.userDetails?.attributes?.studentsAccess !== 'noAccess' &&
                    userState?.userDetails?.attributes?.studentsAccess !== 'restrictedView') ||
                    userState?.user?.role?.type === Roles.AUTHENTICATED_USER) && (
                    <button
                        className={style.actionButton}
                        onClick={handleAddNewStudentBtnClick}
                    >
                        <AiOutlinePlus />
                        <span className={style.textSmall}>Add new Student</span>
                    </button>
                )}

                {((userState?.userDetails?.attributes?.feePaymentAccess !== 'noAccess' &&
                    userState?.userDetails?.attributes?.feePaymentAccess !== 'restrictedView') ||
                    userState?.user?.role?.type === Roles.AUTHENTICATED_USER) && (
                    <button
                        className={style.actionButton}
                        onClick={onCollectFeeClicked}
                    >
                        <span className={style.textSmall}>Collect Fees</span>
                    </button>
                )}

                {/* temporarily commented out for demo */}

                {/* <button className={style.actionButton}>
                        <AiOutlinePlus />
                        <span className={style.textSmall}>Add new Enquiry</span>
                    </button> */}
            </div>

            <CollectFees
                isOpen={isFeeCollectionModalOpen}
                handleClose={handleFeeCollectionModalClose}
                isStudentChangable={true}
            />
            <AddStudent
                isOpen={isNewStudentModalOpen}
                handleClose={handleNewStudentModalClose}
                isEdit={false}
            />
        </div>
    );
};

const ActiveStudentComponent = () => {
    const { activeStudentsCount } = DashboardViewModel();

    return (
        <div className={style.cardContainer}>
            <div className={style.innerBlock}>
                <div>
                    <span className={style.upperCase}>Active Students</span>
                </div>
                {/* temporarily commented out for demo */}

                {/* <div>
                        <span className={style.upperCase}>Active Coaches</span>
				    </div> */}

                {/* temporarily commented out for demo */}
            </div>
            <div className={style.flexJustify}>
                <div>
                    <span className={style.textLarge}>{activeStudentsCount}</span>
                </div>
                {/* temporarily commented out for demo */}

                {/* <div>
					<span className={style.textLarge}>5</span>
					</div> */}

                {/* temporarily commented out for demo */}
            </div>
        </div>
    );
};

const ScheduleComponent = () => {
    const { goScheduleDetails, todayClasses } = DashboardViewModel();

    return (
        <div
            id="upcomingSchedule"
            className={`${style.cardContainer} `}
        >
            <div className={style.cardHeaderWrapper}>
                <div>Upcoming Schedule</div>
                <div>
                    {/* <div className={style.filterItemContainer}>
                        <label className={style.paddingRight2}>
                            {' '}
                            <FiFilter />{' '}
                        </label>

                        <div className={style.filterItemInnerContainer}>
                            <label className={style.pointerCursor}>
                                <input
                                    type="radio"
                                    value="All"
                                    className={style.hideBlock}
                                />
                                <span className={style.filterHeaderText}>
                                    All
                                </span>
                            </label>
                            {DATA_SCHEDULE.map((item, index) => (
                                <label
                                    key={item.id}
                                    className={style.filterHeader}>
                                    <input
                                        type="radio"
                                        key={index}
                                        value={item.category}
                                        className={style.hideBlock}
                                    />
                                    <span className={style.filterHeaderText}>
                                        {item.category}
                                    </span>
                                </label>
                            ))}
                        </div>
                    </div> */}
                </div>

                <div className="hidden">
                    <SlOptions />
                </div>
            </div>
            <table className={style.tableWrapper}>
                <tbody>
                    {todayClasses.length === 0 ? (
                        <div>No classes today.</div>
                    ) : (
                        todayClasses.map((item, index) => (
                            <tr
                                key={index}
                                className={style.tableRow}
                            >
                                <td>
                                    <input type="checkbox" />
                                </td>
                                <td className={style.tableCell}>
                                    <span className={style.categoryWrapper}>c</span>
                                </td>
                                <td
                                    className={`${style.tableCell} hidden sm:block  sm:pt-[0.9rem]`}
                                >
                                    <div>
                                        {' '}
                                        {getFormattedWeekDay(item?.attributes?.class_day ?? '')}
                                    </div>
                                </td>
                                <td className={style.tableCell}>
                                    {' '}
                                    {formatTime(
                                        item?.attributes?.class_timing?.start_time ?? ''
                                    )} -{' '}
                                    {formatTime(item?.attributes?.class_timing?.end_time ?? '')}
                                </td>
                                <td className={style.tableCell}>
                                    {' '}
                                    {item?.attributes?.class_name ?? ''}
                                </td>
                                <td className={style.tableCell}>
                                    {' '}
                                    {item?.attributes?.students?.data?.length === 0 ? 'No student registered' : 
                                        item?.attributes?.students?.data?.length === 1
                                        ? item?.attributes?.students?.data[0]?.attributes.firstName
                                        : `${
                                              item?.attributes?.students?.data[0]?.attributes
                                                  ?.firstName
                                          } and ${
                                              item?.attributes?.students.data?.length - 1
                                          } more`}
                                </td>
                                <td className={style.tableCell}>
                                    {' '}
                                    <button
                                        onClick={() =>
                                            goScheduleDetails(
                                                item.id,
                                                item?.attributes?.class_day ?? '',
                                                item?.attributes?.class_timing?.start_time ?? '',
                                                item?.attributes?.class_timing?.end_time ?? ''
                                            )
                                        }
                                        className={style.linkWrapper}
                                    >
                                        View
                                    </button>
                                </td>
                            </tr>
                        ))
                    )}
                </tbody>
            </table>
        </div>
    );
};

const WelcomePrompt = () => {
    const { supplierData } = DashboardViewModel();
    return (
        <div className={style.headerWelcomeContainer}>
            <div className={style.headerWelcomeMessage}>
                Welcome {supplierData?.data?.data?.[0]?.attributes?.firstName ?? ''},
            </div>
            <div className={style.headerBusinessSummary}>Here is a summary of your business</div>
        </div>
    );
};

const Dashboard = () => {
    const {
        FeesSummary,
        isLoading,
        MonthlyRevenueLastThreeMonths,
        MonthlyStudentRegistration,
        userState,
        MonthlyRevenueLastTwelveMonthsSpread,
    } = DashboardViewModel();

    const handleVibrate = () => {
        navigator.vibrate([100, 50, 100, 50, 100, 50, 100, 50, 100, 50, 100, 50]);
    };

    return (
        <div>
            <NavMenu>
                {isLoading && <FullScreenLoader />}
                <WelcomePrompt />
                <div className={style.flexWrap}>
                    <div>
                        <div className={style.flexRow}>
                            {(userState?.userDetails?.attributes?.studentsAccess !== 'noAccess' ||
                                userState?.user?.role?.type === Roles.AUTHENTICATED_USER) && (
                                <div className={style.flexBasis}>
                                    <ActiveStudentComponent />
                                </div>
                            )}
                            <div className={style.flexBasis}>
                                <QuickActionComponent />
                            </div>
                        </div>
                    </div>
                    {(userState?.userDetails?.attributes?.dashboardAccess === 'fullAccess' ||
                        userState?.user?.role?.type === Roles.AUTHENTICATED_USER) && (
                        <>
                            <div id="monthlyGrowth">
                                <div className={style.flexRow}>
                                    {/* <div className={style.flexBasis}>
                                        <SummaryPieChart FeesSummary={FeesSummary} />
                                    </div> */}
                                    <div className={style.flexBasis}>
                                        <GrowthBarchart
                                            MonthlyRevenueLastThreeMonths={
                                                MonthlyRevenueLastThreeMonths
                                            }
                                            MonthlyStudentRegistration={MonthlyStudentRegistration}
                                            MonthlyRevenueLastTwelveMonthsSpread={
                                                MonthlyRevenueLastTwelveMonthsSpread
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                    {(userState?.userDetails?.attributes?.scheduleAccess !== 'noAccess' ||
                        userState?.user?.role?.type === Roles.AUTHENTICATED_USER) && (
                        <div>
                            {/* temporarily commented out for demo */}
                            <ScheduleComponent />
                            {/* temporarily commented out for demo */}
                        </div>
                    )}
                </div>
                {/* <div className={style.dashboardText}>
                    SpArts Academy Dashboard
                </div> */}
                {/* <button onClick={handleVibrate}>Vibrate</button> */}
            </NavMenu>
        </div>
    );
};

export default Dashboard;
